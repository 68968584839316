import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';
import contracts from './contracts-manage/contracts-manage.vue';
import care from './care/care.vue';

const hrRouting: RouteConfig[] = [
    {
        path: 'hr',
        meta: { title: 'hr' },
        component: LayoutBlock,
        redirect: '/app/hr/care',
        children: [
            {
                name: 'care',
                path: 'care',
                component: care,
                meta: { title: '员工关怀' },
            },
            {
                name: 'contracts-manage',
                path: 'contracts-manage',
                component: contracts,
                meta: { title: '合同' },
            },
            {
                name: 'internship',
                path: 'internship',
                component: () => import('./internship/internship.vue'),
                meta: { title: '转正' },
            },
            {
                name: 'userchangelog',
                path: 'userchangelog',
                component: () => import('./userchangelog/userchangelog.vue'),
                meta: { title: '异动记录' },
            },
            {
                name: 'handleentry',
                path: 'handleentry',
                component: () => import('./handleentry/handleentry.vue'),
                meta: { title: '入职办理' },
            },
            {
                name: 'employee-quit',
                path: 'employee-quit',
                component: () => import('./employee-quit/employee-quit.vue'),
                meta: { title: '离职' },
            },
        ],
    },
];
export default hrRouting;
