<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loadingData">
			<a-card>
				<div class="q-table-container" ref="qContainerRef">
					<div ref="toolbarRef">
						<slot name="toolbar"></slot>
					</div>
					<div ref="tableRef">
						<slot name="table" :scroll="scroll"></slot>
					</div>
					<div ref="pageRef" id="pageRef" v-if="usePagination">
						<slot name="page"></slot>
					</div>
					<!-- 默认插槽 -->
					<slot />
				</div>
			</a-card>
		</a-spin>
	</a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import bus from '@/shared/bus/bus';

/**
 * 单表格容器组件
 * @description 页面只有一个表格建议使用此组件，该组件用于动态计算表格高度
 * @property {loading} 是否开启加载蒙层（默认false）
 * @property {usePagination} 是否开启分页（默认false）
 * @property {useModal} 是否弹窗（默认false） 用于减少高度，避免弹窗撑开页面
 *
 * @slot {toolbar} 插槽：所有筛选条件写入此插槽
 * @slot {table} 插槽：表格组件写入此插槽
 * @slot {page} 插槽：分页写入此插槽
 * @example 见 readme.md (建议复制这里面的代码)
 */
export default {
	name: 'QContainer',
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		usePagination: {
			type: Boolean,
			default: false,
		},
		useModal: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		loading(v) {
			this.loadingData = v;
		},
	},
	data() {
		return {
			zh_CN,
			loadingData: false,
			scroll: {
				y: 0,
			},
		};
	},
	async mounted() {
		this.loadingData = this.loading;
		this.$nextTick(this.calcHeight);
		window.addEventListener('resize', this.calcHeight, false);
		bus.$on('calcHeight', this.calcHeight);
	},
	beforeDestroy() {
		bus.$off('calcHeight');
		window.removeEventListener('resize', this.calcHeight);
	},
	activated() {
		this.$nextTick(() => {
			this.calcHeight();
		});
	},
	updated() {
		this.$nextTick(() => {
			this.calcHeight();
		});
	},
	methods: {
		async calcHeight() {
			try {
				const antTableThead = await this.getElementByClassName('ant-table-thead', this.$refs.tableRef);

				let tableHeader = antTableThead.offsetHeight || 70;
				const top = await this.getOffsetParent();
				let height = 0;
				//是否分页
				if (this.usePagination) {
					height =
						window.innerHeight -
						top -
						this.$refs.toolbarRef.offsetHeight -
						this.$refs.pageRef.offsetHeight -
						tableHeader;
					// console.log('height', height);
					// console.log('window.innerHeight', window.innerHeight);
					// console.log('top', top);
					// console.log('toolbarRef.offsetHeight', this.$refs.toolbarRef.offsetHeight);
					// console.log('pageRef.offsetHeight', this.$refs.pageRef.offsetHeight);
					// console.log('tableHeader', tableHeader);
				} else {
					height = window.innerHeight - top - this.$refs.toolbarRef.offsetHeight - tableHeader;
				}
				if (this.useModal) {
					height = (height / 10) * 9;
				}
				this.$refs.tableRef.style.height = height + 'px';
				this.$refs.tableRef.style.maxHeight = height + 'px';
				this.$refs.tableRef.style.overFlow = 'hidden';
				if (tableHeader > 75) {
					this.$refs.tableRef.style.marginTop = '-40px';
				}
				const antTableTbody = await this.getElementByClassName('ant-table-tbody', this.$refs.tableRef);
				const antTableBody = await this.getElementByClassName('ant-table-body', this.$refs.tableRef);
				//判断是否无数据
				if (antTableTbody && antTableTbody.childNodes.length <= 0) {
					this.scroll.y = 0;
					antTableBody.style.height = `${tableHeader}px`;
				} else {
					// 表格高度 = 容器高度 - 工具栏高度 - 分页高度 - 表格头高度 - 预留边距
					this.scroll.y =
						this.$refs.qContainerRef.offsetHeight -
						this.$refs.toolbarRef.offsetHeight -
						(this.usePagination ? this.$refs.pageRef.offsetHeight : 0) -
						tableHeader -
						10;
					antTableBody.style.height = `${this.scroll.y}px`;
				}
			} catch (error) {
				//console.log('calc error', error);
			}
		},
		getElementByClassName(className, target) {
			return new Promise(async (resolve, reject) => {
				if (!target || !className) {
					reject('必填参数为空');
				}
				let element;
				let childrens = target.children;
				for (let children of childrens) {
					if (children.className == className) {
						element = children;
						break;
					} else {
						element = await this.getElementByClassName(className, children);
						if (element) {
							break;
						}
					}
				}
				resolve(element);
			});
		},
		getOffsetParent() {
			return new Promise((resolve) => {
				let parent = this.$refs.toolbarRef.offsetParent;
				let top = 0;
				while (true) {
					if (parent) {
						top += parent.offsetTop;
						// console.log('parent', parent.offsetTop);
						// console.log('top', top);
						if (parent.localName == 'body') {
							break;
						}
						parent = parent.offsetParent;
					} else {
						break;
					}
				}
				resolve(top);
			});
		},
	},
};
</script>

<style scoped lang="less">
@import './q-container';
</style>
