import { GetCurrentLoginInformationsOutput } from '@/shared';
import { abpStore_NS, rootStore } from '@/shared/store';
import { IAbp } from '@/shared/abp/interfaces';

class AbpService {
    set(val: any) {
        rootStore.commit(`${abpStore_NS}/set`, val);
    }

    get abp(): IAbp {
        const storeAbp = rootStore.getters[`${abpStore_NS}/get`];
        if (storeAbp.localization) {
            return storeAbp;
        }
        return abp as any;
    }

    setModuleKey(val: any) {
        rootStore.commit(`${abpStore_NS}/setModuleKey`, val);
    }

    get moduleKey(): string {
        return rootStore.getters[`${abpStore_NS}/moduleKey`];
    }

    setLoginInfo(val: GetCurrentLoginInformationsOutput) {
        rootStore.commit(`${abpStore_NS}/setLoginInfo`, val);
    }

    get loginInfo(): GetCurrentLoginInformationsOutput {
        return rootStore.getters[`${abpStore_NS}/getLoginInfo`];
    }

    get tabincludes(): Array<string> {
        return rootStore.getters[`${abpStore_NS}/tabincludes`];
    }
    addInclude(include: string) {
        rootStore.commit(`${abpStore_NS}/addInclude`, include);
    }
    removeInclude(include: string) {
        rootStore.commit(`${abpStore_NS}/removeInclude`, include);
    }
    clearInclude() {
        rootStore.commit(`${abpStore_NS}/clearInclude`);
    }
}

const abpService = new AbpService();
export default abpService;
