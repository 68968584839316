import {RouteConfig} from "vue-router";
import {LayoutBlock} from "@/layout";
import AccountLayout from "@/account/account-layout.vue";

const SetPasswordRouting: RouteConfig[] =[
    {
        path: '/account',
        component: AccountLayout,
        children:[
            {
                path: 'setPassword',
                component: () => import(/* webpackChunkName: "account" */ './setPassword/setPassword.vue')
            },
        ]
    }
]

export  default  SetPasswordRouting;
