<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>

                <a-row style="margin-bottom: 20px;">
                    <a-col :span="10">
                        <a-button type="primary" @click="create()" v-if="isGranted('create_audit_task')">
                            <a-icon type="plus" />
                            {{l('Create')}}
                        </a-button>
                        <a-button type="danger" @click="delete1" v-if="isGranted('audit_task_delete')">
                            <a-icon type="delete" />
                            {{l('Delete')}}
                        </a-button>
                        <a-button type="primary" @click="Leave" v-if="isGranted('audit_task_Leave')">
                            <a-icon type="snippets" />
                            {{l('Leave')}}
                        </a-button>
                        <a-button type="primary" @click="task_report" v-if="isGranted('audit_task_Report')">
                            <a-icon type="table" />
                            {{l('Report')}}
                        </a-button>
                        <!-- <a-button @click="Export" v-if="isGranted('audit_task_export')">
                            <a-icon type="export" />
                            {{l('Export')}}
                        </a-button> -->

                        <a-dropdown v-if="isGranted('audit_task_export')">
                            <a-menu slot="overlay" @click="Export">
                                <a-menu-item key="2">
                                    {{l("Task")}}
                                </a-menu-item>
                                <a-menu-item key="3">
                                    {{l("audit_items")}}
                                </a-menu-item>
                            </a-menu>
                            <a-button style="margin-left: 8px">
                                <a-icon type="export" />
                                {{l("export")}}
                            </a-button>
                        </a-dropdown>

                        <a-dropdown v-if="isGranted('audit_task_import')">
                            <a-menu slot="overlay" @click="handleMenuClick">
                                <a-menu-item key="2">
                                    <a-icon type="download" />
                                    {{l("ImportToExcelTemplate")}}
                                </a-menu-item>
                                <a-menu-item key="3">
                                    <a-icon type="import" />
                                    {{l("ImportFromExcel")}}
                                </a-menu-item>
                            </a-menu>
                            <a-button style="margin-left: 8px">
                                <a-icon type="import" />
                                {{l("import")}}
                            </a-button>
                        </a-dropdown>

                    </a-col>

                    <a-col :span="14" class="d-flex justify-content-end">
                        <a-range-picker @change="timeChange" :placeholder="[l('audit.planstart'), l('audit.planend')]"
                            v-model="defaultRangeTime" style="margin-right:10px; width: 250px;" />

                        <a-select showSearch :filterOption="filterOption"
                            style="width: 150px;text-align: left;margin-right: 10px;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            :placeholder="l('please_select_status')" @change="AuditTaskStateChange" allow-clear mode="multiple">
                            <a-select-option v-for="item in AuditTaskStateList" :key="item.itemDetailCode"
                                :title="item.itemDetailName">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>

                        <a-input-search :placeholder="l('please_enter_a_name_to_search')"
                            v-model.trim="filterText" enterButton style="width: 200px;" @search="filteronchange">
                        </a-input-search>
                    </a-col>
                    <a-col :span="24" style="margin-top: 5px;text-align: right;">
						<a-select showSearch :filterOption="filterOption"
						    style="width: 150px;text-align: left;margin-right: 10px;"
						    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
						    :placeholder="l('Please_select_a_user_level')" @change="LevelChange" allow-clear>
						    <a-select-option v-for="item in LevelList" :key="item.levelIndex"
						        :title="item.name">
						        {{ item.name }}
						    </a-select-option>
						</a-select>
                        <a-select showSearch :filterOption="filterOption"
                            style="width: 150px;text-align: left;margin-right: 10px;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            :placeholder="l('please_select_Task_Type')" @change="AuditTaskTypeChange" allow-clear>
                            <a-select-option v-for="item in AuditTaskTypeList" :key="item.itemDetailCode"
                                :title="item.itemDetailName">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>

                        <a-select showSearch :filterOption="filterOption"
                            style="width: 150px;text-align: left;margin-right: 10px;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            :placeholder="l('Please_select_a_label')" @change="AuditTaskLabelChange" allow-clear>
                            <a-select-option v-for="item in AuditTaskLabelList" :key="item.itemDetailCode"
                                :title="item.itemDetailName">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>

                        <a-select showSearch :filterOption="filterOption" style="width: 150px;text-align: left;margin-right: 10px;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            :placeholder="l('please_select_reviewer')" allow-clear @change="LPAUserChange">
                            <a-select-option v-for="item in AllLPAUser" :key="item.id" :title="item.realName">
                                {{ item.realName }}
                            </a-select-option>
                        </a-select>

                        <a-input :placeholder="l('Barcode')"
                            v-model.trim="Barcode" enterButton style="width: 150px;text-align: left;margin-right: 10px;" @search="filteronchange">
                        </a-input>

                        <a-input :placeholder="l('AssitField')"
                            v-model.trim="assitField" enterButton style="width: 150px;text-align: left;" @search="filteronchange">
                        </a-input>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns" :data-source="tableData" :customRow="customRow"
                        :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
                        :rowKey="(record)=>record.id" :scroll="{x:1000,y:600}" :pagination="false" size="small"
                        @change="handleTableChange">
                        <span slot="name" slot-scope="text,record">
                            <a @click="view1(record)">{{text}}</a>
                        </span>
                        <span slot="enable" slot-scope="text">
                            <span v-if="text">
                                <a-icon type='check' style="color: #1890ff;" />
                            </span>
                            <span v-else>
                                <a-icon type='close' />
                            </span>
                        </span>
                        <span slot="auditNeedInput" slot-scope="text">
                            <span v-if="text">
                                <a-icon type='check' style="color: #1890ff;" />
                            </span>
                            <span v-else>
                                <a-icon type='close' />
                            </span>
                        </span>
                        <!-- <span slot="name" slot-scope="text,record">
                                <a @click.stop="view(record)">{{text}}</a>
                                </span> -->
                        <span slot="action" slot-scope="text,record">
                            <!-- <a @click.stop="view1(record)">
										<a-icon type="search" />{{l('View')}}
									</a>
									<font>  |  </font> -->
							<a @click.stop="spotCheck(record)" v-if="isGranted('audit_task_check')">
							    <a-icon type="check-circle" />{{l('Spot_Check')}}
							</a>
							<font v-if="isGranted('audit_task_edit')&&isGranted('audit_task_check')"> | </font>
                            <a @click.stop="createOrEditM(record)" v-if="isGranted('audit_task_edit')">
                                <a-icon type="edit" />{{l('Edit')}}
                            </a>
                            <font v-if="isGranted('audit_task_edit')&&isGranted('audit_task_delete')"> | </font>
                            <a @click.stop="delete2(record)" v-if="isGranted('audit_task_delete')">
                                <a-icon type="delete" />{{l('Delete')}}
                            </a>
                        </span>
                    </a-table>
                </a-spin>
                <a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
					:defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
                    :show-total="total => `共有 ${totalItems} 条`" @change="onChangePage"
                    @showSizeChange="showSizeChange" />
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>

    import { AppComponentBase } from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        AuditItemGroupServiceProxy,
        AudititemServiceProxy,
        AuditItemtypeServiceProxy,
        AuditTaskServiceProxy,
		LayoutLevelServiceProxy,
		SystemConfigServiceProxy
    } from '../../../shared/service-proxies';
    import { ModalHelper } from '@/shared/helpers';
    import { Dic, fileDownloadService } from "../../../shared/utils";
    import createaudittask from "./create-audit-task/create-audit-task.vue";
    import update from "./update-audit-task/update-audit-task.vue";
    import moment from "moment";
    import CreatErrorCode from "./look-model/lookmodel";
	import spotCheck from "./spot-check/spot-check";
    import view from "../audit-task-view/audit-task-view.vue";
    import leave from "./leave/leave.vue";
    import task_report from "./task_report/task_report.vue";
    import importExcelComponent from "./import-excel/import-excel";

    let _this;

    export default {
        name: 'audit-task',
        mixins: [AppComponentBase],
        components: {
            createaudittask,
            leave,
            task_report
        },

        created() {
            _this = this;
            this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
            this.AudititemServiceProxy = new AudititemServiceProxy(this.$apiUrl, this.$api);
            this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
			this.LayoutLevelServiceProxy = new LayoutLevelServiceProxy(this.$apiUrl, this.$api);
			this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        async mounted() {

            try {
                this.loading = true;
                this.AuditTaskStateList = await Dic.getInstance().getDicAsync('AuditTaskState');
                this.AuditTaskTypeList = await Dic.getInstance().getDicAsync('AuditTaskType');
                this.AuditTaskLabelList = await Dic.getInstance().getDicAsync('AuditTaskLabel');
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
			this.getIsEnable();
            this.getAllLPAUser();

            window.addEventListener('resize', this.changeHeight);
            this.changeHeight();

            this.getTime();
        },

        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,

                filterText: undefined,
                totalItems: 0,//总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "50", "100", "500"],
                request: { maxResultCount: 20, skipCount: 0 },

                tableData: [],
                columns: [
                    {
                        title: this.l('FomartDateTime'),
                        width: 150,
                        dataIndex: 'fomartDateTime',
                        align: 'center',
                    },

                    {
                        title: this.l('audit.audittasktypename'),
                        width: 90,
                        dataIndex: 'auditTaskTypeName',
                        align: 'center',
                    },
                    {
                        title: this.l('audit.name'),
                        width: 150,
                        dataIndex: 'name',
                        align: 'center',
                        scopedSlots: { customRender: "name" },
                        ellipsis: true
                    },
                    {
                        title: this.l('Label'),
                        width: 100,
                        dataIndex: 'auditTaskLabelName',
                        align: 'center',
                        scopedSlots: { customRender: "auditTaskLabelName" },
                        ellipsis: true
                    },
                    {
                        title: this.l('audit.inspectorname'),
                        width: 120,
                        dataIndex: 'inspectorName',
                        align: 'center',
                    },

                    { title: this.l('audit.starttime'), width: 150, dataIndex: 'startTime', align: 'center', },

                    {
                        title: this.l('audit.endtime'),
                        width: 150,
                        dataIndex: 'endTime',
                        align: 'center',
                    },


                    {
                        title: this.l('audit.audittaskstatename'),
                        width: 100,
                        dataIndex: 'auditTaskStateName',
                        align: 'center',
                    },
                    
                    {
                        title: this.l('LeaveReason'),
                        width: 100,
                        dataIndex: 'leaveReason',
                        align: 'center',
                    },


                    {
                        title: this.l('LeaveRemark'),
                        width: 100,
                        dataIndex: 'leaveRemark',
                        align: 'center',
                    },
                    {
                        title: this.l('audit.remark'),
                        width: 150,
                        dataIndex: 'remark',
                        align: 'center',
                    },

                    {
                        title: this.l('Actions'),
                        width: 200,
                        dataIndex: 'action',
                        align: 'center',
                        scopedSlots: { customRender: "action" },
                        fixed: 'right',
                    },
                ],
                selectedRowKey: [], // 选择的数据Id
                selectedRowList: [],
                selectedRow: [],//选择的整行数据
                isLoading: false,//加载中
                children: [],
                treeData: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    code: 'code'
                },
                treeReplace: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                styObj: {
                    height: 0
                },

                checkedTreeKeys: [],
                isTreeEdit: false,
                isTreeCreate: false,
                isTreeLoading: false,//加载中
                treeNodeCode: undefined,
                expandedTreeKeys: [],
                selectedTreeKeys: [],
                selected: [],
                workProcessClass: [],
                workProcessData: [],
                teby: [],
                enable: [],
                _fileDownloadService: undefined,
                loading: false,

                AuditTaskStateList: [],
                AuditTaskTypeList: [],

                AllLPAUser: [],
                UserId: undefined,
                startdate: undefined,
                enddate: undefined,
                AuditTaskType: undefined,
                AuditTaskLabel: undefined,
                AuditTaskLabelList: [],

                defaultRangeTime: [],
                Barcode: undefined,
                AssitField: undefined,  
				LevelList: [],
				LevelId: undefined,
				IsEnabled: {
					filedExt1: false,
					filedExt2: false,
					filedExt3: false,
					templateBarCode: false,
					assitField: false
				}
            }
        },
        methods: {
			//获取是否启用扩展字段
			getIsEnable() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getFiledExtSettings(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.IsEnabled = res;
					console.log(this.columns)
					if(this.IsEnabled.assitField) {
						let assitField = {
							title: this.l('AssitField'),
							width: 100,
							dataIndex: 'assitField',
							align: 'center',
						};
						this.columns.splice(8,0,assitField);
					}
					if(this.IsEnabled.templateBarCode) {
						let templateBarCode = {
							title: this.l('TemplateBarCode'),
							width: 100,
							dataIndex: 'templateBarCode',
							align: 'center',
						};
						this.columns.splice(8,0,templateBarCode);
					}
					if(this.IsEnabled.filedExt3) {
						let filedExt3 = {
							title: this.l('filedExt3'),
							width: 100,
							dataIndex: 'filedExt3',
							align: 'center',
						};
						this.columns.splice(8,0,filedExt3);
					}
					if(this.IsEnabled.filedExt2) {
						let filedExt2 = {
							title: this.l('filedExt2'),
							width: 100,
							dataIndex: 'filedExt2',
							align: 'center',
						};
						this.columns.splice(8,0,filedExt2);
					}
					if(this.IsEnabled.filedExt1) {
						let filedExt1 = {
							title: this.l('filedExt1'),
							width: 100,
							dataIndex: 'filedExt1',
							align: 'center',
						};
						this.columns.splice(8,0,filedExt1);
					}
				})
			},
			
            getTime() {
                let time = new Date();
                time.setDate(1);
                var month = parseInt(time.getMonth() + 1);
                var day = time.getDate();
                if (month < 10) {
                    month = '0' + month
                }
                if (day < 10) {
                    day = '0' + day
                }
                let startTime = time.getFullYear() + '-' + month + '-' + day;
                let endTime = new Date();
                this.defaultRangeTime = [moment(startTime), moment(endTime)];
                this.startdate = moment(startTime);
                this.enddate = moment(endTime);

                this.getData();
				this.getLevelData();
            },

            //导出
            Export(rel) {
                this.isLoading = true;
                if (rel.key == "2") {
                    this.AuditTaskServiceProxy.getToExcel(
                    this.startdate,
                    this.enddate,
                    this.workProcessClass,
                    this.UserId,
                    this.AuditTaskType,
                    this.selectedRowKey,
                    this.AuditTaskLabel,
                    this.Barcode,
                    this.AssitField,
					this.LevelId,
                    this.filterText ? this.filterText : undefined,
                    this.Sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this._fileDownloadService.downloadTempFile(res);
                })
                } else if (rel.key == "3") {
                    this.AuditTaskServiceProxy.getToExcelDetail(
                    this.startdate,
                    this.enddate,
                    this.workProcessClass,
                    this.UserId,
                    this.AuditTaskType,
                    this.selectedRowKey,
                    this.AuditTaskLabel,
                    this.Barcode,
                    this.AssitField,
					this.LevelId,
                    this.filterText ? this.filterText : undefined,
                    this.Sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this._fileDownloadService.downloadTempFile(res);
                })
                }

                
            },
            Leave() {
                if (this.selectedRowKey.length != 1) {
                    abp.message.info(this.l('PleaseSelectAtLeastOneItem'));
                    return;
                }
                ModalHelper.create(leave, {
                    id: this.selectedRowKey[0]
                }, { width: 400 }).subscribe(res => {
                    console.log(res)
                    this.getData();
                    this.selectedRowKey = [];
                })
            },

            task_report() {
                if (this.selectedRowKey.length != 1) {
                    abp.message.info(this.l('PleaseSelectAtLeastOneItem'));
                    return;
                }
                ModalHelper.create(task_report, {
                    id: this.selectedRowKey[0]
                }, { width: 1200 }).subscribe(res => {
                    console.log(res)
                    this.getData();
                    this.selectedRowKey = [];
                })
            },

            getAllLPAUser() {
                this.isLoading = true;
                this.AuditTaskServiceProxy.getAllLPAUsers(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.AllLPAUser = res;
                    })
            },

            LPAUserChange(value) {
                console.log(value);
                this.UserId = value;
                this.getData();
            },

            //排序
            handleTableChange(pagination, filters, sorter) {
                console.log(sorter);
                if (sorter.order != undefined) {
                    this.Sorting = sorter.field + " " + sorter.order.replace("end", "");
                }
                else {
                    this.Sorting = undefined;
                }
                this.getData();
            },

            timeChange(date, dateString) {
                this.startdate = date[0];
                this.enddate = date[1];
                this.getData();
            },

            //div高度
            changeHeight() {
                this.styObj.height = window.innerHeight - 199 + 'px';
            },

            batchdelete() {
                if (this.selected <= 0 || this.selected == null) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                // console.log(this.children);
                // if (this.children[0] != undefined) {
                //     abp.message.warn(this.l("问题分类下存在问题分类，不可删除"));
                //     return;
                // }
                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this.AuditItemGroupServiceProxy.delete(this.selected).then(res => {
                                this.getTree();
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })
            },
            //获取数据
            getData() {
                this.selectedRowKey = [];
                this.selectedRowList = [];
                this.isLoading = true;
                this.AuditTaskServiceProxy.getPaged(
                    this.startdate,
                    this.enddate,
                    this.workProcessClass,
                    this.UserId,
                    this.AuditTaskType,
                    undefined,
                    this.AuditTaskLabel,
                    this.Barcode,
                    this.AssitField,
					this.LevelId,
                    this.filterText ? this.filterText : undefined,
                    this.Sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
					
                    this.tableData = res.items;

                    this.tableData.map(item => {
                        item.planEnd = item.planEnd ? moment(item.planEnd).format("YYYY-MM-DD HH:mm") : "";
                        item.planStart = item.planStart ? moment(item.planStart).format("YYYY-MM-DD HH:mm") : "";
                        item.startTime = item.startTime ? moment(item.startTime).format("YYYY-MM-DD HH:mm") : "";
                        item.endTime = item.endTime ? moment(item.endTime).format("YYYY-MM-DD HH:mm") : "";
                    });

                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                })
            },
            view(record) {
                ModalHelper.create(CreatErrorCode, {
                    isEdit: true,
                    id: record.auditTemplateId
                }, {
                }).subscribe(res => {
                    this.getData();
                    this.selectedRowKey = [];
                })
            },
            handleEdit(record) {
                // console.log(record);
                var entity = {
                    "id": record.id,
                };
                if (record.enable == true) {
                    this.enable = false;
                } else {
                    this.enable = true;
                }
                this.isLoading = true;
                this.AudititemServiceProxy.isDetelt(
                    this.enable,
                    entity,
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.getData();
                    this.selectedRowKey = [];
                })
            },
            //新增
            create(cases) {
                ModalHelper.create(createaudittask, {
                }, { width: 1000 }).subscribe(res => {
                    console.log(res)
                    this.getData();
                    this.selectedRowKey = [];
                })
            },
            //编辑
            createOrEditM(record) {
                // console.log(record);
                ModalHelper.create(update, {
                    id: record.id
                }, { width: 1000 }).subscribe(res => {
                    console.log(res)
                    this.getData();
                    // this.selectedRowKey = [];
                })
            },
			//点检
			spotCheck(record) {
				let data = {};
				data.name = record.name;
				data.fomartDateTime = record.fomartDateTime;
				data.auditTaskLabelName = record.auditTaskLabelName;
				data.inspectorName = record.inspectorName;
				localStorage.setItem("AuditTask",JSON.stringify(data));
				this.$router.push({ path: "/app/underlying-data/spot-check?id=" +record.id });
				// ModalHelper.create(spotCheck, {
				//     id: record.id
				// }, { width: 1000 }).subscribe(res => {
				//     this.getData();
				// })
			},
            view1(record) {
                ModalHelper.create(view, {
                    id: record.id
                }, { width: 1000 }).subscribe(res => {
                    console.log(res)
                    if (res) {
                        this.getData();
                    }
                    this.selectedRowKey = [];
                })
            },
            //批量删除事件
            delete1() {
                if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                // if (this.children[0] != undefined) {
                //     abp.message.warn(this.l("审核点下存在审核点，不可删除"));
                //     return;
                // }
                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this.isLoading = true;
                            this.AuditTaskServiceProxy.batchDelete(this.selectedRowKey).then(res => {
                                this.isLoading = false;
                                this.getData();
                                this.selectedRowKey = [];
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })
            },


            //批量删除事件
            delete2(record) {
                let selectid = [];
                selectid.push(record.id);
                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("confirm_deletion"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this.AuditTaskServiceProxy.batchDelete(selectid).then(res => {
                                this.getData();
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })
            },

            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }

            },
            //多选
            SelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKey.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKey.push(item.id);
                            this.selectedRowList.push(item);
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKey = [];
                        this.selectedRowList = [];
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
                //debugger;
				console.log(record.checked)
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKey.push(record.id);
                    this.children.push(record.children);
                    this.selectedRowList.push(record);
                    this.name = record.name;
                    this.number = record.code;
                } else {
                    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
                    this.children = _.difference(_this.children, [record.children]);
                    this.selectedRowList = _.difference(_this.selectedRowList, [record]);
                }
            },
			
			//获取用户层级
			getLevelData() {
				this.isLoading = true;
				this.LayoutLevelServiceProxy.getPaged(
					
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.LevelList = res.items;
				})
			},
			
			LevelChange(value) {
			    this.pageNumber = 1;
			    this.request.skipCount = 0;
			    this.LevelId = value;
			    this.getData();
			},
			
            //搜索框点击事件
            filteronchange() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            AuditTaskStateChange(value) {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.workProcessClass = value;
                this.getData();
            },

            AuditTaskTypeChange(value) {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.AuditTaskType = value;
                this.getData();
            },

            AuditTaskLabelChange(value) {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.AuditTaskLabel = value;
                this.getData();
            },

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            //分页
            onChangePage(page, pageSize) {
                this.selectedRowKey = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            //正常分页展示形式，显示（共有 {0} 条）
            // showTotalFun() {
            // 	console.log(this.totalItems);
            //     return this.l(
            //         "GridFooterDisplayText",
            //         this.totalItems
            //     );
            // },
            showSizeChange(current, size) {
                this.selectedRowKey = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
                this.getData();
            },
            // //导入
            // Export() {
            //     ModalHelper.create(
            //         ImportExcelModel,
            //         {},
            //         {
            //             width: "800px"
            //         }
            //     ).subscribe(res => {
            //         this.getData();
            //     });
            // },
            //导出
            handleMenuClick(rel) {
                if (rel.key == "2") {
                    //模板下载
                    this.AuditTaskServiceProxy
                        .getToImportTempFile()
                        .finally(() => { })
                        .then((res) => {
                            this._fileDownloadService.downloadTempFile(res);
                        });
                } else if (rel.key == "3") {
                    ModalHelper.create(
                        importExcelComponent,
                        {},
                        {
                            width: 400,
                            height: 400
                        }
                    ).subscribe((res) => {
                        //console.log(res);
                        if (res) {
                            //刷新数据
                            this.getData();
                        }
                    });
                }
            },
        },

    }
</script>
<style>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .con {
        padding-bottom: 10px;
    }
</style>