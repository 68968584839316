<template>
	<a-tabs default-active-key="1" @change="change">
		<a-tab-pane key="1" tab="生日祝福" forceRender>
			<birthday-wish></birthday-wish>
		</a-tab-pane>
		<a-tab-pane key="2" tab="入职周年关怀" forceRender>
			<anniversary></anniversary>
		</a-tab-pane>
	</a-tabs>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import BirthdayWish from './birthday-wish/birthday-wish';
import Anniversary from './anniversary/anniversary';
import bus from '@/shared/bus/bus';

export default {
	name: 'care',
	components: { BirthdayWish, Anniversary },
	data() {
		return {
			zh_CN,
		};
	},
	methods: {
		change() {
			//触发表格高度计算
			bus.$emit('calcHeight');
		},
	},
};
</script>

<style scoped></style>
