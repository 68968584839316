<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <span>查看消息记录-{{otherName}}</span>
                </div>
            </div>
            <div class="my-md">
                <a-input-search
                        style="width: 100%;min-width: 100px"
                        name="filterText"
                        :placeholder="l('SearchWithThreeDot')"
                        @search="refresh"
                        enterButton
                        v-model="filterText"
                />
            </div>
            <div>
                <a-table
                        @change="sorterChange"
                        :columns="columns"
                        :pagination="false"
                        :rowKey="(data) => data.id"
                        :dataSource="tableData"
                >
                </a-table>
                <a-pagination
                        class="pagination"
                        :total="totalItems"
                        v-model="pageNumber"
                        :showTotal="showTotalFun"
                        @change="onChangePage"
                        @showSizeChange="showSizeChange"
                />
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {MessageServiceProxy} from "../../../../shared/service-proxies";
    import moment from "moment";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN"


    export default {
        name: "message-record",
        mixins: [ModalComponentBase],
        data() {
            return {
                zh_CN,
                filterText: undefined,
                // 用户表格
                columns: [
                    {
                        title: '发送人',
                        dataIndex: 'sendName',
                        sorter: true,
                        fixed: 'left',
                        width: 100,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'sendName'},
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'creationTime',
                        sorter: true,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'creationTime'},
                    },
                    {
                        title: '消息内容',
                        dataIndex: 'content',
                        sorter: true,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'content'},
                    },
                ],
                tableData: [],
                totalItems: 0, //总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ['10', '20', '30', '40'],
                request: {maxResultCount: 10, skipCount: 0, sorting: ''},
                otherId: undefined,
                otherName:undefined
            }
        },
        created() {
            this.fullData();
            this._messageServiceProxy = new MessageServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            getData() {
                this.loading = true;
                this._messageServiceProxy.getHistoryByOtherUserId(
                    this.otherId,
                    2,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                    .finally(() => {
                        this.loading = false;
                    }).then(res => {
                    this.tableData = res.items;
                    this.tableData.map(item => {
                        item.creationTime = item.creationTime ? moment(item.creationTime).format("YYYY-MM-DD hh:mm:ss") : "";
                    });
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                });
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * 分页
             */
            onChangePage(page, pageSize) {
                this.selectedRowKeys = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.selectedRowKeys = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
                } else {
                    this.request.sorting = undefined;
                }
                this.refresh();
            }
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
