import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const AttendanceRouting: RouteConfig[] = [
    {
        path: 'attendance',
        meta: { title: '班次' },
        component: LayoutBlock,
        redirect: '/app/attendance/shiftinfo',
        children: [
            {
                name: 'shiftinfo',
                path: 'shiftinfo',
                component: () => import('./shiftinfo/shiftinfo.vue'),
                meta: { title: '班次' },
            },
            {
                name: 'attendance-group',
                path: 'attendance-group',
                component: () => import('./attendance-group/attendance-group.vue'),
                meta: { title: '考勤组' },
            },
            {
                name: 'holiday-rules',
                path: 'holiday-rules',
                component: () => import('./holiday-rules/holiday-rules.vue'),
                meta: { title: '假期规则' },
            },
            {
                name: 'attendance-setting',
                path: 'attendance-setting',
                component: () => import('./attendance-setting/attendance-setting.vue'),
                meta: { title: '考勤设置' },
            },
            {
                name: 'user-attendance-plan',
                path: 'user-attendance-plan',
                component: () => import('./user-attendance-plan/user-attendance-plan.vue'),
                meta: { title: '排班' },
            },
            {
                name: 'attmachinedeploy',
                path: 'attmachinedeploy',
                component: () => import('./attmachinedeploy/attmachinedeploy.vue'),
                meta: { title: '考勤设置' },
            },
            {
                name: 'hit-time',
                path: 'hit-time',
                component: () => import('./hit-time/hit-time.vue'),
                meta: { title: '打卡时间' },
            },
            {
                name: 'business-calendar',
                path: 'business-calendar',
                component: () => import('./businessCalendar/businessCalendar.vue'),
                meta: { title: '企业日历' },
            },
            {
                name: 'leave-card-overtime-detailed',
                path: 'leave-card-overtime-detailed',
                component: () => import('./leave-card-overtime-detailed/leave-card-overtime-detailed.vue'),
                meta: { title: '个人明细' },
            },
            {
                name: 'check-in-record',
                path: 'check-in-record',
                component: () => import('./check-in-record/check-in-record.vue'),
                meta: { title: '原始打卡记录' },
            },
            {
                name: 'daily-summary',
                path: 'daily-summary',
                component: () => import('./daily-summary/daily-summary.vue'),
                meta: { title: '考勤日汇总' },
            },
            {
                name: 'user-leave-balance',
                path: 'user-leave-balance',
                component: () => import('./user-leave-balance/user-leave-balance.vue'),
                meta: { title: '假期余额' },
            },
            {
                name: 'month-summary',
                path: 'month-summary',
                component: () => import('./month-summary/month-summary.vue'),
                meta: { title: '考勤月汇总' },
            },
            {
                name: 'replenish-card',
                path: 'replenish-card',
                component: () => import('./replenish-card/replenish-card.vue'),
                meta: { title: '补卡明细' },
            },
            {
                name: 'work-overtime',
                path: 'work-overtime',
                component: () => import('./work-overtime/work-overtime.vue'),
                meta: { title: '加班明细' },
            },
            {
                name: 'ask-for-leave',
                path: 'ask-for-leave',
                component: () => import('./ask-for-leave/ask-for-leave.vue'),
                meta: { title: '请假明细' },
            },
            {
                name: 'excess-max-overtime-record',
                path: 'excess-max-overtime-record',
                component: () => import('./excess-max-overtime-record/excess-max-overtime-record.vue'),
                meta: { title: '加班超额名单' },
            },
            {
                name: 'daily-myerror',
                path: 'daily-myerror',
                component: () => import('./daily-myerror/daily-myerror.vue'),
                meta: { title: '我的考勤异常' },
            },
            {
                name: 'daily-my-subordinate',
                path: 'daily-my-subordinate',
                component: () => import('./daily-my-subordinate/daily-my-subordinate.vue'),
                meta: { title: '下属当月考勤异常' },
            },
            {
                name: 'att-error',
                path: 'att-error',
                component: () => import('./daily-myerror/att-error.vue'),
                meta: { title: '考勤异常' },
            },
            {
                name: 'week-summary',
                path: 'week-summary',
                component: () => import('./week-summary/week-summary.vue'),
                meta: { title: '考勤周汇总' },
            },
        ],
    },
];

export default AttendanceRouting;
