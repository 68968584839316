<template>
	<div>
		<div id="player">
			
		</div>
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" style="margin-right:10px" />
				<span>{{ l('ViewDetails') }}</span>
			</div>
		</div>
		<div>
			<a-config-provider :locale="zh_CN">
				<a-row>
					<a-col :span="24">
						<a-col :span="3" class="audit">
							<p>{{ l('Item') }}:</p>
						</a-col>
						<a-col :span="21">

							<a-popover title="审核描述" trigger="click"  placement="bottomLeft">
								<template slot="content">
									<p v-html="auditItemDesc" style="max-width: 700px;"></p>
								</template>
								<p class="view">{{ auditItemName }}    <a>查看详情<a-icon type="search" /></a></p>
							</a-popover>

							
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="3" class="audit">
							<p>{{ l('Point') }}:</p>
						</a-col>
						<a-col :span="4">
							<p class="view">{{ auditPointName }}</p>
						</a-col>
						<a-col :span="4" class="audit">
							<p>{{ l('audit.inspectorname') }}:</p>
						</a-col>
						<a-col :span="5">
							<p class="view">{{ inspectorName }}</p>
						</a-col>
						<a-col :span="3" class="audit">
							<p>{{ l('Completetime') }}:</p>
						</a-col>
						<a-col :span="5">
							<p class="view">{{ auditTime }}</p>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="3" class="audit">
							<p>{{ l('Results') }}:</p>
						</a-col>
						<a-col :span="4">
							<p class="view">{{ result }}</p>
						</a-col>
						<a-col :span="4" class="audit">
							<p>{{ l('Non-conformity') }}:</p>
						</a-col>
						<a-col :span="5">
							<p class="view">{{ deviationTypeName }}</p>
						</a-col>
						<a-col :span="3" class="audit">
							<p>{{ l('Enterthevalue') }}:</p>
						</a-col>
						<a-col :span="5">
							<p class="view">{{ data }}</p>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="3" class="audit">
							<p>{{ l('audititem.audititemtypeid') }}:</p>
						</a-col>
						<a-col :span="4">
							<p class="view">{{ auditItemTypeName }}</p>
						</a-col>
					</a-col>

					<!-- 					<a-col :span="24" v-if="ISview">
						<a-col :span="2" class="audit">
							<p>{{l('audit.remark')}}:</p>
						</a-col>
						<a-col :span="22">
							<p class="view"></p>
						</a-col>
					</a-col> -->
				</a-row>
			</a-config-provider>
		</div>
		<div>
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" :tab="l('auditrecords')">
					<a-row>
						<a-col :span="24">
							<a-col :span="3" class="audit">
								<p>{{ l('NOTE') }}:</p>
							</a-col>
							<a-col :span="21">
								<p class="view">{{ note }}</p>
							</a-col>
						</a-col>
						<a-col :span="24">
							<a-col :span="3" class="audit">
								<p>{{ l('Livephotos') }}:</p>
							</a-col>
							<a-col :span="20" class="view">
								<div style="width: 100%;display: flex;" v-if="picture != null || video != null">
									<div class="images" v-vuer="{ title: false }" style="min-height: 100px;">
										<img :src="item" :key="item" style="width: 100px;height: 100px;margin-right: 10px;"
											v-if="img" v-for="item in Photo" @click="show()" />
									</div>
									
									<div style="min-height: 100px;">
										<img :src="item.imgUrl" style="width: 100px;height: 100px;margin-right: 10px;" v-for="(item, index) in videoList" @click="doPreviewImage(item.url)" />
									</div>
								</div>
								
								<div class="prompt" v-else>
									<div class="Image">
										<img src="../../../../../public/img/icons/img.png"
											style="width: 30px;height: 30px;" />
										<br />
										<span>{{ l('NotUploaded') }}</span>
									</div>
								</div>
							</a-col>
						</a-col>
					</a-row>
				</a-tab-pane>
				<a-tab-pane key="2" :tab="l('problemrecord')">
					<order-picking :isEdit="isEdit" :auditTaskId="auditTaskId" :auditPointId="auditPointId"
						:auditItemId="auditItemId"></order-picking>
				</a-tab-pane>
			</a-tabs>
		</div>
		<div class="modal-footer" v-if="!isLook">
			<a-button @click="close">
				{{ l('Cancel') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { AuditTaskServiceProxy } from '../../../../shared/service-proxies';
import Order from "../Order/Order.vue";
import OrderPicking from "../OrderPicking/OrderPicking.vue";
import { AppConsts } from "../../../../abpPro/AppConsts";
import 'viewerjs/dist/viewer.css'
import Vuer from 'v-viewer'
import Vue from 'vue'
Vue.use(Vuer, { name: 'vuer' })
import moment from "moment";

let _this;
export default {
	name: 'check-the-details',
	mixins: [ModalComponentBase],
	components: {
		Order,
		OrderPicking,
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
		// this.getDate();
	},
	data() {
		return {
			zh_CN,
			isEdit: false,//是否是编辑
			isLook: false,//是否是查看
			auditPointName: undefined,
			note: undefined,
			remark: undefined,
			data: undefined,
			picture: undefined,
			isEdit: true,
			img: false,
			auditTaskId: undefined,
			auditPointId: undefined,
			auditItemId: undefined,
			realPath: undefined,
			tempPath: undefined,
			photos: undefined,
			Photo: [],
			pictureDate: undefined,
			ISview: undefined,
			auditTime: undefined,
			inspectorName: undefined,
			auditItemName: undefined,
			auditItemTypeName: undefined,
			result: undefined,
			deviationTypeName: undefined,
			video: undefined,
			videoList: []
		}
	},
	methods: {
		getData() {
			console.log(this.record)
			this.ISview = this.isView;
			this.auditPointName = this.record.auditPointName;
			this.note = this.record.note;
			this.remark = this.record.remark;
			this.data = this.record.data;
			this.picture = this.record.picture;
			this.auditTaskId = this.record.auditTaskId;
			this.auditPointId = this.record.auditPointId;
			this.auditItemId = this.record.auditItemId;
			this.realPath = this.record.realPath;
			this.tempPath = this.record.tempPath;
			this.auditTime = this.record.auditTime ? moment(this.record.auditTime).format('YYYY-MM-DD HH:mm:ss') : "";
			this.inspectorName = this.record.inspectorName;
			this.auditItemName = this.record.auditItemName;
			this.auditItemDesc = this.record.auditItemDesc;
			this.auditItemTypeName = this.record.auditItemTypeName;
			this.result = this.record.result;
			this.deviationTypeName = this.record.deviationTypeName;
			this.video = this.record.video;
			if(this.video == '') {
				this.video = null;
			}
			if (this.record.picture == undefined) {
			
			} else {
				this.img = true;
				this.pictureDate = this.picture.split(',');
				console.log(this.pictureDate);
				this.pictureDate.forEach(item => {
					this.photos = this.realPath + '/' + item;
					this.Photo.push(this.photos);
				});
			}
			
			if (this.record.video == undefined) {
			
			} else {
				this.img = true;
				let video = this.video.split(',');
				video.forEach(item => {
					let videoUrl = this.realPath + '/' + item;
					let videoImg = this.realPath + '/' + item.slice(0, item.indexOf('.')) + '.png';
					let videoData = {
						url: videoUrl,
						imgUrl: videoImg
					};
					this.videoList.push(videoData);
				});
			}
		},
		
		show() {
			const vuer = this.$el.querySelector('.images').$vuer
			vuer.show()
		},
		
		doPreviewImage(url) {
			this.videoUrl = url;
			// 创建 video 元素
			var player = document.createElement('video');
			
			// 设置视频源（根据自己的情况修改）
			player.src = url;
			
			// 将 video 元素添加到页面上
			document.getElementById("player").appendChild(player);
			
			// 切换到全屏模式
			if (player.requestFullscreen) {
				player.requestFullscreen();
			} else if (player.mozRequestFullScreen) { /* Firefox */
				player.mozRequestFullScreen();
			} else if (player.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
				player.webkitRequestFullscreen();
			} else if (player.msRequestFullscreen) { /* IE/Edge */
				player.msRequestFullscreen();
			}
			// 添加退出全屏事件监听器
			document.addEventListener('fullscreenchange', function(event) {
			    // 判断当前是否为非全屏状态
			    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
			        this.videoUrl = '';
					player.pause();
					let video = document.getElementById("player").innerHTML = "";
			    }
			});
		}
	}
}
</script>

<style>
.view {
	margin-left: 10px;
}

.audit {
	text-align: right;
}

.prompt {
	height: 117px;
}

.Image {
	width: 100px;
	height: 100px;
	border: 1px solid #c0c0c0;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
}
#player {
    width: 100%;
    height: auto;
}
</style>
