<template>
	<div :class="[themeClass]" style="height: 100%">
		<div :class="{ 'alain-pro__collapsed': collaps }" class="ant-layout ant-layout-has-sider">
			<a-layout-sider :collapsed="collaps" :width="210" class="alain-pro__sider alain-pro__sider-fixed">
				<sidebar class="alain-pro__aside"></sidebar>
			</a-layout-sider>
			<div class="ant-layout ant-pro-content">
				<layout-header></layout-header>
				<a-layout-content class="layout-window">
					<reuse-tab class="layout-tab" v-if="isreuseTab"></reuse-tab>
					<a-layout-content class="layout-content">
						<router-view />
					</a-layout-content>
					<notifications-model
						class="notifications-model"
						v-if="isNotifications"
						@notificationsColse="notificationsColse"
					>
					</notifications-model>
				</a-layout-content>
			</div>
		</div>
	</div>
</template>

<script>
import { ReuseTab } from '@/components';
import { LayoutHeader } from './header';
import { Sidebar } from './sidebar';
import { layoutService } from '@/shared/common';
import bus from '@/shared/bus/bus.js';
import NotificationsModel from './notifications/notifications-model';

export default {
	name: 'layout-default',
	components: {
		LayoutHeader,
		ReuseTab,
		Sidebar,
		NotificationsModel,
	},
	data() {
		return {
			routerkey: this.$router.mode,
			isNotifications: false,
		};
	},
	computed: {
		collaps() {
			return layoutService.data.collapsed;
		},
		theme() {
			return layoutService.data.theme;
		},
		themeClass() {
			return `alain-pro__${this.theme}`;
		},
		isreuseTab() {
			return layoutService.data.reuseTab;
		},
	},
	mounted() {
		bus.$on('clickNotifications', () => {
			this.isNotifications = !this.isNotifications;
			bus.$emit('isclickNotifications', this.isNotifications);
		});
	},
	methods: {
		notificationsColse(record) {
			this.isNotifications = false;
		},
	},
};
</script>

<style lang="less" scoped>
@import './layout-default.less';
</style>
