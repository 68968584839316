<template>
	<a-spin :spinning="isLoading">
	    <div>
	        <div class="modal-header">
	            <div class="modal-title">
	                <a-icon type="medicine-box" style="margin-right:10px" />
	                <span>{{l('AppUserChoose')}}</span>
	            </div>
	        </div>
			<a-card>
				<a-row>
					<a-col :span="8">
						<a-row style="margin-top: 5px"><a-col :span="2"><a-icon type="share-alt" /></a-col>
							<a-col :span="7">{{l('organization')}}</a-col>
							<a-col :span="15"><a-icon type="reload" @click="getTree" style="float: right"/></a-col>
						</a-row>
						
						<a-row>
							<div style="width:100%;overflow:auto;height: 700px;">
								<div class="con" style="width: 100%;min-width: 180px;">
									<a-spin :spinning="isTreeLoading">
									<a-tree
											style="width:100%"
											:expanded-keys="expandedTreeKeys"
											:selected-keys="selectedTreeKeys"
											v-model="checkedTreeKeys"
											:tree-data="treeData"
											:replace-fields="treeReplaceFields"
											@select="onTreeSelect"
											@expand="onTreeExpand"
									/>
									</a-spin>
								</div>
							</div>
						</a-row>
					</a-col>
			
					<a-col :span="16" style="padding-left: 10px;">
						<a-row style="margin-bottom: 20px;">
							<a-col :span="24" class="d-flex justify-content-end">
								<a-input-search :placeholder="l('please_enter_your_username_to_search')" v-model.trim="filterText" enterButton
												style="width: 200px;margin-left: 10px;" @search="filteronchange"
			
								></a-input-search>
							</a-col>
						</a-row>
						<a-spin :spinning="isLoading">
							<a-table :columns="columns"
									 size="small"
									 :data-source="tableData"
									 :customRow="customRow"
									  :scroll="{x:scroll_x,y:600}"
									 :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'20px' }"
									 :rowKey="(record)=>record.id"
									 :pagination="false"
							>
							</a-table>
						</a-spin>
						<a-config-provider :locale="zh_CN">
						<a-pagination
								class="pagination"
								v-model="pageNumber"
								:total="totalItems"
								showSizeChanger
								:defaultPageSize="request.maxResultCount"
								:pageSizeOptions="pageSizeOptions"
								:showTotal="() => showTotal"
								@change="onPageChange"
								@showSizeChange="showPageSizeChange"
						/>
						</a-config-provider>
			
					</a-col>
				</a-row>
			</a-card>
			<div class="modal-footer">
			    <a-button @click="close">
			        {{l('Cancel')}}
			    </a-button>
				<a-button :type="'primary'" @click="save">
				    {{l('Save')}}
				</a-button>
			</div>
		</div>
	</a-spin>
</template>

<script>

    import ModalComponentBase from '@/shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {LayoutUserServiceProxy,LayoutOrgServiceProxy} from '../../../shared/service-proxies';
    import {AppConsts} from "../../../abpPro/AppConsts";
    import moment from "moment";
	import * as _ from 'lodash';
import { indexOf } from 'lodash';
	
	let _this;
    export default {
        name: "Personnel-selection",
        mixins: [ModalComponentBase],
        components: {
			
        },
        created() {
			this.fullData();
            _this = this;
            this._LayoutUserServiceProxy=new LayoutUserServiceProxy(this.$apiUrl, this.$api);
            this._LayoutOrgServiceProxy=new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getTree();
            this.getData();
			this.changeHeight();
        },
        data() {
            return {
                zh_CN,
                tableData: [],
                treeData:[],
                treeReplaceFields: {
                    title: 'levelNameAndName',
                    key: 'id',
                },
                checkedTreeKeys: [],
                expandedTreeKeys:[],
                selectedTreeKeys:[],
                isTreeLoading:false,
                
                
                isLoading:false,
                isEdit: false,
                filterText: undefined,
				respId: undefined,
				isSelect: undefined,
                
                columns: [
                    {title: this.l('organization'),width: 110,dataIndex: 'orgName',align: 'center',},
                    {title: this.l('Users'),width: 65,dataIndex: 'realName',align: 'center',sorter: (a, b) => a.realName.length - b.realName.length,sortDirections: ['descend', 'ascend'],scopedSlots: { customRender: 'name' },},
                ],
                selectedRowKey: [], // 选择的数据Id
                selectedRowList: [],//选择的整行数据
                
                styObj:{
                	height:0
                },
				userType: 0,
            }
        },

        methods:{
            //div高度
            changeHeight(){
            	this.styObj.height=window.innerHeight-580+'px';
            },
            //获取数据
            getTree() {
                this.treeData = [];
                this.isTreeLoading = true;
                this._LayoutOrgServiceProxy.getAllData(
                ).finally(() => {
                    this.isTreeLoading = false;
                }).then(rel => {
                    // rel = [];
                    console.log(rel,"树数据");
                    if(rel.length <= 0){
                        this.treeData = [{levelNameAndName: this.l('Please_create_an_organization'),  id: "0", children: rel,}];
                    }
                    else{
                        this.treeData = [];
                        rel.forEach(item=>{
                            this.treeData.push(item)
                        });
                    }
            		// this.expandedTreeKeys.push('null');
            		this.recursion(rel);
                })
            },
            
            recursion(rel) {
            	console.log(rel);
                if (rel.length > 0) {
                    for (let i = 0; i < rel.length; i++) {
                        console.log(rel[i].id)
            			console.log(rel[i].children);
                        this.expandedTreeKeys.push(rel[i].id)  
                        // this.recursion(rel[i].children);
                    }
                } else {
                    return
                }
            },
            onTreeExpand(expandedKeys, record) {
                console.log(expandedKeys,"expandedKeys");
                console.log(record,"record");
                this.expandedTreeKeys = expandedKeys;
            },
            //架构选择
            onTreeSelect(selectedKeys, info) {
                console.log(selectedKeys, info);
                if (selectedKeys.length > 0) {
                    this.selectedTreeKeys = [];
                    if(isNaN(selectedKeys[0])){
                        this.selectedTreeKeys = selectedKeys;
            
                        this.getData();
                    }
                    else{
                        this.selected = selectedKeys[0];
                        this.selectedTreeKeys = selectedKeys;
            
                        this.getData();
            
                    }
            
                }
            },
            
            //获取数据
            getData() {
                this.isLoading = true;
                this._LayoutUserServiceProxy.getPagedForAssembly(
                    this.selectedTreeKeys.length>0?this.selectedTreeKeys[0]:undefined,
					this.userType,
                    this.filterText ? this.filterText : undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
					if(this.respId) {
						for(let i in this.tableData) {
							if(this.isSelect && this.respId != []) {
								if(this.tableData[i].userId == this.respId) {
									this.selectedRowKey[0] = this.tableData[i].id;
									this.selectedRowList[0] = this.tableData[i];
									return;
								}
							}else {
								for(let l in this.respId) {
									if(this.tableData[i].userId == this.respId[l]) {
										this.selectedRowKey.push(this.tableData[i].id);
										this.selectedRowList.push(this.tableData[i]);
									}
								}
								for(var a=0;a<this.selectedRowList.length - 1;a++){
								    for(var j = a+1;j<this.selectedRowList.length;j++){
								        if(this.selectedRowList[a].userId == this.selectedRowList[j].userId){
								            this.selectedRowList.splice(j,1);
											this.selectedRowKey.splice(j,1);
								        }
								    }
								}
							}
						}
					}
					
                    /*this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];*/
                })
            },
            
            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }
            
            },
            //多选
            SelectAll(record, rows, crows) {
				if(this.isSelect) {
					return;
				}
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKey.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKey.push(item.id);
                            this.selectedRowList.push(item);
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKey = _.difference(_this.selectedRowKey, [item.id]);
                        this.selectedRowList = _.difference(_this.selectedRowList, [item]);
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
				if (this.isSelect) {
					record.checked = true;
					if (record.checked) {
					    this.selectedRowKey = [];
						this.selectedRowList = [];
					    this.selectedRowKey[0] = record.id;
					    this.selectedRowList.push(record);
					} else {
					    this.selectedRowKeys = [];
						this.selectedRowList = [];
					}
				}else {
					record.checked = !record.checked;
					if (record.checked) {
					    this.selectedRowKey.push(record.id);
					    this.selectedRowList.push(record);
					            
					} else {
					    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
					    this.selectedRowList = _.difference(_this.selectedRowList, [record]);
					}
				}
            },
            //搜索框点击事件
            filteronchange() {
                //this.pageNumber = 1;
                //this.request.skipCount = 0;
                this.getData();
            },
			save(){
				this.success(this.selectedRowList);
			}
        }
    }
</script>

<style scoped lang="less">
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
	
	.con {
	    padding-bottom: 10px;
	}
</style>
