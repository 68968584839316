/**
 * 部门树结构帮助类
 */
class DepTreeUntils {
    private static instance: DepTreeUntils = null;

    constructor() {

    }

    static getInstance(): DepTreeUntils {
        if (this.instance == null) {
            this.instance = new DepTreeUntils();
        }
        return this.instance;
    }
    /**
     * 选择下拉框数据格式化
     * @param data
     * @constructor
     */
    InitSelectData(data){
        let res=data.map(item=>{
            return {
                id: item.key,
                value: item.key,
                title: item.title,
                parentId:item.parentId,
                isVirtual:item.isVirtual,
                sortCode: item.sortCode
            }
        });
        return res;
    }

    /**
     * 树新数据格式化
     * @param data
     * @constructor
     */
    InitTreeData(data){
        let res=data.map(item=>{
            return{
                title: item.displayName,
                key: item.id.toString(),
                id:item.id,
                isLeaf: true,
                slots: {
                    icon: ""
                },
                expanded: true,
                isMatched: true,
                isVirtual: item.isVirtual,
                code: item.code,
                memberCount: item.memberCount,
                dto: item,
                parentId: item.parentId,
                children: []
            };
        });
        return res;
    }
    /**
     * 树新数据格式化
     * @param data
     * @constructor
     */
    InitTreeDataByRoles(data){
        let res=data.map(item=>{
            return{
                title: item.displayName+"("+item.roleCount+")",
                key: item.id.toString(),
                id:item.id,
                isLeaf: true,
                slots: {
                    icon: ""
                },
                expanded: true,
                isMatched: true,
                isVirtual: item.isVirtual,
                code: item.code,
                memberCount: item.memberCount,
                dto: item,
                parentId: item.parentId,
                children: []
            };
        });
        return res;
    }
    /**
     * 部门列表转为SelectTree结构
     * @param 数据源
     * @param 父级数据
     * @param 是否包含虚拟部门
     * @constructor
     */
    ArrToTree(source, data, isVirtual) {
        data.forEach(d => {
            let c = source.filter(item => item.parentId == d.id);
            if (c.length > 0) {
                if (isVirtual) {
                    d.children = this.ArrToTree(source, c, isVirtual);
                } else {
                    if (d.isVirtual) {
                        let e = this.ArrToTree(source, c, isVirtual);
                        //子查询结束后移除本身，子级占用父级位置
                        data = data.filter(item => item.id != d.id);
                        data = [...data, ...e];
                    } else {
                        let j = this.ArrToTree(source, c, isVirtual);
                        if (j.length > 0) {
                            d.children = this.ArrToTree(source, c, isVirtual);
                        }
                    }
                }
            } else {
                if (!isVirtual) {
                    if (d.isVirtual) {
                        let e = this.ArrToTree(source, c, isVirtual);
                        //子查询结束后移除本身，子级占用父级位置
                        data = data.filter(item => item.id != d.id);
                        data = [...data, ...e];
                    }
                }
            }
        });
        return data.sort((a, b) => a.sortCode - b.sortCode);
    }

    /**
     * 部门列表转换成Tree
     * @param source
     * @param data
     * @param isVirtual
     * @constructor
     */
    ArrMapTree(source, data, isVirtual){
        data.forEach(d=>{
            let c=source.filter(item=>item.parentId==d.key);

            if(c.length>0){
                d.isLeaf=false;
                if(isVirtual){
                    d.children=this.ArrMapTree(source,c,isVirtual);
                }
                else {
                    if(d.isVirtual){
                        let e=this.ArrMapTree(source,c,isVirtual);
                        //子查询结束后移除本身，子级占用父级位置
                        data=data.filter(item=>item.key!=d.key);
                        data=[...data,...e];
                    }
                    else {
                        let j=this.ArrMapTree(source,c,isVirtual);
                        if(j.length>0){
                            d.children=this.ArrMapTree(source,c,isVirtual);
                        }
                    }
                }
            }
            else {
                if(!isVirtual) {
                    if (d.isVirtual) {
                        let e = this.ArrMapTree(source,c,isVirtual);
                        //子查询结束后移除本身，子级占用父级位置
                        data = data.filter(item => item.key != d.key);
                        data = [...data, ...e];
                    }
                }
            }
        });
        return data;
    }
}

export default DepTreeUntils;
