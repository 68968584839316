import AppCompoentBase from "./app-component-base";
import ModalComponentBase from "./modal-component-base";
import {AppConsts} from '@/abpPro/AppConsts';



const ModalPagedListingComponentBase = {
    mixins: [AppCompoentBase,ModalComponentBase],
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {

    }
};

export default ModalPagedListingComponentBase;

