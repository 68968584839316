<template>

    <a-dropdown class="alain-pro__nav-item" placement="bottomRight">
        <a-menu slot="overlay" style="width:200px;">
            <a-menu-item @click="backToMyAccount()" v-if="isImpersonatedLogin">
                <a-icon type="rollback"/>
                {{ l('BackToMyAccount') }}
            </a-menu-item>
            <a-menu-item @click="changePassword()">
                <a-icon type="dash"/>
                {{ l('ChangePassword') }}
            </a-menu-item>
            <a-menu-item @click="showLoginAttempts()">
                <a-icon type="unordered-list"/>
                {{ l('LoginAttempts') }}
            </a-menu-item>
            <!--            <a-menu-item @click="changeMySettings()">-->
            <!--                <i class="anticon anticon-setting mr-sm"></i>-->
            <!--                {{ l('MySettings') }}-->
            <!--            </a-menu-item>-->
            <a-menu-item @click="emptyallToken()" v-if="isTenantAdmin===1">
                <a-icon type="undo"/>
                {{ l('WipeCache') }}
            </a-menu-item>
            <a-menu-item @click="feedback()" v-if="isFeedback">
                <a-icon type="form" />
                {{ l('Feedback') }}
            </a-menu-item>
            <a-divider/>
            <a-menu-item @click="logout()">
                <a-icon type="logout"/>
                {{ l('Logout') }}
            </a-menu-item>
        </a-menu>


        <div class="alain-pro__nav-item d-flex align-items-center px-sm">
            <a-avatar class="mr-sm" :size="30"
                      :src="profilePreviewImage"
                      :alt="getUserName"
                      v-if="profilePreviewImage"
            >
            </a-avatar>
            <a-avatar
                    style="backgroundColor:rgb(0, 162, 174)"
                    class="mr-sm" :size="30"
                    v-else>
                {{getUserName}}
            </a-avatar>
            {{ getUserName }}
        </div>
    </a-dropdown>

</template>

<script>
    import {AppComponentBase} from '@/shared/component-base';
    import {appAuthService, appSessionService} from "@/shared/abp";
    import {ChangePasswordModal, LoginAttemptsModal, MySettingsModal} from '../../profile';
    import {ModalHelper} from '@/shared/helpers';
    import {
        ProfileServiceProxy,
        TokenAuthServiceProxy,
        UserServiceProxy
    } from '../../../../../src/shared/service-proxies/service-proxies'
    import {abpStore_NS, apiHttpClient, impersonationService, layoutStore_NS} from "@/shared";
    import abpService from "@/shared/abp/abp.service";
    import {AppConsts} from "@/abpPro/AppConsts";
    import CreateOrEditFeedback from '../../../../app/admin/host-feedback/create-or-edit-feedback/create-or-edit-feedback'

    export default {
        name: "header-user",
        mixins: [AppComponentBase],
        components:{CreateOrEditFeedback},
        data() {
            return {
                showNotificationSettings: false,
                profilePreviewImage: '',
                imgPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
                defaultImg:`/assets/images/user.png`
            }
        },
        computed: {
            isImpersonatedLogin() {
                if (abp.session.impersonatorTenantId != null) {
                    return true;
                }
                return false;
            },
            loginUserName() {

                return appSessionService.getShownLoginName();
            },
            isTenantAdmin() {
                return abpService.abp.userInfo.isTenantAdmin;
            },
            getUserName() {
                return abpService.abp.userInfo.realName ? abpService.abp.userInfo.realName : abpService.abp.userInfo.userName
            },
            isFeedback(){
               let a= abp.session.multiTenancySide.toString();
                if(a=="Tenant"){
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        mounted() {
            this._ProfileServiceProxy = new ProfileServiceProxy(
                this.$apiUrl,
                this.$api
            );
            this._tokenAuthService = new TokenAuthServiceProxy(this.$apiUrl, this.$api);
            this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
            // if(appSessionService.user.profilePictureId){
            //     this.getprofilePicture();
            // }
            // else {
            //     this.profilePreviewImage='/assets/images/user.png';
            // }
            if (abpService.abp.userInfo.profilePictureId && abpService.abp.userInfo.userPictureType) {
                this.profilePreviewImage = this.imgPath + appSessionService.user.profilePictureId +abpService.abp.userInfo.userPictureType;
            }
        },
        methods: {
            // getprofilePicture() {
            //     if (abpService.abp.userInfo.profilePictureId && abpService.abp.userInfo.userPictureType) {
            //         this.profilePreviewImage = this.imgPath + appSessionService.user.profilePictureId;
            //     }
            //
            //     // this._ProfileServiceProxy.getProfilePictureById(appSessionService.user.profilePictureId)
            //     //     .then(res=>{
            //     //         if(res.profilePicture){
            //     //             this.profilePreviewImage='data:image/jpeg;base64,' +res.profilePicture;
            //     //         }
            //     // })
            // },
            changePassword() {
                ModalHelper.create(ChangePasswordModal).subscribe((result) => {
                    if (result) {
                        this.logout();
                    }
                });
            },
            showLoginAttempts() {
                ModalHelper.create(LoginAttemptsModal).subscribe(() => {

                });
            },
            changeMySettings() {
                ModalHelper.create(MySettingsModal).subscribe(() => {

                });
            },
            backToMyAccount() {
                impersonationService.backToImpersonator();
            },
            logout() {
                let userId = abpService.abp.session.userId;
                let key = abpService.abp.utils.getCookieValue("SSOGuid");
                if(key){
                    this._tokenAuthService.logOut(userId, key).then(res => {
                        appAuthService.logout();
                    });
                }
                else {
                    appAuthService.logout();
                }


            },
            emptyallToken() {
                this._userServiceProxy.emptyAllToken()
                    .then(res => {
                         appAuthService.logout();
                        this.$notification["success"]({
                            message: this.l("清空成功")
                        });
                    })
            },
            feedback(){
                ModalHelper.create(
                    CreateOrEditFeedback,
                    {},
                    {width:"600px"}
                ).subscribe(res=>{

                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .ant-divider-horizontal {
        margin: 4px 0px;
    }
</style>
