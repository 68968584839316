import Antd, { Icon } from 'ant-design-vue';
import { AppConsts } from '@/abpPro/AppConsts';
import Vue from 'vue';

export function setupIcon() {
	const IconFont = Icon.createFromIconfontCN({
		scriptUrl: AppConsts.remoteServiceBaseUrl + '/iconfont/iconfont.js?t' + new Date().getTime(),
	});
	Vue.component('IconFont', IconFont);
}
