import rootRouting from './root.routing';
import Vue from 'vue'
import router from 'vue-router'
//路由重复点击当前路由
const VueRouterPush = router.prototype.push

router.prototype.push = function push (to) {

  return VueRouterPush.call(this, to).catch(err => err)

}
export {
  rootRouting
}
