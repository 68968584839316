<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 2 }" :wrapper-col="{ span: 4 }" :label="l('Name')">
                            <a-input v-model="name" class="input"></a-input>
                        </a-form-item>
                        <a-col :span="2">
                            <a-form-item :label="l('auditpoint.enable')">
                            </a-form-item>
                        </a-col>
                        <a-col :span="5" style="top: 6px;">
                            <a-switch :checked-children="l('Yes')" :un-checked-children="l('No')" v-model="enable"/>
                        </a-col>
                        <a-col :span="3">
                            <a-form-item :label="l('auditTemplate.isWalkAudit')">
                            </a-form-item>
                        </a-col>
                        <a-col :span="5" style="top: 6px;">
                            <a-switch :checked-children="l('Yes')" :un-checked-children="l('No')" v-model="isWalkAudit"/>
                        </a-col>
                    </a-col>
                    <a-col :span="24">
                        <a-col :span="8">
                            <a-card :title="l('220')">
                                <div style="height: 400px;">
                                    <a-spin :spinning="isTreeLoading">
                                        <a-tree
                                                style="width:100%"
                                                :expanded-keys="expandedTreeKeys"
                                                :selected-keys="selectedTreeKeys"
                                                v-model="checkedTreeKeys"
                                                :tree-data="treeData"
                                                :replace-fields="treeReplaceFields"
                                                @select="onTreeSelect"
                                                @expand="onTreeExpand"
                                        />
                                    </a-spin>
                                </div>
                            </a-card>
                        </a-col>
                        <a-col :span="16">
                            <a-card :title="l('221')">
                                <div style="height: 400px;">
                                    <a-row style="margin-bottom:20px;">
                                        <a-col :span="12">

                                        </a-col>
                                        <a-col :span="12" style="text-align: right;">
                                            <a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
                                                            style="width: 200px;" @search="filteronchange"></a-input-search>
                                        </a-col>
                                    </a-row>
                                    <a-spin :spinning="isLoading">
                                        <a-table :columns="columns"
                                                 :data-source="tableData"
                                                 :customRow="customRow"
                                                 :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
                                                 :rowKey="(record)=>record.auditItemId"
                                                 :scroll="{x:1000,y:396}"
                                                 :pagination="false"
                                                 size="small"
                                        >
                                        </a-table>
                                    </a-spin>
                                </div>
                            </a-card>
                        </a-col>
                    </a-col>
                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('signout')}}
            </a-button>
        </div>
    </div>
</template>

<script>

    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { AuditTemplateServiceProxy,AuditTemplateDetailEditDto,AuditTemplateEditoDto } from '../../../../shared/service-proxies';
    import { ModalHelper } from '@/shared/helpers';


    let _this;

    export default {
        name: "lookmodel",
        mixins: [ModalComponentBase],
        components: {

        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            _this=this;
            this.AuditTemplateServiceProxy = new AuditTemplateServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getTree();
            if(this.isEdit){
                this.getEdit();
            }
        },
        data() {
            return {
                zh_CN,
                id:undefined,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看

                tableData: [],
                columns: [
                    {title: this.l('audititem.audititemtypeid'),width: 150,dataIndex: 'auditItemTypeName',align: 'center',},
                    {title: this.l('audititem.name'), width: 500, dataIndex: 'name', align: 'left',scopedSlots: {customRender: "name"},ellipsis: true},
                ],

                entity: [],
                pId: undefined,
                treeDataList: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                },
                enable: true,
                isTreeLoading:false,
                isLoading:false,
                checkedTreeKeys: [],
                expandedTreeKeys:[],
                selectedTreeKeys:[],
                treeData:[],
                tree:undefined,
                auditItemCount:undefined,
                name:undefined,
                isWalkAudit:false,
                selectedRow:[],
                filterText:undefined,
                selectedRowKey:[],
                //是否禁用
                isdisabled: false,
                selectlist:[],
                itemlist:[],
                savelist:[],
                snlist:[],
            }
        },
        methods: {
            //获取数据
            getTree() {
                this.treeData = [];
                this.isTreeLoading = true;
                this.AuditTemplateServiceProxy.getAllData(
                    this.id
                ).finally(() => {
                    this.isTreeLoading = false;
                }).then(rel => {
                    console.log(rel,"rel");
                    if(rel.length <= 0){
                        this.treeData = [{name: this.l('NoData'),  id: "null", children: rel,}];
                    }
                    else{
                        this.treeData = [];
                        rel.forEach(item=>{


                            if(item.auditItemCount==0) {

                            } else {
                                item.name = item.name+"("+ item.auditItemCount+")";
                            }


                            this.treeData.push(item);
                        });
                    }


                })
            },
            //获取编辑数据
            getEdit() {
                this.isLoading = true;
                this.AuditTemplateServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.entity = res;
                    if(res.name==null){
                        this.enable;
                    }else {
                        this.enable = res.enable;
                    }
                    this.name=res.name;
                    this.isWalkAudit=res.isWalkAudit;
                })
            },

            //获取数据
            getData(){
                this.isLoading=true;
                this.AuditTemplateServiceProxy.getitem(
                    this.id,
                    this.selectedTreeKeys.length>0?this.selectedTreeKeys[0]:undefined,
                    this.filterText
                )
                    .finally(()=>{
                        this.isLoading = false;
                    }).then(val=>{
                    console.log(val,"val");
                    this.tableData=val;
                    this.itemlist=[];
                    this.itemlist=val;
                    this.itemlist.forEach(item=>{
                        item.isdata=true;
                    })
                    this.tableData.forEach(res=>{
                        res.isdata=true;
                    })
                })
            },

            onTreeExpand(expandedKeys, record) {
                console.log(expandedKeys,"expandedKeys");
                console.log(record,"record");
                this.expandedTreeKeys = expandedKeys;
            },
            //架构选择
            onTreeSelect(selectedKeys, info) {
                console.log(selectedKeys, info);
                if (selectedKeys.length > 0) {
                    this.selectedTreeKeys = [];
                    if(isNaN(selectedKeys[0])){
                        this.selectedTreeKeys = selectedKeys;

                        this.getData();
                    }
                    else{
                        this.selected = selectedKeys[0];
                        this.selectedTreeKeys = selectedKeys;

                        this.getData();

                    }

                }
            },
            //新增
            createOrEdit(cases) {
                if (this.selectedTreeKeys.length<=0) {
                    abp.message.info(this.l('select_auditpoint'));
                    return;
                }


                if(this.tableData.length>0){
                    this.savelist=[];
                    this.tableData.forEach(res=>{
                        this.savelist.push(res.auditItemId);
                    })
                }else {
                    this.savelist=[];
                }

                ModalHelper.create(CreatErrorCode, {
                    isEdit: false,
                    id: undefined,
                    selectedRowKey:this.savelist,
                },{width:1200}).subscribe(res => {
                    console.log(res,"回调");
                    var select = res.length;
                    if (select>0) {
                        this.tableData=[];
                        this.selectlist=[];
                        this.savelist=[];
                        this.snlist=[];
                        this.itemlist.forEach(k=>{
                            this.tableData.push(k);
                        });

                        this.selectlist=res;

                        this.selectlist.forEach(item=>{
                            item.isdata=false;
                            var i=true;
                            for(var x=0;x<this.tableData.length;x++){
                                if(item.auditItemId==this.tableData[x].auditItemId){
                                    i=false;
                                    break;
                                }
                            }
                            if(i==true){
                                this.tableData.push(item);
                                this.savelist.push(item.auditItemId);
                                this.snlist.push(item.sn);
                            }
                        })
                    }

                })
            },
            //搜索框点击事件
            filteronchange() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }

            },
            //多选
            SelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKey.find(t => t == item.auditItemId);
                        if (!u) {
                            this.selectedRowKey.push(item.auditItemId);
                            this.selectedRow.push(item);
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKey = [];
                        this.selectedRow = [];
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKey.push(record.auditItemId);
                    this.selectedRow.push(record);
                } else {
                    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.auditItemId]);
                    this.selectedRow = _.difference(_this.selectedRow, [record]);
                }
            },
            save() {
                if (this.name ==null||this.name=="") {
                    abp.message.info(this.l("input_audittemplate"));
                    return;
                }

                var entity1=new AuditTemplateEditoDto();
                if(this.isEdit){
                    entity1.id=this.id;
                }
                entity1.name=this.name;
                entity1.isWalkAudit=this.isWalkAudit;
                entity1.enable=this.enable;

                var list=[];
                for(var i=0;i<this.savelist.length;i++){
                    var entity2=new AuditTemplateDetailEditDto();
                    entity2.auditTemplateId=0;
                    entity2.auditPointId=this.selectedTreeKeys[0];
                    entity2.auditItemId=this.savelist[i];
                    entity2.sn=this.snlist[i];
                    list.push(entity2);
                }

                var resultentity={
                    auditTemplateEditoDto:entity1,
                    auditTemplateDetailEditDto:list
                }
                this.AuditTemplateServiceProxy.createOrUpdate(
                    resultentity
                ).finally(() => {
                    this.close()
                }).then(res => {
                    this.success(true)
                })

            },

            //删除数组中的元素
            arr(arr,res){
                for(var i = 0; i < arr.length; i++) {
                    if(arr[i].auditItemId == res) {
                        arr.splice(i, 1);
                        break;
                    }
                }
            },

            arr2(arr,res){
                for(var i = 0; i < arr.length; i++) {
                    if(arr[i] == res) {
                        arr.splice(i, 1);
                        break;
                    }
                }
            },


            delete1(){
                if (this.selectedRow.length <= 0) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }


                var idlist=[];
                this.selectedRow.forEach(res=>{
                    if(res.isdata){
                        idlist.push(res.id);
                    }
                });

                this.$confirm(
                    {
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk: () => {
                            this.AuditTemplateServiceProxy.batchDeleteDetail(idlist).then(res => {
                                this.getData();
                                this.getTree();
                                this.selectedRow=[];
                                this.selectedRowKey=[];
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted"),
                                });
                            })
                        }
                    })


            }


        }
    }
</script>

<style>
    .input {
        width: 200px;
    }
</style>
