import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const SalaryRouting: RouteConfig[] = [
    {
        path: 'salary',
        meta: { title: '基础资料' },
        component: LayoutBlock,
        redirect: '/app/salary/base-info',
        children: [
            {
                name: 'base-info',
                path: 'base-info',
                component: () => import('./base-info/base-info.vue'),
                meta: { title: '基础资料' },
            },
            {
                name: 'fixed-info',
                path: 'fixed-info',
                component: () => import('./fixed-info/fixed-info.vue'),
                meta: { title: '固定项' },
            },
            {
                name: 'social-security-base',
                path: 'social-security-base',
                component: () => import('./social-security-base/social-security-base.vue'),
                meta: { title: '社保公积金维护' },
            },
            {
                name: 'salary-change',
                path: 'salary-change',
                component: () => import('./salary-change/salary-change.vue'),
                meta: { title: '固定项变动' },
            },
            {
                name: 'salary-change-detail',
                path: 'salary-change-detail',
                component: () => import('./salary-change-detail/salary-change-detail.vue'),
                meta: { title: '固定项变动记录' },
            },
            {
                name: 'month-calc-item',
                path: 'month-calc-item',
                component: () => import('./month-calc-item/month-calc-item.vue'),
                meta: { title: '薪资月度计算项' },
            },
            {
                name: 'salary-detail',
                path: 'salary-detail',
                component: () => import('./salary-detail/salary-detail.vue'),
                meta: { title: '薪资计算' },
            },
            {
                name: 'ss-rules',
                path: 'ss-rules',
                component: () => import('./ss-rules/ss-rules.vue'),
                meta: { title: '社保公积金规则' },
            },
            {
                name: 'salary-setting',
                path: 'salary-setting',
                component: () => import('./salary-setting/salary-setting.vue'),
                meta: { title: '薪资设置' },
            },
            {
                name: 'salary-record',
                path: 'salary-record',
                component: () => import('./salary-record/salary-record.vue'),
                meta: { title: '薪资记录' },
            },
        ],
    },
];

export default SalaryRouting;
