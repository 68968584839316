<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{auditName}}</span>
            </div>
        </div>
        <div>
            <a-row>
                <a-col :span="3" style="text-align: right;">
                    <p>{{l('audit.planstart')}}:</p>
                </a-col>
                <a-col :span="5">
                    <p>{{'&nbsp;&nbsp;&nbsp;'+Plantime}}</p>
                </a-col>
                <a-col :span="3" style="text-align: right;">
                    <p>{{l('inspector')}}:</p>
                </a-col>
                <a-col :span="5">
                    <p>{{'&nbsp;&nbsp;&nbsp;'+inspectorName}}</p>
                </a-col>
                <a-col :span="3" style="text-align: right;" v-if="IsEnabled.templateBarCode">
                    <p>{{l('Barcode')}}:</p>
                </a-col>
                <a-col :span="5" v-if="IsEnabled.templateBarCode">
                    <p>{{'&nbsp;&nbsp;&nbsp;'+(Barcode == null?"":Barcode)}}</p>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="3" style="text-align: right;">
                    <p>{{l('WEStartDate')}}:</p>
                </a-col>
                <a-col :span="5">
                    <p>{{'&nbsp;&nbsp;&nbsp;'+startTime}}</p>
                </a-col>
                <a-col :span="3" style="text-align: right;">
                    <p>{{l('WEEndDate')}}:</p>
                </a-col>
                <a-col :span="5">
                    <p>{{'&nbsp;&nbsp;&nbsp;'+endTime}}</p>
                </a-col>
                <a-col :span="3" style="text-align: right;" v-if="IsEnabled.assitField">
                    <p>{{l('AssitField')}}:</p>
                </a-col>
                <a-col :span="5" v-if="IsEnabled.assitField">
                    <p>{{'&nbsp;&nbsp;&nbsp;'+(AssitField == null?"":AssitField)}}</p>
                </a-col>
            </a-row>
			<a-row>
			    <a-col :span="3" style="text-align: right;" v-if="IsEnabled.filedExt1">
			        <p>{{l('filedExt1')}}:</p>
			    </a-col>
			    <a-col :span="5" v-if="IsEnabled.filedExt1">
			        <p>{{'&nbsp;&nbsp;&nbsp;'+(filedExt1 == null?"":filedExt1)}}</p>
			    </a-col>
			    <a-col :span="3" style="text-align: right;" v-if="IsEnabled.filedExt2">
			        <p>{{l('filedExt2')}}:</p>
			    </a-col>
			    <a-col :span="5" v-if="IsEnabled.filedExt2">
			        <p>{{'&nbsp;&nbsp;&nbsp;'+(filedExt2 == null?"":filedExt2)}}</p>
			    </a-col>
			    <a-col :span="3" style="text-align: right;" v-if="IsEnabled.filedExt3">
			        <p>{{l('filedExt3')}}:</p>
			    </a-col>
			    <a-col :span="5" v-if="IsEnabled.filedExt3">
			        <p>{{'&nbsp;&nbsp;&nbsp;'+(filedExt3 == null?"":filedExt3)}}</p>
			    </a-col>
			</a-row>
        </div>
        <div>
            <a-tabs default-active-key="1">
                <a-tab-pane key="1" :tab="l('auditrecords')">
                    <order-excution :auditID="auditID"></order-excution>
                </a-tab-pane>
                <a-tab-pane key="2" :tab="l('problemrecord')">
                    <order-picking :auditID="auditID" :isEdit="isEdit"></order-picking>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="ExportReport">
                {{l('Export')}}
            </a-button>
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { AuditTaskServiceProxy, SystemConfigServiceProxy } from '../../../shared/service-proxies';
    import OrderExcution from "./OrderExcution/OrderExcution.vue";
    import OrderPicking from "./OrderPicking/OrderPicking.vue";
    import moment from "moment";
    import { Dic, fileDownloadService } from "../../../shared/utils";

    let _this;
    export default {
        name: 'audit-task-view',
        mixins: [ModalComponentBase],
        components: {
            OrderExcution,
            OrderPicking,
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
			this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        mounted() {
            this.getData();
			this.getIsEnable();
        },
        data() {
            return {
                zh_CN,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                id: undefined,
                inspectorName: undefined,
                auditTemplateName: undefined,
                startTime: undefined,
                endTime: undefined,
                auditID: undefined,
                auditName: undefined,
                Plantime: undefined,
                Barcode: undefined,
                AssitField: undefined,
				filedExt1: undefined,
				filedExt2: undefined,
				filedExt3: undefined,
				IsEnabled: {
					filedExt1: false,
					filedExt2: false,
					filedExt3: false,
					templateBarCode: false,
					assitField: false
				}
            }
        },
        methods: {

            ExportReport() {
                this.isLoading = true;
                this.AuditTaskServiceProxy.getToExcelByExportReport(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this._fileDownloadService.downloadTempFile(res);
                })
            },

            getData() {
                console.log(this.id)
                this.isLoading = true;
                this.AuditTaskServiceProxy.getById(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.auditName = res.name;
                    this.auditID = this.id;
                    this.inspectorName = res.inspectorName;
                    this.auditTemplateName = res.auditTemplateName;
                    this.startTime = res.startTime ? moment(res.startTime).format('YYYY-MM-DD HH:mm') : "";
                    this.endTime = res.endTime ? moment(res.endTime).format('YYYY-MM-DD HH:mm') : "";
                    this.Plantime = res.fomartDateTime;
                    this.Barcode = res.templateBarCode;
                    this.AssitField = res.assitField;
					this.filedExt1 = res.filedExt1;
					this.filedExt2 = res.filedExt2;
					this.filedExt3 = res.filedExt3;
                })
            },
			
			//获取是否启用扩展字段
			getIsEnable() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getFiledExtSettings(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.IsEnabled = res;
				})
			},
        }
    }
</script>

<style>
</style>