import AppCompoentBase from './app-component-base';

const ModalComponentBase = {
	mixins: [AppCompoentBase],
	props: ['modelRef', 'modelData'],
	mounted() {
		// const modalHeader = document.getElementsByClassName('modal-header')[0];
		// const modal = document
		// 	.getElementsByClassName('DragContainer')[0]
		// 	.getElementsByClassName('ant-modal-content')[0];
		// modalHeader.style.cursor = 'move';
		// modalHeader.onmousedown = (downEvent) => {
		// 	document.onmousemove = (moveEvent) => {};
		// };
	},
	methods: {
		success(result) {
			if (typeof result !== 'boolean') {
				this.modelRef.close(result);
				return;
			}
			this.modelRef.close(true);
		},
		close() {
			this.modelRef.close(false);
		},
		fullData() {
			if (this.modelData) {
				for (let key in this.modelData) {
					this[key] = this.modelData[key];
				}
			}
		},
	},
};

export default ModalComponentBase;
