<template>
	<a-card>
		<div ref="qTreeContainerRef">
			<div ref="selectRef">
				<slot name="select"></slot>
			</div>
			<div ref="treeRef" class="q-mt-10 q-tree-container">
				<slot name="tree"></slot>
			</div>
		</div>
	</a-card>
</template>

<script>
export default {
	name: 'QTreeContainer',
	mounted() {
		this.$nextTick(this.calcHeight);
		window.addEventListener('resize', this.calcHeight, false);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calcHeight);
	},
	activated() {
		this.$nextTick(() => {
			this.calcHeight();
		});
	},
	updated() {
		this.$nextTick(() => {
			this.calcHeight();
		});
	},
	methods: {
		async calcHeight() {
			const top = await this.getOffsetParent();

			//设置容器高度
			this.$refs.qTreeContainerRef.style.height =
				window.innerHeight - this.$refs.selectRef.offsetHeight - top + 'px';
			this.$refs.qTreeContainerRef.style.maxHeight =
				window.innerHeight - this.$refs.selectRef.offsetHeight - top + 'px';

			//设置树高度
			this.$refs.treeRef.style.height =
				this.$refs.qTreeContainerRef.offsetHeight - this.$refs.selectRef.offsetHeight - 10 + 'px';
			this.$refs.treeRef.style.maxHeight =
				this.$refs.qTreeContainerRef.offsetHeight - this.$refs.selectRef.offsetHeight - 10 + 'px';
		},
		getOffsetParent() {
			return new Promise((resolve) => {
				let parent = this.$refs.selectRef.offsetParent;
				let top = 0;
				while (true) {
					if (parent) {
						top = top + parent.offsetTop;
						if (parent.localName == 'body') {
							break;
						}
						parent = parent.offsetParent;
					} else {
						break;
					}
				}
				resolve(top);
			});
		},
	},
};
</script>

<style scoped lang="less">
div {
	box-sizing: border-box;
}

.q-tree-container {
	overflow-y: scroll;
}
/deep/ .ant-card-body {
	padding: 7px;
}
/* 设置全局滚动条的样式 */
/deep/ .q-tree-container::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	padding-top: 10px;
}
/* 滚动槽 */
/deep/ .q-tree-container::-webkit-scrollbar-track {
	-webkit-box-shadow: 0 0 0 #ccc;
	border-radius: 10px;
}
/* 滚动条滑块 */
/deep/ .q-tree-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0 0 #ccc;
}
/* 鼠标悬停 滚动条滑块 */
/deep/ .q-tree-container::-webkit-scrollbar-thumb:hover {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 0 #ccc;
}
</style>
