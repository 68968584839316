<template>
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{l('Problemphoto')}}</span>
		    </div>
		</div>
		<div class="images" v-vuer="{title: false}" style="min-height: 100px;">
			<img :src="item" :key="item" style="width: 100px;height: 100px;margin-right: 10px;" v-if="img" v-for="item in photo" @click="show()" />
		</div>
		<div class="modal-footer" v-if="!isLook">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { ModalHelper } from '@/shared/helpers';
	import {AppConsts} from "../../../../abpPro/AppConsts";
	import 'viewerjs/dist/viewer.css'
	import Vuer from 'v-viewer'
	import Vue from 'vue'
	Vue.use(Vuer, {name: 'vuer'})

	
	let _this;
	export default {
		name: 'photos',
		mixins: [ModalComponentBase],
		components: {
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
		},
		mounted() {
		    this.getData();
		},
		data() {
		    return {
				zh_CN,
		        isEdit: false,//是否是编辑
		        isLook: false,//是否是查看
				img:false,
				photos: undefined,
				pic: undefined,
				photo: [],
				previewVisible: false,
				// 上传的接口地址
				uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
				//图片的请求头信息
				picUploadHeaders: {
				    Authorization: "Bearer " + abp.auth.getToken(),
				    type: "photo"
				},
				previewImage: '',
				discoveryPicture:'',
				discoveryPicturelist:[],
		    }
		},
		methods: {
			getData() {
				console.log(this.picture);
				if(this.picture==null){
												
				}else {
					this.img = true;
					this.pic =this.picture.split(',');
					console.log(this.pic);
					this.pic.map(item => {
						console.log(item);
						this.photos = this.realPath +'/'+ item;
						this.photo.push(this.photos);
					})
					console.log(this.photo);
				}
			},
			show () {
			        const vuer = this.$el.querySelector('.images').$vuer
			        vuer.show()
			},
		}
	}
</script>

<style>
</style>
