import Vue from 'vue';
import VueRouter from 'vue-router';
import {accountRouting} from '@/account';

import {appRouting} from '@/app';
import SetPasswordRouting from "@/account/set-password.routing";
import LPA from '../app/kanban/LPA/LPA.vue';
import FSKanBan from '../app/kanban/FS-KanBan/FS-KanBan.vue';
import Rotate from '../app/kanban/Rotate/Rotate.vue';
import K1KanBan from '../app/kanban/K1-KanBan/LPA.vue';
import BJKanBan from '../app/kanban/BJ-KanBan/LPA.vue';

Vue.use(VueRouter);

const rootRooting = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...accountRouting,
        ...SetPasswordRouting,
        ...appRouting,
		{
		  path: '/app/kanban/LPA',
		  name: 'LPA',
		  component: LPA,
		  meta: {
		    title: 'LPA'
		  }
		},
		{
		  path: '/app/kanban/FS-KanBan',
		  name: 'FS-KanBan',
		  component: FSKanBan,
		  meta: {
		    title: 'FS-KanBan'
		  }
		},
		{
		  path: '/app/kanban/Rotate',
		  name: 'Rotate',
		  component: Rotate,
		  meta: {
		    title: 'Rotate'
		  }
		},
		{
		  path: '/app/kanban/K1-KanBan',
		  name: 'K1-KanBan',
		  component: K1KanBan,
		  meta: {
		    title: 'K1-KanBan'
		  }
		},
		{
		  path: '/app/kanban/BJ-KanBan',
		  name: 'BJ-KanBan',
		  component: BJKanBan,
		  meta: {
		    title: 'BJ-KanBan'
		  }
		}
    ]
});
export default rootRooting;
