import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const SystemAdminIstrationRouting: RouteConfig[] = [
  {
    path: "systemadministration",
    meta: { title: "菜单&系统管理" },
    component: LayoutBlock,
    redirect: "/app/systemadministration/host-module",
    children: [
      {
        name: "host-module",
        path: "host-module",
        component: () => import("./host-module/host-module.vue"),
        meta: { title: "host-module" },
      },
	  {
	    name: "app-host-module",
	    path: "app-host-module",
	    component: () => import("./app-host-module/app-host-module.vue"),
	    meta: { title: "app-host-module" },
	  },
      {
	    name: "system-config",
	    path: "system-config",
	    component: () => import("./system-config/system-config.vue"),
	    meta: { title: "system-config" },
	  },
    ],
  },
];
export default SystemAdminIstrationRouting;
