<template>


    <a-dropdown class="alain-pro__nav-item" placement="bottomRight">
        <a-menu slot="overlay" style="width:200px;">
            <a-menu-item @click="appLogs()">
                <a-icon type="profile" />
                {{l("updatelog")}}
            </a-menu-item>
            <a-menu-item @click="changeTheme()">
                <a-icon type="bg-colors"></a-icon>
                {{l("SwitchTheme")}}
            </a-menu-item>

        </a-menu>

        <a-icon type="setting"></a-icon>
    </a-dropdown>

</template>

<script>
    import {AppComponentBase} from '@/shared/component-base';
    import {layoutService} from '@/shared/common';
    import {AppConsts} from "@/abpPro/AppConsts";

    export default {
        name: "header-settings",
        mixins: [AppComponentBase],
        data() {
            return {}
        },
        methods: {
            changeTheme() {
                layoutService.changeTheme();
            },
            appLogs(){
                window.open(AppConsts.AppLogsUrl);
            }
        }
    }
</script>

<style scoped>

</style>
