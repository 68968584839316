/**
 * 应用使用的常量
 */
export class AppConsts {
	/**
	 * 远程服务器地址
	 */
	static remoteServiceBaseUrl: string;

    /**
	 * OSS 下载文件请求地址
	 */	
     static OSSRequestUrl: string;

	/**
	 * 门户地址
	 */
	static portalBaseUrl: string;

	/**
	 * 当前应用地址
	 */
	static appBaseUrl: string;

	/**
	 * 上传文件api路径
	 */
	static uploadApiUrl = '/api/File/Upload';

	/**
	 * 个人头像上传最大MB
	 */
	static maxProfilPictureMb = 1;

	/**
	 * 公司图片上传最大MB
	 */
	static maxCompanyPictureMb = 5;

	/**
	 * 文件上传最大MB
	 */
	static maxProFileMb = 5;

	/**
	 * 后端本地化和前端angular本地化映射
	 */
	static localeMappings: any;

	/**
	 * 后端本地化和ng-zorro本地化映射
	 */
	static ngZorroLocaleMappings: any;

	/**
	 * 后端本地化和ng-alian本地化映射
	 */
	static ngAlainLocaleMappings: any;

	/**
	 * 后端本地化和moment.js本地化映射
	 */
	static momentLocaleMappings: any;

	static readonly userManagement = {
		defaultAdminUserName: 'admin',
	};

	static readonly localization = {
		defaultLocalizationSourceName: 'QYB',
	};

	static readonly authorization = {
		encrptedAuthTokenName: 'enc_auth_token',
	};

	/**
	 * 数据表格设置
	 */
	// tslint:disable-next-line:member-ordering
	static readonly grid = {
		/**
		 * 每页显示条目数
		 */
		defaultPageSize: 10,
		/**
		 * 每页显示条目数下拉框值
		 */
		defaultPageSizes: [5, 10, 15, 20, 25, 30, 50, 80, 100],
	};

	/**
	 * top bar通知组件中获取通知数量
	 */
	static readonly notificationCount = 5;
	/**
	 * 审批流跳转地址
	 */
	static LR_Url: any;
	/**
	 * 扫码入职地址
	 */
	static EntryUrl: any;
	/*
	 *默认租户
	 */
	static DefaultTenant: any;

	/**
	 * 消息详情查看详情跳转的默认模块Id
	 */
	static DefaultMsgLinkModuleId: any;

	/**
	 * 系统更新日志远程地址
	 */
	static readonly AppLogsUrl = 'https://e.gitee.com/ssoft/projects/273270/docs/853401/file/2179044/preview';
	/**
	 * 菜单图标远程库
	 */
	static readonly FontIcon = '//at.alicdn.com/t/font_2811377_3s2f4ert9sl.js';
	/**
	 * 登录页选择租户信息默认过期时间(天)
	 */
	static readonly  TenantInfoExpiresTime=30;
}
