<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{l('Report')}}</span>
            </div>
        </div>
        <!--startprint-->
        <div id="printMe">
            <a-descriptions bordered size="small" :column="2">
                <a-descriptions-item label="business unit">
                    {{businessunit}}
                </a-descriptions-item>
                <a-descriptions-item label="BU manager">
                    {{BUmanager}}
                </a-descriptions-item>

                <a-descriptions-item label="audit area">
                    {{auditarea}}
                </a-descriptions-item>
                <a-descriptions-item label="area responsible">
                    {{arearesponsible}}
                </a-descriptions-item>
                <a-descriptions-item label="date">
                    {{date}}
                </a-descriptions-item>
                <a-descriptions-item label="Auditer">
                    {{Auditor}}
                </a-descriptions-item>

            </a-descriptions>
            <br>
            <a-config-provider :locale="zh_CN">
                <div class="tableClass">
					<table>
					    <tbody style="border: 1px solid #000;">
					        <tr v-for="(item,key) in tableData" style="text-align: center;border: 1px solid #000;">
					            <td style="width: 4%;background-color: #dfdfdf;border: 1px solid #000;" v-if="key==0">
					                <b>Category</b>
					            </td>
					            <td style="width: 8%;background-color: #dfdfdf;position: relative;border: 1px solid #000; text-align: left;vertical-align: top;" :class="key > 8 ?'trClass':''"
					                v-else>
									<div style="margin-bottom: 20px;text-align: left;" class="heightClass">
									    <b>{{item.auditItemGroupFirstName}}</b>
									</div>
					                <div
					                    style="height: 20px;width:100%;background-color: #999999;position: absolute;bottom: 0;">
					                </div>
					            </td>
					
					
					            <td style="width: 8%;background-color: #dfdfdf;border: 1px solid #000;" v-if="key==0">
					                <b>Subtopic</b>
					            </td>
					            <td style="width: 8%;background-color: #dfdfdf;position: relative;border: 1px solid #000;vertical-align: top;" :class="key > 8 ?'trClass':''"
					                v-else>
									<div style="margin-bottom: 20px;text-align: left;" class="heightClass">{{item.auditItemGroupSecondName}}
									</div>
					                <div
					                    style="height: 20px;width:100%;background-color: #999999;position: absolute;bottom: 0;line-height: 20px;">
					                    rating</div>
					            </td>
					
					
					            <td style="width: 12%;border: 1px solid #000;" v-for="itemchird in item.task_Report_Type"
					                v-if="key == 0">
					                <b>{{itemchird.auditItemTypeName}}</b>
					            </td>
					            <td style="width: 12%;position: relative;border: 1px solid #000; vertical-align: top;" :class="key > 8 ?'trClass':''"
					                v-for="itemchird in item.task_Report_Type" v-if="key != 0">
									<div class="divClass">
										<div style="margin-bottom: 20px;text-align: left;" class="printClass tdClass scaled">
										    {{itemchird.auditItemName}}</div>
									</div>
					                <div style="height: 20px;width:100%;background-color:blue;position: absolute;bottom: 0;color: white;"
					                    v-if="itemchird.issueCount == 0">{{itemchird.issueCount}}</div>
					                <div style="height: 20px;width:100%;background-color:red;position: absolute;bottom: 0;color: white;"
					                    v-if="itemchird.issueCount != 0">{{itemchird.issueCount}}</div>
					            </td>
					
					
					            <td style="width: 4%;background-color: #dfdfdf;border: 1px solid #000;" v-if="key==0">
					                <b>Total 6IMS Tags</b>
					            </td>
					            <td style="width: 4%;background-color: #dfdfdf;position: relative;border: 1px solid #000;" :class="key > 8 ?'trClass':''"
					                v-else>
									<div style="margin-bottom: 20px;" class="heightClass">{{getIssueCount(item.task_Report_Type)}}</div>
					                <div
					                    style="height: 20px;width:100%;background-color: #999999;position: absolute;bottom: 0;">
					                </div>
					            </td>
					
					
					            <td style="width: 12%;border: 1px solid #000;" v-if="key==0">
					                <b>Explanation</b>
					            </td>
					            <td style="width: 12%;position: relative;border: 1px solid #000;vertical-align: top;" :class="key > 8 ?'trClass':''"
					                v-else>
									<div style="margin-bottom: 20px;text-align: left;" class="heightClass">
									    <div v-for="items in item.task_Report_Type">
									        <div v-for="data in items.issueDescriptionArr" class="images"
									            v-vuer="{title: false}">
									            {{data.issueDescription}}
									            <img :src="uploadPictureUrl + photo" :key="photo"
									                style="width: 20px;height: 15px;margin-left: 10px;"
									                v-for="photo in data.discoveryPicture" @click="show()" />
									        </div>
									    </div>
									</div>
					                <div
					                    style="height: 20px;width:100%;background-color: #999999;position: absolute;bottom: 0;">
					                </div>
					            </td>
					        </tr>
					    </tbody>
					
					    <tr style="text-align: center;border: 0;" v-if="tableData.length >= 2">
					        <td style="width: 8%; border: 0;">
					        </td>
					
					
					        <td style="width: 8%;border: 0;">
					            <div
					                style="margin-top: 25px;height: 60px;line-height: 60px;width:100%; border-top: 1px solid gray;border-left: 1px solid gray;border-bottom: 1px solid gray;">
					                Result</div>
					        </td>
					
					
					        <td style="width: 12%;border: 0;" v-for="(item,key) in Issuelist">
					            <div style="margin-top: 25px;height: 60px;line-height: 60px;width:100%; border-top: 1px solid gray;border-left: 1px solid gray;border-bottom: 1px solid gray; background-color: green;color: white;"
					                v-if="item == tableDatalength">
					                {{getpercentage(item)}}
					            </div>
					            <div style="margin-top: 25px;height: 60px;line-height: 60px;width:100%; border-top: 1px solid gray;border-left: 1px solid gray;border-bottom: 1px solid gray; background-color: red;color: white;"
					                v-if="item != tableDatalength">
					                {{getpercentage(item)}}
					            </div>
					        </td>
					
					
					        <td style="width: 4%;border: 0;">
					            <div
					                style="margin-top: 25px;height: 60px;line-height: 60px;width:100%; border-top: 1px solid gray;border-left: 1px solid gray;border-bottom: 1px solid gray;">
					                {{getTotalResult()}}
					            </div>
					        </td>
					
					
					        <td style="width: 10%;border: 0;">
					            <div
					                style="margin-top: 25px;height: 60px;line-height: 60px;width:100%; border: 1px solid gray;">
					                Total Result
					            </div>
					        </td>
					    </tr>
					</table>
				</div>
            </a-config-provider>

            <div style="height: 500px;width: 100%;border-top: 1px solid #dfdfdf;border-bottom: 1px solid #dfdfdf;text-align: left"
                id="task_report">

            </div>

        </div>
        <!--endprint-->
        <div class="modal-footer" v-if="!isLook">
            <a-button type="primary" v-print="'#printMe'">
                {{l('打印')}}
            </a-button>
            <a-button @click="close">
                {{l('signout')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { AuditTaskServiceProxy, IssueTypeEditDto, LeaveRecordEditDto } from '../../../../shared/service-proxies';
    import { Dic } from "../../../../shared/utils";
    import moment from "moment";
    import {
        AppConsts
    } from "../../../../abpPro/AppConsts";
    import * as echarts from 'echarts';
    import 'viewerjs/dist/viewer.css'
    import Vuer from 'v-viewer'
    import Vue from 'vue'
    Vue.use(Vuer, { name: 'vuer' })
    import print from 'vue-print-nb'

    directives: {
        print   
    }

    let _this;

    export default {
        name: "task_report",
        mixins: [ModalComponentBase],
        components: {

        },
        created() {
            _this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            this.getData()
        },
        data() {
            return {
                zh_CN,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: [],
                id: undefined,
                LeaveReasonList: [],
                LeaveReason: undefined,
                LeaveNote: undefined,
                tableData: [],

                Issuelist: [],
                tableDatalength: 0,
                charttitlelist: [],
                chartvaluelist: [],
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl,
                businessunit: undefined,
                BUmanager: undefined,
                Auditor: undefined,
                auditarea: undefined,
                arearesponsible: undefined,
                date: undefined,
            }
        },
        methods: {

            

            getIssueCount(itemchird) {
                var count = 0;
                for (let i = 0; i < itemchird.length; i++) {
                    count += itemchird[i].issueCount;
                }
                return count;
            },
            getIssueDescription(itemchird) {
                var Description = "";
                for (let i = 0; i < itemchird.length; i++) {

                    if (itemchird[i].issueDescription == null) {
                        Description += "";
                    }
                    else {
                        Description += itemchird[i].issueDescription;
                    }
                }
                return Description;
            },
            getpercentage(item) {
                 
                return ((item / _this.tableDatalength) * 100).toFixed(0) + "%";
            },
            getTotalResult() {
                var s1 = 0;
                var s2 = 0;
                for (let index = 0; index < this.Issuelist.length; index++) {
                    s1 += this.Issuelist[index];
                    s2 += _this.tableDatalength;
                }
				if(this.Issuelist.length==0) {
					return "100%";
				}else {
					return ((s1 / s2) * 100).toFixed(0) + "%";
				}
            },
            //获取数据
            getData() {
                this.isLoading = true;
                this.AuditTaskServiceProxy.task_Report(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);

                    this.businessunit = res.businessunit;
                    this.BUmanager = res.bUmanager;
                    this.Auditor = res.auditor;
                    this.auditarea = res.auditarea;
                    this.arearesponsible = res.arearesponsible;
                    this.date = res.date;

                    this.tableData = res.task_ReportList;
                    //审核项
                    if (this.tableData.length >= 2) {
                        _this.tableDatalength = res.task_ReportList.length - 1;
                        this.tableData[0].task_Report_Type.forEach(element => {
							let data = {};
							data.name = element.auditItemTypeName;
							data.max = 100;
                            _this.charttitlelist.push(data);
                        });;
                        let acltableDatalength = this.tableData.length - 1;
                        console.log(acltableDatalength);
                        for (let index = 1; index <= acltableDatalength; index++) {
                            let indecchirdlist = this.tableData[index].task_Report_Type;
                            console.log(indecchirdlist);
                            for (let indecchird = 0; indecchird < indecchirdlist.length; indecchird++) {
                                if (this.Issuelist[indecchird] == undefined) {
                                    this.Issuelist.push((acltableDatalength) - indecchirdlist[indecchird].issueCount)
                                }
                                else {
                                    this.Issuelist[indecchird] -= indecchirdlist[indecchird].issueCount;
                                }
                            }
                        }
                    }
                    
                    console.log(this.Issuelist);

                    this.Issuelist.forEach(element => {
                        _this.chartvaluelist.push(((element / _this.tableDatalength) * 100).toFixed(0))
                    });

                    _this.LineChartOutline();

                })
            },

            show() {
                const vuer = this.$el.querySelector('.images').$vuer
                vuer.show()
            },

            LineChartOutline() {
                // console.log(document.getElementById("task_report"));
                this.myeChart = echarts.init(document.getElementById("task_report"))
                this.myeChart.clear();

                let option = {
                    tooltip: {
                        trigger: 'axis',
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
					radar: {
					    indicator: _this.charttitlelist,
						nameGap:25
					},
                    series: [
                        {
                            name: '',
                            type: 'radar',
                            data: [
								{
									value: _this.chartvaluelist,
									label: {
									    normal: {
									        show: true,
									        formatter:function(params) {
												return params.value+'%';
									        }
									    },
									}
								}
							],
                        }
                    ]
                }

                // 使用刚指定的配置项和数据显示图表。
                this.myeChart.setOption(option);
                this.myeChart.resize();
                window.addEventListener('resize', () => {
                    this.myeChart.resize();
                })
            },


        }
    }
</script>

<style media="print">
    * {
        margin: 0;
        padding: 0;
    }

    .input {
        width: 200px;
    }
    @media print {
		@page {
		  size:  29.7cm 42cm;
		  margin: 40px 20px;
		  padding: 0;
		}

		.noprint {
		  display: none;
		}
		
		.tableClass {
			height: 1460px;
			overflow-y: hidden
		}
		
		.trClass {
			display: none
		}
		
		.printClass {
			width: 130%;
			height: 160px;
			margin-top: -25px;
			margin-left: -15%
		}
		.heightClass {
			height: 135px;
		}
		
		.divClass {
			height: 162px;
			overflow-y: hidden;
			padding: 3px;
		}
		
		.scaled {
			transform: scale(0.7);
		}
		
		table {
			font-size: 12px;
		}
	}
</style>
<style>
	.tdClass {
		line-height: 15px;
	}
</style>