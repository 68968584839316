<template>
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{l('Followrecords')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-spin :spinning="isLoading" style="top: -10px;">
					<a-table :columns="columns" :data-source="tableData"
						:rowKey="(record)=>record.id" :scroll="{x:400,y:scroll_x}" :pagination="false" size="small">
					</a-table>
				</a-spin>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
			<a-row>
				<a-button @click="close">
					{{l('Cancel')}}
				</a-button>
			</a-row>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { IssueServiceProxy} from '../../../../shared/service-proxies';
	import {AppConsts} from "../../../../abpPro/AppConsts";
	import moment from "moment";
	
	let _this;
	export default {
		name: 'ViewNumber',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
		    this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.getData();
		},
		data() {
		    return {
				zh_CN,
		        isLook: false,//是否是查看
				isLoading: false, //加载中
				tableData: [],
				columns: [
					{
						title: this.l('User'),
						width: 150,
						dataIndex: 'userName',
						align: 'center',
					},
					{
						title: this.l('FolloTime'),
						width: 150,
						dataIndex: 'subTime',
						align: 'center',
					},
				],
		    }
		},
		methods: {
			getData() {
				this.isLoading = true;
				this.IssueServiceProxy.getIssueSubscribeByUsers(
					this.id
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					console.log(res)
					this.tableData = res;
					this.tableData.map(item => {
						item.subTime = item.subTime ? moment(item.subTime).format('YYYY-MM-DD HH:mm:ss') : "";
					})
				})
			},
		}
	}
</script>

<style>
	.problemview {
		text-align: right;
	}
	
	.body {
		margin-bottom: 10px;
	}
</style>
