<template>
	<div class="container2" style="overflow: hidden">
		<div class="top-wrap">
			<div class="top-part">
				<div class="top-logo">
					<img class="left-img1" src="/assets/images/logos/logo.png" />
					<span>精益助手</span>
				</div>
				<div class="top-text">
					<span class="span">人员</span><a-divider type="vertical" /> <span class="span">质量</span
					><a-divider type="vertical" /> <span class="span">技术</span><a-divider type="vertical" />
					<span class="span">客户</span><a-divider type="vertical" />
					<span class="span">利润</span>
				</div>
			</div>
		</div>

		<router-view />
		<div class="copyright">
			<p>©{{ currentYear }}苏州寻道者软件Ver2.0.1[20211115]</p>
			<p ><a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备15019353号-9</a></p>
		</div>
	</div>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { appSessionService } from '@/shared';

export default {
	name: 'account-layout',
	mixins: [AppComponentBase],
	data() {
		return {
			links: [
				{
					title: 'ABP',
					href: '',
				},
				{
					title: '隐私',
					href: '',
				},
				{
					title: '条款',
					href: '',
				},
			],
			loginBg: '',
		};
	},
	computed: {
		currentYear: (vm) => {
			return new Date().getFullYear();
		},
		versionText: (vm) => {
			return appSessionService.application.version;
		},
	},
};
</script>

<style scoped>
@import './account-layout.less';
</style>
