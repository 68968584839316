import localizationService from '../i18n/localization.service';
import abpService from '../abp/abp.service';
import apiHttpClient from '@/shared/utils/api-http-client';
import { AppConsts } from '@/abpPro/AppConsts';

const AppCompoentBase = {
	data() {
		return {
			loading: false,
			saving: false,
			isReady: false,

			//搜索筛选
			filterText: '',
			/**分页参数**/
			// 总数
			totalItems: 0,
			// 当前页码
			pageNumber: 1,
			// 条数显示范围
			pageRange: [1, 20],
			// 显示条数
			pageSizeOptions: ['10', '20', '50', '100'],
			/**分页参数**/

			/**分页接口基础参数**/
			request: {
				sorting: '',
				maxResultCount: 20,
				skipCount: 0,
			},

			//选择的key
			selectedRowObj: {},
			//表格是否多选
			isMultiple: false,
			scroll_x: 0,
		};
	},
	computed: {
		moduleKey() {
			return abpService.moduleKey;
		},
		$apiUrl() {
			// API 的 访问地址
			return AppConsts.remoteServiceBaseUrl;
		},
		$api() {
			// API 的 http 实例
			return apiHttpClient;
		},
		$notificationCount() {
			return AppConsts.notificationCount;
		},
		message() {
			return abpService.abp.message;
		},
		notify() {
			return abpService.abp.notify;
		},
		//共多少页
		total() {
			return Math.ceil(this.totalItems / this.request.maxResultCount) || 1;
		},
		//分页文字
		showTotal() {
			return `${this.pageNumber}/${this.total}页，共${this.totalItems}条，当前
			${(this.pageNumber - 1) * this.request.maxResultCount + 1}-${this.pageNumber * this.request.maxResultCount}条`;
		},
		//当前选择的ID
		selectedRowKeys() {
			try {
				return Object.values(this.selectedRowObj).map((r) => r.id);
			} catch (error) {
				console.error('computed selectedRowKeys', error);
				return [];
			}
		},
		selectedRows() {
			try {
				return Object.values(this.selectedRowObj);
			} catch (error) {
				console.error('computed selectedRows', error);
				return [];
			}
		},
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {},
				onSelect: (record) => {
					this.rowSelect(record);
				},
				onSelectAll: (selected, selectedRows, changeRows) => {
					if (selectedRows.length > 0) {
						for (let row of selectedRows) {
							if (row.checked) continue;
							this.rowSelect(row);
						}
					} else {
						this.selectedRowObj = {};
					}
				},
			};
		},
	},
	methods: {
		customRow(record, index) {
			return {
				on: {
					click: () => {
						this.rowSelect(record);
					},
				},
			};
		},
		rowSelect(record) {
			//是否多选
			if (this.isMultiple) {
				record.checked = !record.checked;
			} else {
				//重置所有行背景颜色
				this.selectedRowObj = {};
				//单选，如果当前已选中，则取消
				record.checked = true;
			}
			if (record.checked) {
				this.$set(this.selectedRowObj, 'r' + record.id, record);
				this.onRowSelect(record, 'set');
			} else {
				if (this.selectedRowObj['r' + record.id]) {
					this.$delete(this.selectedRowObj, 'r' + record.id);
					this.onRowSelect(record, 'delete');
				}
			}
		},
		onRowSelect(record, type) {
			//console.log('表格行选择回调方法，按需重新此方法 onRowSelect(record, type)');
		},
		onRemoveObjAttrById(id) {
			let findKey = '';
			for (let key in this.selectedRowObj) {
				if (key.indexOf(id) != -1) {
					findKey = key;
					break;
				}
			}
			if (findKey) {
				this.$delete(this.selectedRowObj, findKey);
			} else {
				console.log(`${id}键值不存在`);
				console.log('selectedRowObj', this.selectedRowObj);
			}
		},
		rowClassName(record, index) {
			//如果是多选，不显示亮色
			if (!this.isMultiple) {
				if (this.selectedRowObj['r' + record.id]) {
					//类在 style/qyb.less
					return 'selected';
				}
			}
		},
		l(key, ...args) {
			return localizationService.l(key, ...args);
		},
		/**
		 * 判断是否有改按钮权限
		 * @param mBtnPermissionName
		 * @returns {boolean}
		 */
		isGranted(mBtnPermissionName) {
			if (!mBtnPermissionName) {
				return true;
			}
			//租户管理员
			if (abpService.abp.userInfo.isTenantAdmin === 1) {
				return true;
			}
			//获取模块key
			if (abpService.abp.auth.moduleButtonDic && abpService.moduleKey) {
				if (abpService.abp.auth.moduleButtonDic[abpService.moduleKey]) {
					if (abpService.abp.auth.moduleButtonDic[abpService.moduleKey].indexOf(mBtnPermissionName) !== -1) {
						return true;
					}
				}
			}
			return false;
		},

		/**
		 * 判断是否有改按钮权限(获取指定Id下的权限)
		 * @param mBtnPermissionName
		 * @returns {boolean}
		 */
		isGrantedByModuleKey(mBtnPermissionName,moduleKey) {
			if (!mBtnPermissionName) {
				return true;
			}
			//租户管理员
			if (abpService.abp.userInfo.isTenantAdmin === 1) {
				return true;
			}
			//获取模块key
			if (abpService.abp.auth.moduleButtonDic && moduleKey) {
				if (abpService.abp.auth.moduleButtonDic[moduleKey]) {
					if (abpService.abp.auth.moduleButtonDic[moduleKey].indexOf(mBtnPermissionName) !== -1) {
						return true;
					}
				}
			}
			return false;
		},

		/**
		 * 组合权限 逗号分割
		 * @param permissions
		 * @returns {boolean}
		 */
		isGrantedAny(permissions) {
			if (!permissions) {
				return false;
			}
			for (const permission of permissions.split(',')) {
				if (this.isGranted(permission)) {
					return true;
				}
			}
			return false;
		},
		//搜索框点击事件
		search() {
			this.selectedRowObj = {};
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		refresh() {
			this.filterText = '';
			this.search();
		},
		getData() {
			console.warn('请在混入的页面重写该方法getData');
		},
		/**
		 * 重置分页参数
		 */
		resetRequest() {
			this.request = { maxResultCount: 20, skipCount: 0, sorting: '' };
			this.selectedRowObj = {};
		},
		/**
		 * 分页
		 */
		onPageChange(page) {
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		showPageSizeChange(current, size) {
			this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.getData();
		},
		//排序
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.search();
		},
		/**
		 * 获取当前页面的表格列权限
		 * @returns 表格列
		 */
		getTableColumn() {
			if (abpService.abp.auth.moduleColumnDic && abpService.moduleKey) {
				if (abpService.abp.auth.moduleColumnDic[abpService.moduleKey]) {
					return abpService.abp.auth.moduleColumnDic[abpService.moduleKey];
				}
			}
			return [];
		},
		/**
		 * 获取表格列初始化
		 * @param 表格名称
		 * @param 列数据
		 * @param 列默认宽度
		 * returns {拥有权限的列}
		 */
		getColumn(gridName, column, defaultWidth = 100) {
			this.scroll_x = 0;
			let data = [];
			let codes = [];
			if (column) {
				if (column.length <= 0) {
					return [];
				}
			}
			let columnData = column;

			if (gridName) {
				data = this.getTableColumn().filter((item) => item.gridName == gridName);
			} else {
				data = this.getTableColumn();
			}
			codes = _.map(data, 'enCode');
			//checkbox(默认的勾选框)
			let c = [...columnData.filter((item) => codes.includes(item.dataIndex) || item.dataIndex == 'checkbox')];
			c.forEach((item) => {
				if (item.width) {
					this.scroll_x += parseInt(item.width);
				} else {
					this.scroll_x += defaultWidth;
				}
			});

			return c;
		},
		/**
		 * 获取合并列的表格列数据(合并表头表格)
		 * @param gridName
		 * @param column
		 * @return {*[]|列}
		 */
		getColSpanColumn(gridName, column) {
			this.scroll_x = 0;
			let data = [];
			if (column) {
				if (column.length <= 0) {
					return [];
				}
			}
			let columnData = column;
			if (gridName) {
				data = this.getTableColumn().filter((item) => item.gridName == gridName);
			} else {
				data = this.getTableColumn();
			}
			let codes = _.map(data, 'enCode');
			return this.getChildrenColumn(columnData, codes, { removeCount: 0 });
		},
		/**
		 * 获取合并列的表格列数据(递归子级)
		 * @param 列数据源
		 * @param 需要的列codes
		 * @param 引用类型对象(记录移除的大类型数)
		 * @param 列默认宽度
		 * @return 列
		 */
		getChildrenColumn(data, codes, obj, defaultWidth = 100) {
			data.forEach((item, index) => {
				if (item.children) {
					if (item.children.length > 0) {
						//优先查找子级
						item.children = this.getChildrenColumn(item.children, codes, obj, defaultWidth);
					}
					//没有子级的大类(移除)
					if (item.children.length <= 0) {
						data = [...data.filter((t, i) => t.title != item.title)];
						obj.removeCount += 1;
					}
				} else {
					//最后子级，不存在权限，移除
					if (!codes.includes(item.dataIndex)) {
						data = [...data.filter((t) => t.dataIndex != item.dataIndex)];
					} else {
						if (item.width) {
							this.scroll_x += parseInt(item.width);
						} else {
							this.scroll_x += defaultWidth;
						}
					}
				}
			});
			return data;
		},
        //执行清空
        rowClearAllSelected() {
            Object.values(this.selectedRowObj).forEach((row) => {
            this.rowSelect(row);
            });
            this.selectedRowObj = {};
            //触发回调
            this.onRowClearAllSelected();
        },
        //清空回调
        onRowClearAllSelected() {
        //清空回调
        },

        // base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(',')
            var mime = arr[0].match(/:(.*?);/)[1]
            var bstr = atob(arr[1])
            var n = bstr.length
            var u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            let newfile = new File([u8arr], filename, { type: mime });
            newfile.uid = '1';
            newfile.status = "done";
            newfile.url = '';
                    console.log(newfile);
            return newfile;
        },

        // 图片压缩函数
        compressImg(file,PictureQuality,size) {
            const that = this
            var files
            var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2);
            var read = new FileReader()
			var picWidth
			var picHeight
			var imgWidth
			var imgHeight
            read.readAsDataURL(file)
            return new Promise(function (resolve, reject) {
                read.onload = function (e) {
                    var img = new Image()
                    img.src = e.target.result
                    img.onload = function () {
                        // 默认按比例压缩
                        var w = this.width
                        var h = this.height
						if(size == "3") {
							picWidth = 1920;
							picHeight = 1080;
						}else {
							picWidth = 1280;
							picHeight = 720;
						}
						if(w >= h) {
							imgWidth = w * (picWidth/ w)
							imgHeight = h * (picWidth / w)
						}else {
							imgWidth = w * (picWidth/ h)
							imgHeight = h * (picWidth / h)
						}
						
                        // 生成canvas
                        var canvas = document.createElement('canvas')
                        var ctx = canvas.getContext('2d')
                        var base64
                        // 创建属性节点
                        canvas.setAttribute('width', imgWidth)
                        canvas.setAttribute('height', imgHeight)
                        ctx.drawImage(this, 0, 0, imgWidth, imgHeight)
                        // if (fileSize < 1) {
                        //     // 如果图片小于一兆 那么压缩0.5
                        //     base64 = canvas.toDataURL(file['type'], 0.3)
                        // } else if (fileSize > 1 && fileSize < 2) {
                        //     // 如果图片大于1M并且小于2M 那么压缩0.5
                        //     base64 = canvas.toDataURL(file['type'], 0.6)
                        // } else {
                        //     // 如果图片超过2m 那么压缩0.2
                        //     base64 = canvas.toDataURL(file['type'], 0.9)
                        // }
                        base64 = canvas.toDataURL(file['type'], PictureQuality)
                        // 回调函数返回file的值（将base64编码转成file）
                        files = that.dataURLtoFile(base64, file.name) // 如果后台接收类型为base64的话这一步可以省略
                        resolve(files)
                    }
                }
            })
        }

	},
};

export default AppCompoentBase;
