import { DataItemDetailServiceProxy } from '@/shared';
import { AppConsts } from "@/abpPro/AppConsts";
import apiHttpClient from '@/shared/utils/api-http-client';


class Dic{
    private _dataItemDetailServiceProxy: DataItemDetailServiceProxy = null;
    private static instance: Dic = null;
    constructor() {
        this._dataItemDetailServiceProxy = new DataItemDetailServiceProxy(AppConsts.remoteServiceBaseUrl, apiHttpClient);
    }
    static getInstance(): Dic{
        if(this.instance == null){
            this.instance = new Dic();
        }
        return this.instance;
    }

    /**
     * 异步获取字典列表
     * @param dicCode 字典code
     */
    getDicAsync(dicCode) {
        return new Promise((resolve, reject) => {
            this._dataItemDetailServiceProxy.getListByDataItmeCode(dicCode).then(res => {
                resolve(res);
            }).catch(err => {
                console.error(err);
                reject();
            })
        })
    }

    /**
     * 获取是否字典列表里的是否值
     * @param list 查找的列表
     * @param id 是否的id
     */
    getYesOrNoBool(list, id): Promise<Boolean>{
        return new Promise(async (resolve, reject) => {
            let ret = false;
            for(let item of list){
                if(item.id === id){
                    ret = item.itemDetailName === '是'
                }
            }
            resolve(ret);
        })
    }
}

export default Dic;
