import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';
import abpService from '@/shared/abp/abp.service';

const adminRouting: RouteConfig[] = [
    {
        path: 'admin',
        meta: { title: '系统' },
        component: LayoutBlock,
        redirect: '/app/admin/user',
        children: [
            // { path: 'users', component: () => import(/* webpackChunkName: "admin" */ './users/users.vue'),meta: {title:  "users" } },
            {
                name: 'roles',
                path: 'roles',
                component: () => import(/* webpackChunkName: "admin" */ './roles/roles.vue'),
                meta: { title: '角色' },
            },
            {
                name: 'auditLogs',
                path: 'auditLogs',
                component: () => import(/* webpackChunkName: "admin" */ './audit-logs/audit-logs.vue'),
                meta: { title: '审计日志' },
            },
            {
                name: 'file-manager',
                path: 'file-manager',
                component: () => import(/* webpackChunkName: "admin" */ './file-manager/file-manager.vue'),
                meta: { title: '文件管理' },
            },
            {
                name: 'maintenance',
                path: 'maintenance',
                component: () => import(/* webpackChunkName: "admin" */ './maintenance/maintenance.vue'),
                meta: { title: '维护' },
            },
            {
                name: 'host-settings',
                path: 'host-settings',
                component: () => import(/* webpackChunkName: "admin" */ './host-settings/host-settings.vue'),
                meta: { title: '设置' },
            },
            {
                name: 'editions',
                path: 'editions',
                component: () => import(/* webpackChunkName: "admin" */ './editions/editions.vue'),
                meta: { title: '日志' },
            },
            { path: 'languages', component: () => import(/* webpackChunkName: "admin" */ './languages/languages.vue') },
            {
                name: 'languagetexts',
                path: 'languagetexts',
                component: () => import(/* webpackChunkName: "admin" */ './language-texts/language-texts.vue'),
            },
            {
                name: 'tenants',
                path: 'tenants',
                component: () => import(/* webpackChunkName: "admin" */ './tenants/tenants.vue'),
                meta: { title: '租户' },
            },

            {
                name: 'organization-units',
                path: 'organization-units',
                component: () => import(/* webpackChunkName: "admin" */ './organization-units/organization-units.vue'),
                meta: { title: '部门员工' },
            },
            {
                name: 'subscription-management',
                path: 'subscription-management',
                component: () =>
                    import(/* webpackChunkName: "admin" */ './subscription-management/subscription-management.vue'),
            },
            {
                name: 'tenant-settings',
                path: 'tenant-settings',
                component: () => import(/* webpackChunkName: "admin" */ './tenant-settings/tenant-settings.vue'),
                meta: { title: '设置' },
            },
            {
                name: 'strategy-info',
                path: 'strategy-info',
                component: () => import(/* webpackChunkName: "admin" */ './strategy-info/strategy-info.vue'),
            },
            {
                name: 'host-module',
                path: 'host-module',
                component: () => import('./host-module/host-module.vue'),
                meta: { title: '系统&菜单管理' },
            },
            {
                name: 'tenant-info',
                path: 'tenant-info',
                component: () => import('./tenant-info/tenant-info.vue'),
                meta: { title: '企业信息' },
            },
            {
                name: 'account',
                path: 'account',
                component: () => import('./account/account.vue'),
                meta: { title: '安全策略' },
            },
            {
                name: 'timed-task',
                path: 'timed-task',
                component: () => import('./timed-task/timed-task.vue'),
                meta: { title: '定时任务' },
            },
            {
                name: 'host-feedback',
                path: 'host-feedback',
                component: () => import('./host-feedback/host-feedback.vue'),
                meta: { title: '意见反馈清单' },
            },
            {
                name: 'report',
                path: 'report',
                component: () => import('./host-feedback/report/report.vue'),
                meta: { title: '报表统计' },
            },
            {
                name: 'interface-manager',
                path: 'interface-manager',
                component: () => import('./interface-manager/interface-manager.vue'),
                meta: { title: '接口管理' },
            },
            {
                name: 'interface-authorize-detail',
                path: 'interface-authorize-detail',
                component: () => import('./interface-authorize-detail/interface-authorize-detail.vue'),
                meta: { title: '接口权限清单' },
            },
            {
                name: 'app-update-manage',
                path: 'app-update-manage',
                component: () => import('./app-update-manage/app-update-manage.vue'),
                meta: { title: 'App更新管理' },
            },
			{
			    name: 'multilingual-setting',
			    path: 'multilingual-setting',
			    component: () => import('./multilingual-setting/multilingual-setting.vue'),
			    meta: { title: '255' },
            },

            {
                name: "master-moudlezuhu",
                path: "master-moudlezuhu",
                component: () =>
                    import("./master-moudlezuhu/host-module/host-module.vue"),
                meta: { title: "APP系统&菜单管理" },
            },
			
			{
			    name: "scenario",
			    path: "scenario",
			    component: () =>
			        import("./scenario/scenario.vue"),
			    meta: { title: "260" },
			},

			// {
			//     name: 'the-progress-of-the-problem',
			//     path: 'the-progress-of-the-problem',
			//     component: () => import('./the-progress-of-the-problem/the-progress-of-the-problem.vue'),
			//     meta: { title: '问题进度' },
			// },
            // {
            //     path: 'hangfire',
            //     component: () => import(/* webpackChunkName: "admin" */ './roles/roles.vue'),
            // }
            // { path: 'company', component: () => import(/* webpackChunkName: "admin" */ '../organization/ompany/company.vue')}
        ],
    },
];

export default adminRouting;
