<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="11">
					近<b>{{ monthData }}</b
					>个月即将到期和已到期员工 (共<b>{{ totalItems }}</b
					>人)
				</a-col>
				<a-col :span="6" style="float:right">
					<a-range-picker :allowClear="false" @change="onDateChange" v-model="DateTime" style="width: 100%" />
				</a-col>
				<a-col :span="3" style="float:right">
					<a-tree-select
						@change="companyChange"
						:placeholder="l('Company')"
						style="width: 100%"
						allow-clear
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="CompanyId"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<template slot="actions" slot-scope="text, record">
					<a @click="renewal(record)" v-if="isGranted('renewal')">
						续签
					</a>
					<a-divider type="vertical" />
					<a-popconfirm
						placement="top"
						:okText="l('Ok')"
						:cancelText="l('Cancel')"
						@confirm="noRenewal(record)"
					>
						<template slot="title">
							{{ l('你确定不再续签吗?') }}
						</template>
						<a v-if="isGranted('un_renewal')"> 不再续签</a>
					</a-popconfirm>
				</template>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { abpService, ContractsManageServiceServiceProxy } from '@/shared';
import moment from 'moment';
import { ModalHelper } from '@/shared';
import CreateOrEditPersonnelContracts from '../../../app/admin/users/view-user/create-or-edit-personnel-contracts/create-or-edit-personnel-contracts';
import { CompanyServiceProxy } from '../../../shared/service-proxies/service-proxies';

export default {
	name: 'contracts-manage',
	mixins: [AppComponentBase],
	components: { CreateOrEditPersonnelContracts },
	data() {
		return {
			columns: [
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('Department'),
					dataIndex: 'derpanment',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'derpanment' },
				},
				{
					title: this.l('PcStartDate'),
					dataIndex: 'startTime',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'startTime' },
				},
				{
					title: this.l('PcEndDate'),
					dataIndex: 'endTime',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'endTime' },
				},
				{
					title: this.l('PcCompany'),
					dataIndex: 'company',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'company' },
				},
				{
					title: this.l('PcType'),
					dataIndex: 'contractsType',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'contractsType' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					scopedSlots: { customRender: 'actions' },
					width: '150px',
				},
			],
			tableData: [],
			DateTime: [],
			StartTime: undefined,
			EndTime: undefined,
			monthData: undefined,
			CompanyId: undefined,
			companyTreeList: [],
		};
	},
	created() {
		this._contractsManageServiceServiceProxy = new ContractsManageServiceServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.StartTime = moment();
		this.EndTime = moment().add(2, 'months');
		this.DateTime = [this.StartTime, this.EndTime];
		this.monthData = moment(this.EndTime).diff(moment(this.StartTime), 'months');

		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
		}
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._contractsManageServiceServiceProxy
				.getContractsManageList(
					this.CompanyId,
					this.StartTime,
					this.EndTime,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.startTime = item.startTime ? moment(item.startTime).format('YYYY-MM-DD') : ' ';
						item.endTime = item.endTime ? moment(item.endTime).format('YYYY-MM-DD') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},

		onDateChange(e) {
			if (e.length > 0) {
				this.StartTime = e[0];
				this.EndTime = e[1];
				this.monthData = moment(this.EndTime).diff(moment(this.StartTime), 'months');
			}
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		/**
		 * 续签
		 */
		renewal(record) {
			ModalHelper.create(
				CreateOrEditPersonnelContracts,
				{
					id: undefined,
					userId: record.userId,
				},
				{
					width: '70%',
				}
			).subscribe((res) => {
				if (res) {
					this.loading = true;
					this._contractsManageServiceServiceProxy
						.updateContractsToComplete(record.id)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.getData();
						});
				}
			});
		},
		/**
		 * 不在续签
		 */
		noRenewal(record) {
			this.spinning = true;
			this._contractsManageServiceServiceProxy
				.updateContractsToComplete(record.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					if (res) {
						this.getData();
					}
				});
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
