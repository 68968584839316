<template>
    <a-spin :spinning="isLoading">
        <div>
            <div class="modal-header">
                <div class="modal-title">
                    <a-icon type="medicine-box" style="margin-right:10px" />
                    <span>{{l('AppIssueView')}}</span>
                </div>
            </div>

            <div>
                <a-config-provider :locale="zh_CN">
                    <a-spin :spinning="isLoading" style="top: -10px;">
                    	<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
                    		:rowKey="(record)=>record.id" :pagination="false" size="small">
							<span slot="description" slot-scope="text,record" class="portlet">
								<a @click="view(record)"
									class="view-data">{{ text }}</a>
							</span>
                    		<span slot="action" slot-scope="text,record">
                    			<a @click.stop="edit(record)">
                    				<a-icon type="edit" />{{l('Edit')}}
                    			</a>
                    		</span>
                    	</a-table>
                    </a-spin>
                </a-config-provider>
            </div>

            
            <div class="modal-footer">
                <a-button @click="close">
                    {{l('Cancel')}}
                </a-button>
            </div>
        </div>
    </a-spin>
</template>

<script>

    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {
		APPApiServiceProxy
    } from '../../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
    import {AppConsts} from "../../../../abpPro/AppConsts";
	import CreatErrorCode from "../../problem-management/UpdataIssue/updataIssue.vue";
	import view from "../../problem-management/view/view.vue";
    import moment from "moment";
    export default {
        name: "issue-view",
        mixins: [ModalComponentBase],
        components: {
			CreatErrorCode,
			view
        },

        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
			this.APPApiServiceProxy = new APPApiServiceProxy(this.$apiUrl, this.$api);
        },


        mounted() {
            this.getData();
        },

        data() {
            return {
                zh_CN,
                isLoading:false,
				
				tableData: [],
				columns: [{
						title: this.l('Submissiontime'),
						width: 150,
						dataIndex: 'discoveryTime',
						align: 'center',
						ellipsis: true
					},
					// {title: this.l('issuetype'), width: 100, dataIndex: 'sourceName', align: 'center',},
					{
						title: this.l('issuetype'),
						width: 150,
						dataIndex: 'issueTypeName',
						align: 'center',
					},
					{
						title: this.l('issue.description'),
						width: 250,
						dataIndex: 'description',
						align: 'left',
						ellipsis: true,
						scopedSlots: {
							customRender: "description"
						}
					},
					{
						title: this.l('issueprogress.Progress'),
						width: 120,
						dataIndex: 'issueProgressName',
						align: 'center',
						ellipsis: true
					},
					{
						title: this.l('layoutuser.isresponsible'),
						width: 100,
						dataIndex: 'respName',
						align: 'center',
						ellipsis: true,
					},
					{
						title: this.l('PlanCompleteTime'),
						width: 150,
						dataIndex: 'planEnd',
						align: 'center',
					},
					{
						title: this.l('Actions'),
						width: 80,
						dataIndex: 'action',
						align: 'center',
						scopedSlots: {
							customRender: "action"
						},
						fixed: 'right'
					},
				],
				
				auditTaskId: undefined,
				auditItem: undefined,
				pId: undefined,
				auditItemId: undefined
            }
        },

        methods:{
            getData() {
				this.isLoading = true;
				this.APPApiServiceProxy.getIssueByTaskPoint(
				    this.auditTaskId,
					this.pId,
					this.auditItemId
				).finally(() => {
				    this.isLoading = false;
				}).then(res=>{
				    console.log(res)
					this.tableData = res;
					this.tableData.map(item => {
						item.discoveryTime = item.discoveryTime ? moment(item.discoveryTime).format(
							'YYYY-MM-DD HH:mm') : "";
						item.planEnd = item.planEnd ? moment(item.planEnd).format('YYYY-MM-DD') : "";
					})
				})
			},
			
			//修改
			edit(record) {
				ModalHelper.create(CreatErrorCode, {
					id: record.id,
					isKanban: false,
					iscreate:false,
					auditItem: this.auditItem
				}, {
					width: 1100
				}).subscribe(res => {
					if (res) {
						this.getData();
					}
				})
			},
			
			//查看
			view(record) {
				ModalHelper.create(view, {
					id: record.id,
				}, {
					width: 1000
				}).subscribe(res => {
					if (res) {
						this.getData();
						this.selectedRowKey = [];
						this.selectedRow = [];
					}
				})
			},
        }
    }
</script>

<style scoped>

</style>
