<template>
	<div>
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" style="margin-right:10px" />
				<span>{{ l('ConfirmClosingIssue') }}</span>
			</div>
		</div>
		<div>
			<a-config-provider :locale="zh_CN">
				<a-row>
					<a-col :span="24">
						<a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" :label="l('Completetime')">
							<a-date-picker show-time  v-model="closeTime" :placeholder="l('select_date')" style="width: 100%" />
						</a-form-item>
					</a-col>

					<a-col :span="24">
						<a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" :label="l('Remark')">
							<a-textarea v-model="remark"
								:placeholder="l('please_enter_remark_issueclose')" style="width: 100%"></a-textarea>
						</a-form-item>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
			<a-button @click="close">
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				{{ l('Save') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { IssueServiceProxy } from '../../../../shared/service-proxies';

let _this;
export default {
	name: 'close-issue',
	mixins: [ModalComponentBase],
	components: {

	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getRemark();
	},
	data() {
		return {
			zh_CN,
			isLook: false,//是否是查看
			isremark: false,
			remark: undefined,
			closeTime:undefined,
		}
	},
	methods: {
		//获取关闭问题前为否填写备注
		getRemark() {
			this.isLoading = true;
			this.IssueServiceProxy.getIsCloseIssueFillInRemarks(

			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				this.isremark = res;
			})
		},
		save() {
			if (this.isremark) {
				if (this.isremark) {
					if (this.remark == undefined || this.remark == "") {
						abp.message.info(this.l("CompanyDescriptionInputDesc"));
						return;
					}
					this.closeissue();
				}
			} else {
				this.closeissue();
			}
		},
		closeissue() {
			this.isLoading = true;
			this.IssueServiceProxy.closeIssue(
				this.id,
				this.closeTime,
				this.remark
			).finally(() => {
				this.close();
			}).then(res => {
				this.success(true);
			})
		},
	}
}
</script>

<style></style>
