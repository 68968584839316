import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const organizationRouting: RouteConfig[] = [
    {
        path: 'organization',
        meta: { title: '组织结构' },
        component: LayoutBlock,
        redirect: '/app/organization/company',
        children: [
            //公司
            {
                name: 'company',
                path: 'company',
                component: () => import(/* webpackChunkName: "admin" */ './company/company.vue'),
                meta: { title: '公司' },
            },
            //部门
            {
                name: 'department',
                path: 'department',
                component: () => import(/* webpackChunkName: "admin" */ './department/department.vue'),
                meta: { title: '部门' },
            },
            //人员兼职部门
            {
                name: 'userDepPluralism',
                path: 'userDepPluralism',
                component: () => import(/* webpackChunkName: "admin" */ './userDepPluralism/userDepPluralism.vue'),
                meta: { title: '部门兼职人员' },
            },
            //岗位人员
            {
                name: 'userrelation',
                path: 'userrelation',
                component: () => import('./userrelation/userrelation.vue'),
                meta: { title: '岗位员工' },
            },
            //岗位
            {
                name: 'post',
                path: 'post',
                component: () => import(/* webpackChunkName: "admin" */ './post/post.vue'),
                meta: { title: '岗位' },
            },
            //组织架构图
            {
                name: 'orgchart',
                path: 'orgchart',
                component: () => import(/* webpackChunkName: "admin" */ './orgchart/orgchart.vue'),
                meta: { title: '组织架构图' },
            },
            //登录用户
            {
                name: 'login-user',
                path: 'login-user',
                component: () => import(/* webpackChunkName: "admin" */ './login-user/login-user.vue'),
                meta: { title: '账号' },
            },
            //登录用户
            {
                name: 'roster',
                path: 'roster',
                component: () => import(/* webpackChunkName: "admin" */ './roster/roster.vue'),
                meta: { title: '花名册' },
            },
            //工号规则
            {
                name: 'Job-number-rules',
                path: 'Job-number-rules',
                component: () => import(/* webpackChunkName: "admin" */ './Job-number-rules/Job-number-rules.vue'),
                meta: { title: '工号规则' },
            },
            //岗位等级
            {
                name: 'post-grade',
                path: 'post-grade',
                component: () => import(/* webpackChunkName: "admin" */ './post-grade/post-grade.vue'),
                meta: { title: '岗位等级' },
            },
            //岗位兼职人员
            {
                name: 'concurrent-post',
                path: 'concurrent-post',
                component: () => import(/* webpackChunkName: "admin" */ './concurrent-post/concurrent-post.vue'),
                meta: { title: '岗位兼职人员' },
            },
        ],
    },
];
export default organizationRouting;
