import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const LaborcostRouting: RouteConfig[] = [
    {
        path: 'laborcost',
        meta: { title: '人力成本' },
        component: LayoutBlock,
        redirect: '/app/laborcost/year-plan',
        children: [
            {
                name: 'year-plan',
                path: 'year-plan',
                component: () => import(/* webpackChunkName: "admin" */ './year-plan/year-plan.vue'),
                meta: { title: '全年计划' },
            },
            {
                name: 'month-att-plan',
                path: 'month-att-plan',
                component: () => import(/* webpackChunkName: "admin" */ './month-att-plan/month-att-plan.vue'),
                meta: { title: '月度计划' },
            },
            {
                name: 'dep-diff-rate',
                path: 'dep-diff-rate',
                component: () => import(/* webpackChunkName: "admin" */ './dep-diff-rate/dep-diff-rate.vue'),
                meta: { title: '部门差异率' },
            },
            {
                name: 'month-lc-rate',
                path: 'month-lc-rate',
                component: () => import(/* webpackChunkName: "admin" */ './month-lc-rate/month-lc-rate.vue'),
                meta: { title: '全年差异率' },
            },
            {
                name: 'year-plan-contrast',
                path: 'year-plan-contrast',
                component: () => import(/* webpackChunkName: "admin" */ './year-plan-contrast/year-plan-contrast.vue'),
                meta: { title: '全年计划对比' },
            },
            {
                name: 'dep-plan-contrast',
                path: 'dep-plan-contrast',
                component: () => import(/* webpackChunkName: "admin" */ './dep-plan-contrast/dep-plan-contrast.vue'),
                meta: { title: '部门计划对比' },
            },
        ],
    },
];
export default LaborcostRouting;
