<template>
    <div>
        <a-config-provider :locale="zh_CN">
			<a-card>
				<a-row>
					<a-col :span="2" style="text-align: right;">
						<p>{{l('audit.name')}}:</p>
					</a-col>
					<a-col :span="4">
						<p style="margin-left: 10px;">{{auditTask.name}}</p>
					</a-col>
					<a-col :span="2" style="text-align: right;">
						<p>{{l('FomartDateTime')}}:</p>
					</a-col>
					<a-col :span="4">
						<p style="margin-left: 10px;">{{auditTask.fomartDateTime}}</p>
					</a-col>
					<a-col :span="2" style="text-align: right;">
						<p>{{l('Label')}}:</p>
					</a-col>
					<a-col :span="4">
						<p style="margin-left: 10px;">{{auditTask.auditTaskLabelName}}</p>
					</a-col>
					<a-col :span="2" style="text-align: right;">
						<p>{{l('audit.inspectorname')}}:</p>
					</a-col>
					<a-col :span="4">
						<p style="margin-left: 10px;">{{auditTask.inspectorName}}</p>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="2" style="text-align: right;">
						<p>{{l('Point') + ':'}}</p>
					</a-col>
					<a-col :span="10">
						<a-select showSearch :filterOption="filterOption"
								  v-model="auditPoint.id"
						          style="width: 200px;text-align: left;margin-top: -5px;margin-left: 10px;"
						          dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
						          :placeholder="l('Please_select_a_audit_point')"
						          allow-clear
						          @change="auditPointChange"
						>
						    <a-select-option v-for="item in auditPointList" :key="item.id"
						                     :title="item.name">
						        {{ item.name }}
						    </a-select-option>
						</a-select>
						<span v-if="auditPoint.mustNFC || auditPoint.mustQRCode" style="margin-left: 10px;color: red;">{{l('This_audit_point_requires_on-site_certification')}}</span>
					</a-col>
					<a-col :span="12" style="text-align: right;">
						<a-button type="primary" @click="save">
							{{l('Save')}}
						</a-button>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="24" style="margin-top: 10px;">
						<a-col :span="8">
							<a-card :title="l('AppAuditItem')">
							    <div>
									<a-spin :spinning="isLoading">
									    <a-table :columns="columns" :data-source="tableData" :customRow="customRow"
											:row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,columnWidth:'10px' }"
											 :scroll="{x:500,y:600}"
									        :rowKey="(record)=>record.id" :pagination="false" size="small">
											<span slot="Code" slot-scope="text,record,index" class="portlet">
												<div style="width: 100%;height: 100%" v-if='record.optionType != "7"' :class="{'green' : record.result == 'Y', 'red' : record.result == 'N'}">
													{{index + 1}}
												</div>
												<div style="width: 100%;height: 100%" v-else :style="[record.style]">
													{{index + 1}}
												</div>
											</span>
									    </a-table>
									</a-spin>
								</div>
							</a-card>
						</a-col>
						<a-col :span="16">
							<a-card :title="l('AppAuditItemDetail')">
							    <div>
									<a-col :span="24">
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22, style:'text-align: left'}" :label="l('Description')">
											<div style="width: 100%;table-layout: fixed;word-break: break-all;overflow: hidden;">{{itemDescription}}</div>
										</a-form-item>
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 4 }" :label="l('Auditresults')" v-if="auditItem.optionType=='1'">
											<div style="text-align: left;">
												<a-radio-group v-model="auditItem.valueActual" @change="onChange">
												    <a-radio :value="true">
												      Y
												    </a-radio>
												    <a-radio :value="false">
												      N
												    </a-radio>
												</a-radio-group>
											</div>
										</a-form-item>
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 4 }" :label="l('Auditresults')" v-if="auditItem.optionType=='2'">
											<a-input style="width: 200px;" :placeholder="l('PleaseEnterData')" v-model="auditItem.valueActual" />
										</a-form-item>
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 , style:'text-align: left'}" :label="l('Auditresults')" v-if="auditItem.optionType=='3' || auditItem.optionType=='4' || auditItem.optionType=='5' || auditItem.optionType=='6'">
											<a-input type="number" style="width: 200px;" :placeholder="l('PleaseEnterData')" v-model="auditItem.valueActual" @blur="checkData"/>
											<span style="margin-left: 10px;" v-if="auditItem.optionType=='3'">{{l('StandardValue') + "：" + auditItem.valueLowerLimit + '~' + auditItem.valueUpperLimit}}</span>
											<span style="margin-left: 10px;" v-if="auditItem.optionType=='4'">{{l('StandardValue') + "：" + auditItem.valueStandard + " " + l('NegativeDifference') + "：" + auditItem.valueLowerLimit + " " + l('PositiveDifference') + "：" + auditItem.valueUpperLimit }}</span>
											<span style="margin-left: 10px;" v-if="auditItem.optionType=='5'">{{l('StandardRange') + "：" + '<' + auditItem.valueStandard}}</span>
											<span style="margin-left: 10px;" v-if="auditItem.optionType=='6'">{{l('StandardRange') + "：" + '>' + auditItem.valueStandard}}</span>
											<span style="margin-left: 10px;color: red" v-if="!isQualified">{{l('The_data_is_abnormal_please_confirm_and_add_the_question')}}</span>
										</a-form-item>
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 4 }" :label="l('Auditresults')" v-if="auditItem.optionType=='7' && auditItem.displayForm == '1'">
											<a-select showSearch :filterOption="filterOption"
												style="width: 200px;text-align: left;"
												dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
												:placeholder="l('Please_select_the_audit_item_result')"
												allow-clear
												 v-model="auditItem.valueActual"
												@change="optionListChange"
											>
												<a-select-option v-for="item in optionListDetail" :key="item.name"
													:title="item.name">
													 {{ item.name }}
												</a-select-option>
											</a-select>
										</a-form-item>
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" :label="l('Auditresults')" v-if="auditItem.optionType=='7' && auditItem.displayForm == '2'">
											<div style="text-align: left;">
												<a-radio-group v-model="auditItem.valueActual" @change="onChangeResults">
												    <a-radio :value="item.name" v-for="item in optionListDetail">
												      {{item.name}}
												    </a-radio>
												</a-radio-group>
											</div>
										</a-form-item>
									</a-col>
									<a-col :span="24" v-if="auditItem.optionType=='1'||auditItem.optionType=='7'">
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 4 }" :label="l('Data')">
											<a-input style="width: 200px;" :placeholder="l('PleaseEnterData')" v-model="auditItem.data"/>
										</a-form-item>
									</a-col>
									<a-col :span="24" v-if="auditItem.result == 'N'">
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 4 }" :label="l('NonConformity')">
											<a-select showSearch :filterOption="filterOption"
												style="width: 200px;text-align: left;"
												dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
												:placeholder="l('PleaseSelectNonConformity')"
												allow-clear
												v-model="auditItem.deviationType"
												@change="deviationTypeChange"
											>
												<a-select-option v-for="item in DeviationType" :key="item.id"
													:title="item.itemDetailName">
													 {{ item.itemDetailName }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>
									<a-col :span="24">
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }" :label="l('AuditDesc')">
											<a-textarea :placeholder="l('PleaseEnterDesc')" v-model="auditItem.note"/>
										</a-form-item>
									</a-col>
									<a-col :span="24" style="margin-bottom: -10px;">
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" :label="l('Photo')">
											<a-upload
											
											        style="width: 100%;word-wrap:break-word"
											        :action="uploadPictureUrl"
											        list-type="picture-card"
											        :fileList="discoveryPicturelist"
											        accept="image/*"
											        :headers="picUploadHeaders"
											        :beforeUpload="beforeUpload"
											        :remove="removePic"
											        :multiple="true"
											        @preview="handlePreview"
											        @change="handleChange"
											>
											    <div >
											        <a-icon type="plus"/>
											        <div class="ant-upload-text">
											            {{l('Upload_an_image')}}
											        </div>
											    </div>
											</a-upload>
											<a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
											    <img alt="example" style="width: 100%" :src="previewImage"/>
											</a-modal>
										</a-form-item>
									</a-col>
									<a-col :span="2" style="margin-bottom: 10px;">
										
									</a-col>
									<a-col :span="22" style="margin-bottom: 10px;">
										<a-button type="primary" @click="IssueView">
											{{l('AppIssueView')}}
										</a-button>
										<a-button @click="NewIssue">
											{{l('NewIssue')}}
										</a-button>
									</a-col>
									<a-col :span="24">
										<a-form-item :label-col="{ span: 2 }" :wrapper-col="{ span: 22 , style: 'text-align: left'}" :label="l('Annexes')">
											<a-upload
											        list-type="picture"
											        :action="uploadAnnextureUrl"
											        :beforeUpload="beforeAnnexUpload"
											        :default-file-list="annexFileList"
											        :headers="uploadAnnexHeaders"
											        @change="uploadAnnexChange($event)"
											>
											    <a-button type="primary">
											        <a-icon type="import"/>
											        {{l('Upload')}}
											    </a-button>
											</a-upload>
										</a-form-item>
									</a-col>
							    </div>
							</a-card>
						</a-col>
					</a-col>
				</a-row>
			</a-card>
        </a-config-provider>
    </div>
</template>

<script>

    import { AppComponentBase } from "@/shared/component-base";
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { APPApiServiceProxy } from '../../../../shared/service-proxies';
	import { Dic } from "../../../../shared/utils";
    import { ModalHelper } from '@/shared/helpers';
	import {AppConsts} from "../../../../abpPro/AppConsts";
	import createissue from "../../problem-management/create-issue/create-issue";
	import issueView from "../issue-view/issue-view";
    let _this;

    export default {
        name: "spot-check",
        mixins: [AppComponentBase],
        components: {
			createissue,
			issueView
        },
        created() {
            _this = this;
            this.APPApiServiceProxy = new APPApiServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
			try {
				this.loading = true;
				this.DeviationType = await Dic.getInstance().getDicAsync('DeviationType');
			}catch (e) {
				
            } finally {
                this.loading = false;
            }
			this.auditTask = JSON.parse(localStorage.getItem('AuditTask'));
            this.getDate();
        },
        data() {
            return {
                zh_CN,
				id: this.$route.query.id,
				auditPointList: [],
				auditPoint: {},
				auditItem: {},
				
				isLoading: false, //加载中
				tableData: [],
				columns: [
					{
						title: this.l('Code'),
						width: 30,
						dataIndex: 'index',
						align: 'center',
						// customRender: (text,record,index) => `${index+1}`,
					    ellipsis: true,
						scopedSlots: {
							customRender: "Code"
						}
					},
					{
						title: this.l('Name'),
						width: 150,
						dataIndex: 'itemName',
                        ellipsis: true
					},
					{
						title: this.l('Results'),
						width: 50,
						dataIndex: 'result',
						align: 'center',
					    ellipsis: true
					},
				],
				selectedRowKey: [],
				selectedRow: [],
				auditList: [],
				optionListDetail: [],
				DeviationType: [],
				loading: false,
				
				// 图片上传的接口地址
				uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
				//图片的请求头信息
				picUploadHeaders: {
				    Authorization: "Bearer " + abp.auth.getToken(),
				    type: "photo"
				},
				previewImage: '',
				previewVisible: false,
				discoveryPicture: '',
				discoveryPicturelist:[],
				
				//// 附件上传的接口地址
				uploadAnnextureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadAuditTaskDetail",
				uploadAnnexHeaders: {
				    Authorization: "Bearer " + abp.auth.getToken()
				},
				annexFileList:[
				
				],
				
				//审核任务详情
				auditTask: undefined,
				index: 0,
				isQualified: true,
				
				itemDescription: undefined
            }
        },
        methods: {
            getDate() {
				this.APPApiServiceProxy.getAuditPointByTask(
				    this.id
				).then(res=>{
				    this.auditPointList = res;
					this.auditPoint = res[0];
					if(!this.auditPoint.mustNFC && !this.auditPoint.mustQRCode) {
						this.auditItemDate();
					}
				})
			},
			
			auditPointChange(event) {
				this.auditPoint = this.auditPointList.find((item) => {
				    return item.id === event;
				});
				this.save();
			},
			
			optionListChange(event) {
				let option = this.optionListDetail.find((item) => {
				    return item.name === event;
				});
				
				if(event == "true" || event == "false") {
					this.auditItem.valueActual = Boolean(event);
				}else {
					this.auditItem.valueActual = event;
				}
				console.log(this.auditItem.valueActual)
				this.auditItem.score = option.score;
				if(option.isQualified) {
					this.auditItem.result = "Y";
				}else {
					this.auditItem.result = "N";
				}
			},
			
			deviationTypeChange(event) {
				let deviation = this.DeviationType.find((item) => {
				    return item.id === event;
				});
				this.auditItem.deviationType = event;
				this.auditItem.deviationTypeName = deviation.itemDetailName;
			},
			
			auditItemDate() {
				this.isLoading = true;
				this.APPApiServiceProxy.getAuditItemByTaskPoint(
				    this.id,
					this.auditPoint.id
				).finally(() => {
                    this.isLoading = false;
                }).then(res=>{
				    console.log(res)
					this.tableData = res;
					for(let i in this.tableData) {
						for(let l in this.tableData[i].optionListDetail) {
							let foreColor = "#000";
							let backColor = "#dfdfdf";
							if(this.tableData[i].optionListDetail[l].foreColor) {
								foreColor = this.tableData[i].optionListDetail[l].foreColor;
							}
							if(this.tableData[i].optionListDetail[l].backColor) {
								backColor = this.tableData[i].optionListDetail[l].backColor;
							}
							this.tableData[i].optionListDetail[l].style = {
								color: foreColor,
								backgroundColor: backColor
							};
							if(this.tableData[i].valueActual == this.tableData[i].optionListDetail[l].name) {
								this.tableData[i].style = this.tableData[i].optionListDetail[l].style;
							}
						}
					}
					localStorage.setItem("AuditItemList",JSON.stringify(res));
					this.auditItem = res[0];
					this.optionListDetail = res[0].optionListDetail;
					this.selectedRowKey[0] = res[0].id;
					this.selectedRow[0] = res[0];
					let itemDescription = res[0].itemDescription;
					if(itemDescription){
						let str1 = new RegExp("<p>");
						let str2 = new RegExp("</p>");
						let itemDescription1 = itemDescription.replace(str1,"");
						this.itemDescription = itemDescription1.replace(str2,"");
					}
					if(this.auditItem.result == 'Y') {
						this.isQualified = true;
					}else if(this.auditItem.result == 'N') {
						this.isQualified = false;
					}
					this.picProcessing(res[0]);
				})
			},
			
			picProcessing(date) {
				console.log(date)
				this.discoveryPicture = "";
				this.discoveryPicturelist = [];
				this.annexFileList =[];
				if (date.picture != "" && date.picture != null) {
					if (date.picture.charAt(date.picture.length-1) != ",") {
						this.discoveryPicture=date.picture + ',';
					} else{
						this.discoveryPicture=date.picture;
					}
				}
				if(date.picture) {
					var str=date.picture.split(',');
					str.forEach(item=>{
					    if(item!=null&&item!=""){
					        this.discoveryPicturelist.push(
					            {
					                uid: item,
					                name: item,
					                status: 'done',
					                url: date.dicPath+'/'+item,
					            }
					        )
					    }
					});
				}
			},
			
			onChange(e) {
				if(e.target.value) {
					this.auditItem.result = "Y";
					this.auditItem.valueActual = true;
				}else {
					this.auditItem.result = "N";
					this.auditItem.valueActual = false;
				}
			},
			
			onChangeResults(e) {
				let option = this.optionListDetail.find((item) => {
				    return item.name === e.target.value;
				});
				
				if(e.target.value == "true" || e.target.value == "false") {
					this.auditItem.valueActual = Boolean(e.target.value);
				}else {
					this.auditItem.valueActual = e.target.value;
				}
				this.auditItem.score = option.score;
				if(option.isQualified) {
					this.auditItem.result = "Y";
				}else {
					this.auditItem.result = "N";
				}
				
				let foreColor = "#fff";
				let backColor = "#000";
				if(option.foreColor) {
					foreColor = option.foreColor;
				}
				if(option.backColor) {
					backColor = option.backColor;
				}
				this.auditItem.style = "color: " +  foreColor + ";background: " + backColor + ";";
				
				// let tableData = this.tableData;
				
				// for(let i in tableData)  {
				// 	if(tableData[i].auditItemId == this.auditItem.auditItemId) {
				// 		tableData[i].style = "color: " +  foreColor + ";background: " + backColor + ";";
				// 	}
				// }
				// this.tableData = tableData;
				// console.log(this.tableData)
			},
			
			//行选择
			customRow: (record) => {
			    return {
			        on: {
			            click: () => {
			                _this.onSelect(record)
			            }
			        }
			    }
			
			},
			
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
				this.save();
			    record.checked = true;
			    if (record.checked) {
					this.selectedRowKey = [];
					this.selectedRow = [];
			        this.selectedRowKey[0] = record.id;
			        this.selectedRow[0] = record;
					this.auditItem = record;
					if(this.auditItem.valueActual == "true" || this.auditItem.valueActual == "false") {
						this.auditItem.valueActual = Boolean(event);
					}
					this.picProcessing(record);
					this.optionListDetail = record.optionListDetail;
					
			    } else {
			        this.selectedRowKey = [];
			        this.selectedRow = [];
			    }
			},
			
			filterOption(input, option) {
			    return (
			        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			    );
			},
			
			//图片上传
			beforeUpload(file) {
			    const isJPG =
			        file.type === "image/jpeg" ||
			        file.type === "image/png" ||
			        file.type === "image/gif" ||
			        file.type === "image/bmp";
			    if (!isJPG) {
			        abp.message.error(this.l("请上传正确格式的图片文件"));
			    }
			    const isLtXM = file.size / 1024 / 1024 < 5;
			    if (!isLtXM) {
			        abp.message.error(
			            this.l(
			                "ProfilePicture_Warn_SizeLimit",
			                2
			            )
			        );
			    }
			    return isJPG && isLtXM;
			},
			
			//附件上传
			beforeAnnexUpload(file) {
			    let filetype;
			    if (file.name) {
			        filetype = file.name.substring(file.name.lastIndexOf('.'));
			    }
			},
			
			uploadAnnexChange({file, fileList}) {
			    _this.annexFileList = [];
			    if (file.status === 'done') {
			        let ss = [];
			        fileList.forEach(item=>{
			            if (item.response == undefined){
			                ss.push(item)
			                let ent = {
			                    uid: item.fileId,
			                    name: item.fileName,
			                    status: 'done',
			                    fileId:item.fileId,
			                    fileName:item.fileName,
			                    fileExt:"."+item.fileExt,
			                }
			                _this.annexFileList.push(ent)
			            }
			            else{
			                ss.push(item.response.result)
			                let ent = {
			                    uid: item.response.result.fileId,
			                    name: item.response.result.fileName,
			                    status: 'done',
			                    fileId:item.response.result.fileId,
			                    fileName:item.response.result.fileName,
			                    fileExt:item.response.result.fileExt,
			                }
			
			                _this.annexFileList.push(ent)
			            }
			
			
			
			        })
			    }
			    if (file.status === "removed") {
			        _this.UploadServiceProxy.delete(undefined, undefined, "/FileSystem/InspectTask/" + (file.response == undefined?file.fileId + file.fileExt:file.response.result.fileId + file.response.result.fileExt), undefined, undefined, FileType.Show)
			            .then(() => {
			                this.picFileData = {
			                    uuidName: undefined,
			                    fileName: undefined,
			                    fileUrl: undefined,
			                    useType: undefined
			                }
			            })
			
			
			        let ss = [];
			        fileList.forEach(item=>{
			            if (item.response == undefined){
			                ss.push(item)
			                let ent = {
			                    uid: item.fileId,
			                    name: item.fileName,
			                    status: 'done',
			                    fileId:item.fileId,
			                    fileName:item.fileName,
			                    fileExt:"."+item.fileExt,
			                }
			                _this.annexFileList.push(ent)
			            }
			            else{
			                ss.push(item.response.result)
			                let ent = {
			                    uid: item.response.result.fileId,
			                    name: item.response.result.fileName,
			                    status: 'done',
			                    fileId:item.response.result.fileId,
			                    fileName:item.response.result.fileName,
			                    fileExt:item.response.result.fileExt,
			                }
			
			                _this.annexFileList.push(ent)
			            }
			        })
			    }
			},
			
			//删除
			removePic(file) {
			    return new Promise((resolve, reject) => {
			        this.$confirm({
			            title: this.l("ConfirmInformation"),
			            content: this.l("the_selected_option"),
			            cancelText: this.l('Cancel'),
			            okText: this.l('Ok'),
			            onOk() {
			                resolve(true)
			
			            },
			            onCancel() {
			                reject(false)
			            },
			        })
			
			    })
			
			},
			//预览
			handleCancel() {
			    this.previewVisible = false;
			},
			async handlePreview(file) {
			    if (!file.url && !file.preview) {
			        file.preview = await ImageUtils.getBase64(file.originFileObj);
			    }
			    this.previewImage = file.url || file.preview;
			    this.previewVisible = true;
			},
			handleChange({file, fileList}) {
				console.log(fileList)
			    if (file.status === 'done') {
			        if(this.discoveryPicture==''){
			            this.discoveryPicture=file.response.result.fileName+',';
			        }else {
			            this.discoveryPicture=this.discoveryPicture+file.response.result.fileName+',';
			        }
			    }
			
			    if (file.status === 'removed'){
			        if(file.response==undefined){
			            var str=file.name+',';
			            this.discoveryPicture=this.discoveryPicture.split(str).join('');
			        }else {
			            var str=file.response.result.fileName+',';
			            this.discoveryPicture=this.discoveryPicture.split(str).join('');
			        }
			    }
			    this.discoveryPicturelist = fileList;
			},
			
			checkData() {
				if(this.auditItem.valueActual) {
					if(this.auditItem.optionType == "2") {
						if(this.auditItem.valueStandard != null) {
							if(this.auditItem.valueStandard != this.auditItem.valueActual) {
								this.isQualified = false;
								this.auditItem.result = "N";
							}else {
								this.isQualified = true;
								this.auditItem.result = "Y";
							}
						}
					}else if(this.auditItem.optionType == "3") {
						if(Number(this.auditItem.valueActual) < Number(this.auditItem.valueLowerLimit) || Number(this.auditItem.valueActual) > Number(this.auditItem.valueUpperLimit)) {
							this.isQualified = false;
							this.auditItem.result = "N";
						}else {
							this.isQualified = true;
							this.auditItem.result = "Y";
						}
					}else if(this.auditItem.optionType == "4") {
						let Lower = Number(this.auditItem.valueStandard) - Number(this.auditItem.valueLowerLimit);
						let Upper = Number(this.auditItem.valueStandard) + Number(this.auditItem.valueUpperLimit);
						if(Number(this.auditItem.valueActual) < Lower || Number(this.auditItem.valueActual) > Upper) {
							this.isQualified = false;
							this.auditItem.result = "N";
						}else {
							this.isQualified = true;
							this.auditItem.result = "Y";
						}
					}else if(this.auditItem.optionType == "5") {
						if(Number(this.auditItem.valueStandard) < Number(this.auditItem.valueActual)) {
							this.isQualified = false;
							this.auditItem.result = "N";
						}else {
							this.isQualified = true;
							this.auditItem.result = "Y";
						}
					}else if(this.auditItem.optionType == "6") {
						if(Number(this.auditItem.valueStandard) > Number(this.auditItem.valueActual)) {
							this.isQualified = false;
							this.auditItem.result = "N";
						}else {
							this.isQualified = true;
							this.auditItem.result = "Y";
						}
					}
				}else {
					this.isQualified = true;
				}
			},
			
			//审核项保存
			save() {
				if(this.auditItem.result == "Y") {
					this.auditItem.deviationType = null;
					this.auditItem.deviationTypeName = null;
				}
				if(this.discoveryPicture.charAt(this.discoveryPicture.length-1) == ",") {
					this.auditItem.picture = this.discoveryPicture.slice(0, this.discoveryPicture.length - 1)
				}else {
					this.auditItem.picture = this.discoveryPicture;
				}
				this.APPApiServiceProxy.getCurrentTime().then(time=>{
					if(this.auditItem.result == "Y" || this.auditItem.result == "N") {
						this.auditItem.auditTime = time;
					}else {
						this.auditItem.auditTime = null;
					}
				    let auditTaskDetails = JSON.parse(localStorage.getItem('AuditItemList'));
				    for (let i = 0; i < auditTaskDetails.length; i++) {
				    	if(this.auditItem.id == auditTaskDetails[i].id) {
				    		auditTaskDetails[i] = this.auditItem;
				    	}
				    }
				    
				    localStorage.setItem("AuditItemList",JSON.stringify(auditTaskDetails));
				    
				    this.APPApiServiceProxy.saveAuditTaskDetail(
				        {auditTaskDetails}
				    ).then(res=>{
				        
				    })
				})
			},
			
			NewIssue() {
				ModalHelper.create(createissue, {
					auditItem: this.auditItem.itemName,
					pId: this.auditPoint.id,
					auditItemId: this.auditItem.auditItemId,
					auditTaskId: this.auditItem.auditTaskId
				}, {
					width: 1100
				}).subscribe(res => {
					
				})
			},
			
			IssueView() {
				ModalHelper.create(issueView, {
					auditItem: this.auditItem.itemName,
					pId: this.auditPoint.id,
					auditItemId: this.auditItem.auditItemId,
					auditTaskId: this.auditItem.auditTaskId
				}, {
					width: 1100
				}).subscribe(res => {
					
				})
			}
        }
    }
</script>

<style>
    .input {
        width: 200px;
    }
	
	.green {
		background-color: #00ff00;
	}
	
	.red {
		background-color: red;
	}
</style>
