import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const processappRouting: RouteConfig[] = [
    {
        path: 'processapp',
        meta: { title: '组织结构' },
        component: LayoutBlock,
        redirect: '/app/processapp/designs',
        children: [
            {
                name: 'designs',
                path: 'designs',
                component: () => import(/* webpackChunkName: "admin" */ './designs/designs.vue'),
                meta: { title: 'designs' },
            },
            {
                name: 'task',
                path: 'task',
                component: () => import(/* webpackChunkName: "admin" */ './task/task.vue'),
                meta: { title: 'task' },
            },
            {
                name: 'entrust',
                path: 'entrust',
                component: () => import(/* webpackChunkName: "admin" */ './entrust/entrust.vue'),
                meta: { title: 'entrust' },
            },
            {
                name: 'monitor',
                path: 'monitor',
                component: () => import(/* webpackChunkName: "admin" */ './monitor/monitor.vue'),
                meta: { title: 'monitor' },
            },
        ],
    },
];
export default processappRouting;
