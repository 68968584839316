<template>
    <div class="log-container">
        <div class="log-icon">
            <a-icon type="sound" style="font-size: 20px;"/>
        </div>
        <div class="log-data">
            <div class="log-time">
                <span v-if="logs.creationTime">{{logs.creationTime}}</span>
            </div>
            <div class="log-header">
                <div class="log-name">
                    {{logs.sendName}}
                </div>
            </div>
            <div class="log-content">
                {{logs.content}}
                <span v-if="logs.mesUrl"
                      title="查看详情"
                      @click="ViewDetails(logs.mesUrl)"
                ><a>查看详情</a></span>
            </div>
        </div>
    </div>
</template>

<script>
    import {abpService} from "@/shared";
    import {AppConsts} from "@/abpPro/AppConsts";

    export default {
        name: "message-log",
        props: {
            logs: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {}
        },
        created() {

        },
        mounted() {

        },
        methods: {
            ViewDetails(record) {
                //跳转外部地址(力软)
                if (record.toString().indexOf("http") != -1) {
                    let url = record;
                    if (url.toString().indexOf("?") != -1) {
                        url = url + "&&abpguid=" + abpService.abp.userInfo.ssoGuid + "&ModuleId=" + AppConsts.DefaultMsgLinkModuleId;
                    } else {
                        url = url + "?abpguid=" + abpService.abp.userInfo.ssoGuid + "&ModuleId=" + AppConsts.DefaultMsgLinkModuleId;
                    }
                    window.open(url);
                } else {
                    //跳本地路由
                    let path = record.toString().split("?")[0];
                    if (path) {
                        this.$router.push({path: path});
                    }
                }
            }
        }
    }
</script>

<style scoped>
    @import "message-log.less";
</style>
