import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const AppManageRouting: RouteConfig[] = [
    {
        path: 'appmanage',
        meta: { title: '移动管理' },
        component: LayoutBlock,
        redirect: '/app/appmanage/manage',
        children: [
            {
                name: 'manage',
                path: 'manage',
                component: () => import(/* webpackChunkName: "admin" */ './manage/manage.vue'),
                meta: { title: 'APP轮播图' },
            },
        ],
    },
];
export default AppManageRouting;
