import Vue from 'vue';
import Vuex from 'vuex';

import { layoutStore, abpStore } from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        layoutStore: layoutStore,
        abpStore: abpStore,
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {},
});
