<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row>
                    <a-col :span="14" style="text-align: left">
                        <a-month-picker v-model="SelectMonthTime" @change="onChange3" :allowClear="false"
                            style="width: 120px;margin-right: 10px;" />
                        <a-tree-select v-model="orgId" style="width: 150px;margin-right: 10px;" :tree-data="treeDataList"
                            :replace-fields="treeReplaceFields"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto', width: '300px' }"
                            :placeholder="l('please_select_organization_structure')" :dropdownMatchSelectWidth="false"
                            allow-clear tree-default-expand-all @change="orgChange">
                        </a-tree-select>

                        <a-select showSearch style="width: 120px;text-align: left;margin-right: 10px;"
                            dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                            :placeholder="l('please_select_status')" @change="AuditTaskStateChange" allow-clear
                            mode="multiple">
                            <a-select-option v-for="item in AuditTaskStateList" :key="item.itemDetailCode"
                                :title="item.itemDetailName">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>
                        <a-input-search :placeholder="l('PleaseEnterAKeyword')" v-model.trim="filterText" enterButton
                            style="width: 160px;" @search="filteronchange">
                        </a-input-search>

                        <span style="margin: 0px 5px;">显示审核模版</span>
                        <a-switch default-checked v-model="IsShowAuditTemplate" @change="filteronchange" />
						<a-button @click="Export">
							<a-icon type="export" />
							{{ l('Export') }}
						</a-button>
                    </a-col>


                    <a-col :span="10" style="text-align: left;margin-top: 3px;">
                        <div style="float: right;">
                            <img src="../../../../../public/img/image/qj.png"
                                style="height: 23px;" /><span>{{ l('LeaveRequested') }}</span>
                        </div>
                        <span style="width:90px;float: right;height: 20px;"><span
                                style="display: block; border-radius:50%; height: 20px; width: 20px;background-color: red;float: left;"></span>{{ l('Undone') }}</span>
                        <span style="width:90px;float: right;height: 20px;"><span
                                style="display: block; border-radius:0; height: 20px; width: 20px;background-color: #6ec5d2;float: left;"></span>{{ l('Have_Plans') }}</span>
                        <span style="width:90px;float: right;height: 20px;"><span
                                style="display: block; border-radius:50%; height: 20px; width: 20px;background-color: #6ec5d2;float: left;"></span>{{ l('Executing') }}</span>
                        <span style="width:90px;float: right;height: 20px;"><span
                                style="display: block; border-radius:50%;border: 3px green  solid; height: 20px; width: 20px;background-color: white;float: left;"></span>{{ l('Completed') }}</span>
                    </a-col>

                    <a-col :span="24" style="margin-top: 20px">
                        <a-spin :spinning="isLoading" style="position: relative;">
                            <div
                                style="width:100%;height: 1px;border-top:1px solid #696969;position: absolute;z-index: 2;background-color: white;">

                            </div>
                            <div style="width:100%;overflow-y: auto;height: 100%;table-layout: fixed;z-index: 1;"
                                :style="styObj">
                                <table style="text-align: center;width: 100%;" id="tables" border="1">
                                    <thead>
                                        <th style="border:1px solid #696969;height: 50px;text-align: center;vertical-align:middle;width: 90px;height: 50px;position: sticky;top: 0;;background: #ffffff;table-layout: fixed;"
                                            class="body2">{{ l('audit.inspectorname') }}</th>
                                        <th v-if="IsShowAuditTemplate"
                                            style="border:1px solid #696969;height: 50px;text-align: center;vertical-align:middle;width: 140px;height: 50px;position: sticky;top: 0;background: #ffffff;"
                                            class="body1">{{ l('AuditTemplate') }}</th>
                                        <th v-for="item in month" :title="item"
                                            style="border:1px solid #696969;vertical-align:middle;position: sticky;top: 0;background: #ffffff;"
                                            class="body">{{ item }}</th>
                                    </thead>
									<tr style="display: none;">
										<th style="border:1px solid #696969;height: 50px;text-align: center;vertical-align:middle;width: 90px;height: 50px;position: sticky;top: 0;;background: #ffffff;table-layout: fixed;"
										    class="body2">{{ l('audit.inspectorname') }}</th>
										<th v-if="IsShowAuditTemplate"
										    style="border:1px solid #696969;height: 50px;text-align: center;vertical-align:middle;width: 140px;height: 50px;position: sticky;top: 0;background: #ffffff;"
										    class="body1">{{ l('AuditTemplate') }}</th>
										<th v-for="item in month" :title="item"
										    style="border:1px solid #696969;vertical-align:middle;position: sticky;top: 0;background: #ffffff;"
										    class="body">{{ item }}</th>
									</tr>
                                    <tr v-for="item in templateList">
                                        <td style="border:1px solid #696969;width: 160px;height: 50px;" class="body2">
                                            {{ item.inspectorName }}</td>
                                        <td v-if="IsShowAuditTemplate" style="border:1px solid #696969;width: 160px;height: 50px" class="body1">
                                            {{ item.templateName }}</td>
                                        <td v-for="itemchird in item.auditTaskList" align="center"
                                            style="border:1px solid #696969;width: 60px;" class="body">
                                            <div v-for="itemchird_chird in itemchird.tasks">
                                                <div style="display: block; border-radius:50%; height: 23px; width: 23px;line-height: 22px;font-weight: bold;cursor: pointer;color: #ffffff;margin: 2px;"
                                                    :style="{ backgroundColor: StatusColor(itemchird_chird.status), borderRadius: borderRadius(itemchird_chird.status), border: Border(itemchird_chird.status) }"
                                                    @click="monthly(itemchird_chird.id)"
                                                    v-if="itemchird_chird.status === '1' || itemchird_chird.status === '2' || itemchird_chird.status === '4'">
                                                    <div v-if="itemchird_chird.issueCount">
                                                        {{ itemchird_chird.issueCount }}
                                                    </div>
													<div style="display: none;" v-if="itemchird_chird.status === '1'">
														■
													</div>
													<div style="display: none;" v-if="itemchird_chird.status === '2'">
														●
													</div>
													<div style="display: none;" v-if="itemchird_chird.status === '4'">
														×
													</div>
                                                </div>

                                                <div style="display: block; border-radius:50%; height: 23px; width: 23px;line-height: 17px;font-weight: bold;cursor: pointer;color: #000000;margin: 2px;"
                                                    :style="{ backgroundColor: StatusColor(itemchird_chird.status), borderRadius: borderRadius(itemchird_chird.status), border: Border(itemchird_chird.status) }"
                                                    @click="monthly(itemchird_chird.id)"
                                                    v-if="itemchird_chird.status === '3' || itemchird_chird.status === '6'">
                                                    <div v-if="itemchird_chird.issueCount">
                                                        {{ itemchird_chird.issueCount }}
                                                    </div>
													<div style="display: none;">
														○
													</div>
                                                </div>

                                                <div style="display: block;" @click="monthly(itemchird_chird.id)"
                                                    v-if="itemchird_chird.status === '5'">
                                                    <img src="../../../../../public/img/image/qj.png" />
                                                    <!-- <div v-if="itemchird_chird.issueCount">
												        {{itemchird_chird.issueCount}}
												    </div> -->
													<div style="display: none;">
														▲
													</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </a-spin>
                    </a-col>
                </a-row>

            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
import {
    AppComponentBase
} from "@/shared/component-base";
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import {
    AuditTaskServiceProxy,
    LayoutOrgServiceProxy
} from '../../../../shared/service-proxies';
// import CreatErrorCode from "../organization-structure/new-template/new-template";
import moment from "moment";
import {
    ModalHelper
} from '@/shared/helpers';
import monthly from "../../audit-task-view/audit-task-view.vue";
import { Dic, fileDownloadService } from "../../../../shared/utils";
import XLSX from 'xlsx';
import XLSXS from 'xlsx-style';
import FileSaver from "file-saver";

let _this;

export default {
    name: "month-view-emp",
    mixins: [ModalComponentBase],
    components: {
        // CreatErrorCode,
        monthly,
    },
    created() {
        _this = this;
        this._AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
        this._LayoutOrgServiceProxy = new LayoutOrgServiceProxy(this.$apiUrl, this.$api);
    },
    async mounted() {
        this.fullData(); // 模态框必须,填充数据到data字段
        this.orgId = this.id;
        this.AuditTaskStateList = await Dic.getInstance().getDicAsync('AuditTaskState');
        this.NowMonth();
        this.getTreeData();
        window.addEventListener('resize', this.changeHeight);
        this.changeHeight();
    },
    data() {
        return {
            zh_CN,
            isEdit: false,
            isLook: false,
            isLoading: false,
            Modevalue: 1,
            hourOrDay: 1,
            selectDayTime: undefined,
            aData: undefined,
            date: undefined,
            timeRangeStartTime: undefined,
            timeRangeEndTime: undefined,
            SelectMonthTime: undefined,
            data: {
                hourOrDay: undefined,
                selectDayTime: undefined,
                SelectMonthTime: undefined,
                timeRangeStartTime: undefined,
                timeRangeEndTime: undefined,
            },
            hour: [],
            month: [],
            table: undefined,
            startTime: undefined,
            endTime: undefined,
            i: undefined,
            value: true,
            tableData: undefined,
            mmm: undefined,
            mmmm: 24,
            p: undefined,
            q: undefined,
            tabled: undefined,
            DATA: [],
            Table: undefined,
            Hour: [],
            styObj: {
                height: 0
            },
            HOUR: [],
            MONTH: {},
            MONTHDATA: undefined,

            templateList: [],

            treeDataList: [],
            treeReplaceFields: {
                title: 'levelNameAndName',
                key: 'id',
                value: 'id'
            },
            treeReplace: {
                title: 'name',
                key: 'id',
                value: 'id'
            },
            orgId: undefined,

            AuditTaskStateList: [],
            AuditTaskState: [],

            filterText: undefined,

            //LPA看板传参
            id: undefined,
            kanbanDate: undefined,
            IsShowAuditTemplate: true
        }
    },
    methods: {
        moment,

        orgChange(key) {
            console.log(key);
            this.orgId = key;
            this.getData();
        },
        //搜索框点击事件
        filteronchange() {
            this.getData();
        },
        AuditTaskStateChange(value) {
            console.log(value);
            this.AuditTaskState = value;
            this.getData();
        },

        getTreeData() {
            this.isLoading = true;
            this._LayoutOrgServiceProxy.getAllData(
            )
                .finally(() => {
                    this.isLoading = false;
                })
                .then((res) => {
                    this.treeDataList = JSON.parse(JSON.stringify(res));
                })
        },
        //div高度
        changeHeight() {
            this.styObj.height = window.innerHeight - 199 + 'px';
        },
        create() {
            // this.getData();
        },
        NowMonth() {
            if (this.kanbanDate) {
                this.SelectMonthTime = this.kanbanDate;
            } else {
                this.SelectMonthTime = moment(new Date()).format('YYYY-MM');
            }

            let mm = this.SelectMonthTime.split('-'); //把2020-11变成需要传输的样子：2020和11
            this.getDaysInMonth(mm[0], mm[1]);
        },
        getData() {

            var tb = document.getElementById('tables');
            var rowNum = tb.rows.length;
            for (let i = 0; i < rowNum; i++) {
                tb.deleteRow(i);
                rowNum = rowNum - 1;
                i = i - 1;
            }

            this.data.hourOrDay = this.hourOrDay;
            this.data.selectDayTime = this.selectDayTime;
            this.data.SelectMonthTime = this.SelectMonthTime;
            this.data.timeRangeStartTime = this.timeRangeStartTime;
            this.data.timeRangeEndTime = this.timeRangeEndTime;
            this.isLoading = true;
            this._AuditTaskServiceProxy.month_View({
                monthAndYear: this.SelectMonthTime,
                orgId: this.orgId,
                auditTaskStatus: this.AuditTaskState,
                isShowAuditTemplate: this.IsShowAuditTemplate,
                filterText: this.filterText
            }).finally(() => {
                this.isLoading = false;
            }).then(res => {



                // console.log(res);
                if (res.length <= 0) {
                    this.templateList = [];
                    return;
                }
                if (this.IsShowAuditTemplate) {
                    res.forEach(item => {
                        item.templates.forEach(itemchird => {
                            if (itemchird.auditTaskList != undefined) {
                                let ss = {
                                    inspectorName: item.inspectorName,
                                    auditTaskList: itemchird.auditTaskList,
                                    templateName: itemchird.templateName,
                                }
                                _this.templateList.push(ss)
                            }

                        })
                    })
                } else {
                    res.forEach(item => {
                        if (item.auditTaskList != undefined) {
                            let ss = {
                                inspectorName: item.inspectorName,
                                auditTaskList: item.auditTaskList
                            }
                            _this.templateList.push(ss)
                        }

                    })
                }
            });
            // for(var i=0;i<a.length;i++){
            //   for(var j=0;j<b.length;j++){
            //     if(b[j]>0){
            //       a[j]=a[j]+b[j];
            //     }
            //   }
            // }
        },
        monthly(items) {
            // console.log(items)
            ModalHelper.create(monthly, {
                id: items
            }, {
                width: 1000
            }).subscribe(res => {
                // console.log(res)
            })
        },
        ModeChange(e) {
            if (e.target.value == 1) {
                this.value = true;
            } else if (e.target.value == 2) {
                this.value = false;
            }
            this.hourOrDay = e.target.value;
            // console.log(this.hourOrDay);
            this.getData();
        },
        onChange(date, time) {
            this.selectDayTime = time;
            this.getData();
        },
        onChange1(date, time) {
            this.startTime = time;
            this.hourOnChange();
        },
        onChange2(date, time) {
            this.endTime = time;
            this.hourOnChange();
        },
        onChange3(date, time) {
            this.SelectMonthTime = time;
            let mm = this.SelectMonthTime.split('-'); //把2020-11变成需要传输的样子：2020和11
            this.getDaysInMonth(mm[0], mm[1]);
        },

        StatusColor(status) {
            if (status == "4") {
                return "red";
            }
            if (status == "1") {
                return "#6ec5d2";
            }
            if (status == "3" || status == "6") {
                return "white";
            }
            if (status == "2") {
                return "#6ec5d2";
            }
        },
        borderRadius(status) {
            if (status == "4") {
                return "50%";
            }
            if (status == "2") {
                return "50%";
            }
            if (status === "3" || status == "6") {
                return "50%";
            }

            if (status === "1") {
                return "0";
            }
        },
        Border(status) {
            if (status === "3" || status == "6") {
                return "3px green solid";
            }
        },
        Color(status) {
            if (status === "3" || status == "6") {
                return "#000000";
            } else {
                return "#ffffff";
            }
        },

        hourOnChange() {
            if (this.startTime != undefined && this.endTime != undefined) {
                this.timeRangeStartTime = this.startTime + ":00:00";
                this.timeRangeEndTime = this.endTime + ":00:00";
                let s = parseInt(this.startTime);
                let e = parseInt(this.endTime);
                this.mmmm = e - s;
                this.hour = [];
                for (let g = s; g < e; g++) {
                    this.i = g;
                    this.hour.push(this.i);
                }
                // console.log(this.hour)
                if (this.timeRangeStartTime == ":00:00" || this.timeRangeEndTime == ":00:00") {
                    this.timeRangeStartTime = undefined;
                    this.timeRangeEndTime = undefined;
                    let i = 24;
                    this.mmmm = i;
                    this.hour = [];
                    for (let a = 0; a < i; a++) {
                        this.hour.push(a);
                    }
                }
                this.getData();
            } else {

            }
        },
        getDaysInMonth(year, month) {
            this.month = [];
            let temp = new Date(year, month, 0);
            let mm = temp.getDate(); //这里mm就是天数啦
            this.mmm = mm;
            for (let m = 1; m < mm + 1; m++) {
                this.month.push(m);
            }
            console.log(this.month)
            this.getData();
        },
		
		Export() {
			var elt = document.getElementById('tables');
			// 创建Excel工作簿，即一个Excel文件
			var wb = XLSX.utils.table_to_book(elt, {
			    sheet: "月视图" // 工作表格名称
			});
			this.setExlStyle(wb["Sheets"]["月视图"]);
			// this.addRangeBorder(
			//     wb["Sheets"]["月视图"]["!merges"],
			//     wb["Sheets"]["月视图"]
			// );
			var ws = XLSXS.write(wb, {
			    type: "buffer",
			});
			try {
				FileSaver.saveAs(
					new Blob([ws], { type: "application/octet-stream"}),
					'SheetJSTableExport.xlsx'
				);
			} catch (e) {
				if (typeof console !== "undefined") console.log(e, ws);
			}
			// 导出工作表
			// return XLSX.writeFile(wb, 'SheetJSTableExport.xlsx');
			return ws
		},
		
		setExlStyle(data) {
		    let borderAll = {
		        //单元格外侧框线
		        top: {
					style: "thin",
		        },
		        bottom: {
					style: "thin",
		        },
		        left: {
					style: "thin",
		        },
		        right: {
					style: "thin",
		        },
		    };
		    data["!cols"] = [];
		    for (let key in data) {
				if (data[key] instanceof Object) {
					data[key].s = {
						border: borderAll,
						alignment: {
							horizontal: "center", //水平居中对齐
							vertical: "center",
						},
						font: {
							sz: 11,
						},
						bold: true,
						numFmt: 0,
					};
					data["!cols"].push({ wpx: 115 });
				}
		    }
		    return data;
		},
		
		addRangeBorder(range, ws) {
			console.log(range)
		    // s:起始位置，e:结束位置
		    let arr =["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
		    range.forEach((item) => {
				let startRowNumber = Number(item.s.r), startColumnNumber = Number(item.s.c), endColumnNumber = Number(item.e.c), endRowNumber = Number(item.e.r);
		        //合并单元格时会丢失边框样式，例如A1->A4 此时内容在A1 而range内获取到的是从0开始的，所以开始行数要+2
		        for (let i = startColumnNumber; i <= endColumnNumber; i++) {
		            for(let j = startRowNumber+2 ;j<= endRowNumber+1 ; j++) {
		                console.log(arr[i] + j)
		                ws[arr[i] + j] = {
		                    s: {
								border: {
									top: { style: "thin" },
									left: { style: "thin" },
									bottom: { style: "thin" },
		                            right: { style: "thin" },
		                        },
		                    },
		                };
		            }
		
		        }
		    });
		    console.log(ws);
		    return ws;
		}
    }
}
</script>

<style scoped>
.body {
    min-width: 30px;
    min-height: 50px;
}

.body1 {
    min-width: 100px;
    min-height: 50px;
}

.body2 {
    min-width: 70px;
    min-height: 50px;
}

th:nth-child(1),
td:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 1;
    background: #ffffff;
}

th:nth-child(2),
td:nth-child(2) {
    position: sticky;
    left: 70px;
    z-index: 1;
    background: #ffffff;
}</style>