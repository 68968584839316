import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const KanBanRouting: RouteConfig[] = [
    {
        path: 'kanban',
        meta: { title: '数据维护' },
        component: LayoutBlock,
        redirect: '/app/kanban/Data-maintenance',
        children: [
            {
                name: 'Data-maintenance',
                path: 'Data-maintenance',
                component:()=>import('./Data-maintenance/Data-maintenance.vue'),
                meta: { title: '数据维护' },
            },
			{
			    name: 'FS-Data-maintenance',
			    path: 'FS-Data-maintenance',
			    component:()=>import('./FS-Data-maintenance/FS-Data-maintenance.vue'),
			    meta: { title: '数据维护' },
			}
        ],
    },
];
export default KanBanRouting;
