import $ from 'jquery';

class Image_Utils {
	constructor() {}
	getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}
	/**
	 * 预览图片
	 * @param url 图片地址，支持base64
	 */
	showFullImage(url) {
		let clientWidth = window.document.body.clientWidth;
		let clientHeight = window.document.body.clientHeight;
		//拼接蒙层
		let img = `<div id='mask' title="点击任意区域即可关闭预览" style='width: ${clientWidth}px; height: ${clientHeight}px; position: fixed; top: 0; left: 0;z-index: 9999;'>
				      <div style='width: 100%; height: 100%; display: flex; align-items: center; padding: 10px; justify-content: center;background: #000000;'>
				        <img id='maskimg' src='${url}'/>
				      </div>
				    </div>`;
		//添加到body节点上
		$(document.body)
			.append(img)
			.show();
		//点击蒙层移除
		$('#mask').on('click', function() {
			$(this).remove();
		});
		//图片原始宽高
		let originalHeight = $('#maskimg').innerHeight();
		let originalWidth = $('#maskimg').innerWidth();
		if (originalHeight > clientHeight) {
			$('#maskimg').css('height', clientHeight + 'px');
		}
		//Esc退出监听
		$(document).keydown(function(event) {
			if (event.keyCode == 27) {
				$('#mask').remove();
			}
		});
		//鼠标滚轮
		$('#maskimg').on('mousewheel DOMMouseScroll', function(event) {
			let delta =
				(event.originalEvent['wheelDelta'] && (event.originalEvent['wheelDelta'] > 0 ? 1 : -1)) || // chrome & ie
				(event.originalEvent['detail'] && (event.originalEvent['detail'] > 0 ? -1 : 1)); // firefox
			let img = $('#maskimg');
			//当前宽高
			let currentWidth = img.innerWidth();
			let currentHeight = img.innerHeight();

			if (delta > 0) {
				//原始高度大于窗口高度   可以放大宽度
				if (originalHeight > clientHeight) {
					//当前宽==浏览器宽
					if (currentWidth >= clientWidth - 100) {
						return;
					}
					img.css({
						width: currentWidth + 100 + 'px',
						height: currentHeight + 100 + 'px',
					});
				} else {
					//当前宽或者高==浏览器宽高
					if (currentWidth >= clientWidth - 100 || currentHeight >= clientHeight - 100) {
						return;
					}
					img.css({
						width: currentWidth + 100 + 'px',
						height: currentHeight + 100 + 'px',
					});
				}
			} else {
				//原始高度大于窗口高度   可以放大宽度
				if (originalHeight > clientHeight) {
					//当前宽<原始宽的一半
					if (currentWidth < originalWidth / 2) {
						return;
					}
					img.css({
						width: currentWidth - 100 <= 0 ? 100 : currentWidth - 100 + 'px',
						height: currentHeight - 100 <= 0 ? 100 : currentHeight - 100 + 'px',
					});
				} else {
					//当前宽高==原始宽高
					if (currentHeight <= originalHeight || currentWidth <= originalWidth) {
						return;
					}
					img.css({
						width: currentWidth - 100 <= 0 ? 100 : currentWidth - 100 + 'px',
						height: currentHeight - 100 <= 0 ? 100 : currentHeight - 100 + 'px',
					});
				}
			}
		});
	}
	/**
	 * 检查一个图片是否可用
	 * @param url 图片地址
	 * @returns promise
	 */
	checkImage(url: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = url;
			img.onerror = reject;
			img.onload = resolve;
		});
	}
}

const ImageUtils = new Image_Utils();
export default ImageUtils;
