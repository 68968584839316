<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="form" />
					<span>{{ l(' 提交反馈') }}</span>
				</div>
			</div>
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('反馈分类')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-select
						show-search
						:placeholder="l('反馈分类')"
						option-filter-prop="children"
						style="width: 100%"
						v-model="entity.feedbackType"
					>
						<a-select-option v-for="item in feedbackList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('反馈图片')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-upload
						name="profilePictureFile"
						:action="uploadPictureUrl"
						listType="picture-card"
						:fileList="fileList"
						:beforeUpload="beforeUpload"
						accept="image/*"
						:headers="uploadHeaders"
						@preview="handlePreview"
						@change="uploadPictureChange($event)"
					>
						<div v-if="fileList.length < 5">
							<a-icon type="plus" />
							<!-- {{ l('UploadProfilePicture') }} -->
							<div class="ant-upload-text">上传图片</div>
						</div>
					</a-upload>
					<a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
						<img alt="example" style="width: 100%" :src="previewImage" />
					</a-modal>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('反馈内容')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-textarea :rows="5" v-model="entity.feedbackContent" placeholder="请输入不少于5个字的反馈内容" />
				</a-col>
			</a-row>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button :disabled="saving" @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :loading="saving" :type="'primary'" @click="save()">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Dic } from '../../../../shared/utils';
import { AppConsts } from '@/abpPro/AppConsts';
import ImageUtils from '@/shared/utils/image-utils';
import { FeedbackEditDto, FeedbackServiceProxy } from '@/shared';

export default {
	name: 'create-or-edit-feedback',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			feedbackList: [
				{
					itemDetailCode: 'problem',
					itemDetailName: '遇到问题',
				},
				{
					itemDetailCode: 'suggest',
					itemDetailName: '使用建议',
				},
			],
			// 上传的接口地址
			uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadFilePicture',
			maxAppPictureBytesValue: AppConsts.maxProfilPictureMb,
			fileList: [],
			entity: new FeedbackEditDto(),
			feedbackPicture: '',
			uploadHeaders: {
				Authorization: 'Bearer ' + abp.auth.getToken(),
				type: 'feedback',
			},
			//是否显示图片预览框
			previewVisible: false,
			//预览图片地址
			previewImage: '',
		};
	},
	created() {
		this.fullData();
		this._feedbackServiceProxy = new FeedbackServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		// this.feedbackList = await Dic.getInstance().getDicAsync("QYB_FeedbackType");
	},
	methods: {
		/**
		 * 上传图片
		 */
		beforeUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
			if (!isJPG) {
				abp.message.error(this.l('OnlySupportPictureFile'));
			}
			const isLtXM = file.size / 1024 / 1024 < this.maxAppPictureBytesValue;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', this.maxAppPictureBytesValue));
			}
			return isJPG && isLtXM;
		},
		/**
		 * 预览图片
		 * @param file 文件
		 * @returns {Promise<void>}
		 */
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		/**
		 * 上传图片回调  状态 "status: "done"  status: "uploading"  status: "removed"
		 */
		uploadPictureChange({ file, fileList }) {
			if (file.status === 'done') {
				this.feedbackPicture += file.response.result.fileName + ',';
			} else if (file.status === 'removed') {
				let s = this.feedbackPicture
					? this.feedbackPicture.split(',').filter((item) => item != file.response.result.fileName)
					: [];
				this.feedbackPicture = s.join(',');
			}
			this.fileList = fileList;
		},
		save() {
			if (!this.entity.feedbackType) {
				return abp.message.warn('请选择反馈分类');
			}
			if (!this.entity.feedbackContent) {
				return abp.message.warn('请输入反馈内容');
			}
			if (this.entity.feedbackContent.length < 5) {
				return abp.message.warn('反馈内容不能少于5个字符');
			}
			this.entity.feedbackPicture = this.feedbackPicture;
			this.entity.userId = abp.session.userId;
			this.entity.identification = 2;
			this.loading = true;
			this._feedbackServiceProxy
				.add(this.entity)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('提交成功'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
