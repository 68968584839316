<template>
    <a-spin :spinning="isLoading">
        <div>
            <div class="modal-header">
                <div class="modal-title">
                    <a-icon type="medicine-box" style="margin-right:10px" />
                    <span>{{l('Create_the_issue')}}</span>
                </div>
            </div>

            <div>
                <a-config-provider :locale="zh_CN">
                    <a-row>
                        <a-col style="margin-bottom: -30px;" v-if="auditItem">
                            <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21, style:'text-align: left'}"
                                :label="l('AppAuditItem')" class="body">
                                <P>{{auditItem}}</P>
                            </a-form-item>
                        </a-col>
                        <a-row style="margin-bottom: 20px;">
                            <a-col :span="3">
                                <a-form-item required :label="l('issue.description')" class="body">
                                </a-form-item>
                            </a-col>
                            <a-col :span="21">
                                <a-textarea v-model="description" style="height: 60px;width:100%;"></a-textarea>
                            </a-col>
                        </a-row>


                        <a-row>
                            <a-col :span="3">
                                <a-form-item :label="l('issuetype')" class="body">
                                </a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-tree-select v-model="issueTypeIdList" style="width:200px" :tree-data="issueTypelist"
                                    :replace-fields="issueType" multiple
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                                    :dropdownMatchSelectWidth=false :placeholder="l('Issue_tip_select')" allow-clear
                                    tree-default-expand-all @change="issueTypeChange">
                                </a-tree-select>
                            </a-col>

                            <a-col :span="4" style="margin-left: 10px">
                                <a-form-item :label="l('220')" class="body">
                                </a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-tree-select v-model="pId" style="width: 200px" :tree-data="auditPointIdlist"
                                    :replace-fields="auditPoint"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                                    :dropdownMatchSelectWidth=false :placeholder="l('select_auditpoint')" allow-clear
                                    tree-default-expand-all @change="change">
                                </a-tree-select>
                            </a-col>

                            <a-col :span="4">
                                <a-form-item required :label="l('auditpoint.respid')" class="body" v-if="IsRequiredIssueResp">
                                </a-form-item>
								<a-form-item :label="l('auditpoint.respid')" class="body" v-else>
								</a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-input v-model="respName" :placeholder="l('Please_select_the_person_responsible')"
                                    allow-clear style="width: 200px;" @click="respNameSelect"></a-input>
                                <!-- <a-select showSearch
                                          style="width: 200px;text-align: left;"
                                          dropdownClassName="drop-down-Style"
                                          :dropdownMatchSelectWidth="false"
                                          v-model="respId"
                                          optionFilterProp="label"
										  :placeholder="l('Please_select_the_person_responsible')"
										  @change = "Responsible"
                                >
                                    <a-select-option v-for="item in resplist" :key="item.id" :label="item.realName">
                                        {{ item.realName }}
                                    </a-select-option>
                                </a-select> -->
                            </a-col>
                        </a-row>

                        <a-row>
                            <a-col :span="3">
                                <a-form-item :label="l('audit.planstart')" class="body">
                                </a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-date-picker style="width: 200px;text-align: left;" v-model="planStart"
                                    :placeholder="l('select_date')" />
                            </a-col>

                            <a-col :span="4" style="margin-left: 10px">
                                <a-form-item :label="l('PlanCompleteTime')" class="body">
                                </a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-date-picker style="width: 200px;text-align: left;" v-model="planEnd"
                                    :placeholder="l('select_date')" />
                            </a-col>


                        </a-row>


                    </a-row>

                </a-config-provider>
            </div>

            <div>
                <a-tabs type="card">
                    <a-tab-pane key="2" :tab="l('issue.discoverypicture')">
                        <a-upload style="width: 900px;word-wrap:break-word" :action="uploadPictureUrl"
                            list-type="picture-card" :fileList="discoveryPicturelist" accept="image/*"
                            :headers="picUploadHeaders" :beforeUpload="beforeUpload" :remove="removePic" :disabled="IsEnableUploadPicture"
                            :multiple="false" @preview="handlePreview" @change="handleChange">
                            <div>
                                <a-icon type="plus" />
                                <div class="ant-upload-text">
                                    {{l('Upload_an_image')}}
                                </div>
                            </div>
                        </a-upload>
                        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                            <img alt="example" style="width: 100%" :src="previewImage" />
                        </a-modal>
                    </a-tab-pane>
                    <a-tab-pane key="4" :tab="l('issue.improvenote')">
                        <a-textarea v-model="improveNote" style="height: 80px;width:100%;"></a-textarea>
                    </a-tab-pane>
                    <a-tab-pane key="6" :tab="l('Remark')">
                        <a-textarea v-model="remark" style="height: 80px;width:100%;"></a-textarea>
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div class="modal-footer">
                <a-button @click="close">
                    {{l('Cancel')}}
                </a-button>
                <a-button :type="'primary'" @click="save">
                    {{l('Save')}}
                </a-button>
            </div>
        </div>
    </a-spin>
</template>

<script>
    let _this;
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {
        AuditPointServiceProxy,
        EmployeeServiceProxy,
        IssueTypeServiceProxy,
        IssueProgressServiceProxy, IssueServiceProxy, IssueEditDto,
        APPApiServiceProxy,
        SystemConfigServiceProxy
    } from '../../../../shared/service-proxies';
    import { ModalHelper } from '@/shared/helpers';
    import { AppConsts } from "../../../../abpPro/AppConsts";
    import ImageUtils from "../../../../shared/utils/image-utils";
    import PersonnelSelection from "../../Personnel-selection/Personnel-selection.vue";
    import moment from "moment";
    export default {
        name: "create-issue",
        mixins: [ModalComponentBase],
        components: {
            PersonnelSelection
        },

        created() {
            _this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
            this.IssueTypeServiceProxy = new IssueTypeServiceProxy(this.$apiUrl, this.$api);
            this._EmployeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
            this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
            this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
            this.APPApiServiceProxy = new APPApiServiceProxy(this.$apiUrl, this.$api);
            this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
        },


        mounted() {
			this.getIsRequiredIssueResp();
			this.getIsEnableUploadPicture();
            this.getPictureQuality();
            this.getuser();
            this.getissueTreeData();
            this.getissuerprogess();
            this.getAuditPointTreeData();
            if (this.id != null) {
                this.getEditData();
            }
        },

        data() {
            return {
                zh_CN,
                isKanban: false,
                isLoading: false,
                id: undefined,
                pId: undefined,
                previewVisible: false,
                previewVisible2: false,
                //发现人
                discovererId: undefined,
                discovererlist: [],
                //责任人
                respId: undefined,
                respName: undefined,
                resplist: [],
                //问题分类
                issueTypeIdList: [],
                issueTypelist: [],
                issueType: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                //审核点
                auditPointId: undefined,
                auditPointIdlist: [],
                auditPoint: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                //审核进度
                issueProgress: undefined,
                issueProgresslist: [],
                //提交时间
                discoveryTime: undefined,
                //完成时间
                closeTime: undefined,
                //计划开始时间
                planStart: undefined,
                //计划结束时间
                planEnd: undefined,
                description: undefined,
                // 上传的接口地址
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
                //图片的请求头信息
                picUploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken(),
                    type: "photo"
                },
                previewImage: '',
                previewImage2: '',
                //发现时图片
                discoveryPicture: '',
                discoveryPicturelist: [],
                improvePicture: '',
                improvePicturelist: [],

                //改善建议
                improveNote: undefined,
                //改善措施
                correctiveNote: undefined,
                remark: undefined,
                entity: new IssueEditDto(),
                imgPath: `${AppConsts.remoteServiceBaseUrl}/File/LPA/${abp.session.tenantId}/Issue/`,

                iscreate: undefined,
                isclick: true,
                isResId: false,

                auditItem: undefined,
                auditItemId: undefined,
                auditTaskId: undefined,
                issueTypeName: undefined,

                PictureQuality:undefined,
				IsEnableUploadPicture: true,
				IsRequiredIssueResp: true
            }
        },

        methods: {
            save() {
				if (!this.description) {
				    abp.message.info(this.l('PleaseEnterIssueDesc'));
				    return;
				}
				if (this.IsRequiredIssueResp && !this.respName) {
				    abp.message.info(this.l('PleaseSelectResponseible'));
				    return;
				}
                // if (!this.planStart) {
				//     abp.message.info(this.l('请选择计划开始日期'));
				//     return;
				// }
                // if (!this.planEnd) {
				//     abp.message.info(this.l('请选择计划完成日期'));
				//     return;
				// }
				
                this.entity.id = this.id;
                this.entity.issueTypeIdList = this.issueTypeIdList;
                this.entity.issueProgress = this.issueProgress;
                this.entity.description = this.description;
                this.entity.respId = this.respId;
                this.entity.respName = this.respName;
                this.entity.auditPointId = this.pId;
                this.entity.planStart = this.planStart;
                this.entity.planEnd = this.planEnd;
                this.entity.closeTime = this.closeTime;
                this.entity.discoveryPicture = this.discoveryPicture;
                this.entity.improvePicture = this.improvePicture;
				if (this.discoveryPicture[this.discoveryPicture.length - 1] == ",") {
				    this.entity.discoveryPicture = this.discoveryPicture.substr(0, this.discoveryPicture.length - 1);
				}
				if (this.improvePicture[this.improvePicture.length - 1] == ",") {
				    this.entity.improvePicture = this.improvePicture.substr(0, this.improvePicture.length - 1);
				}
                this.entity.improveNote = this.improveNote;
                this.entity.correctiveNote = this.correctiveNote;
                this.entity.remark = this.remark;
                // var resultlist={
                //     "IssueEditDto":
                // };
                if (this.isclick == true) {
                    this.isclick = false;
                    if (this.auditItem) {
                        let data = {};
                        data.auditItemId = this.auditItemId;
                        data.auditPointId = this.pId;
                        data.auditTaskId = this.auditTaskId;
                        data.description = this.description;
                        data.discoveryPicture = this.discoveryPicture;
                        data.improveNote = this.improveNote;
                        data.issueTypeId = this.issueTypeId;
                        data.issueTypeName = this.issueTypeName;
                        data.respId = this.respId;
                        data.responseName = this.respName;
                        this.APPApiServiceProxy.createIssue(
                            { issueEditDtos: data }
                        )
                            .finally(() => {
                            })
                            .then((res) => {
                                this.success(true)
                                this.isclick = true;
                            })
                    } else {
						console.log(this.respId)
                        this.IssueServiceProxy.createIssue(
                            this.entity
                        )
                            .finally(() => {
                                // this.close()
                            })
                            .then((res) => {
                                this.success(true)
                                this.isclick = true;
                            })
                    }
                }

            },

			//获取是图片上传是否禁用
			getIsEnableUploadPicture() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getIsEnableUploadPicture(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.IsEnableUploadPicture = !res;
				})
			},
			
			//获取问题责任人是否必填
			getIsRequiredIssueResp() {
			    this.isLoading = true;
			    this.IssueServiceProxy.getIsRequiredIssueResp(
			
			    ).finally(() => {
			        this.isLoading = false;
			    }).then(res => {
			        this.IsRequiredIssueResp = res;
			    })
			},

            getPictureQuality() {
			    this.isLoading = true;
			    this.SystemConfigServiceProxy.getPictureQuality()
                .finally(() => {
			        this.isLoading = false;
			    }).then(res => {
					this.PictureQuality = res;
			    })
			},

            //获取用户
            getuser() {
                this._EmployeeServiceProxy.getAllEmp().finally(() => {

                }).then((res) => {
                    res.forEach(item => {
                        this.discovererlist.push(item);
                        this.resplist.push(item);
                    });

                })
            },
            //获取问题分类
            getissueTreeData() {
                this.isLoading = true;
                this.IssueTypeServiceProxy.getPaged(
                    undefined,
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.issueTypelist = JSON.parse(JSON.stringify(res));
                        /*res.forEach(item=>{
                            this.issueTypelist.push(item);
                        });*/
                    })
            },

            //获取审核点
            getAuditPointTreeData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res)
                        this.auditPointIdlist = JSON.parse(JSON.stringify(res));
                        /*res.forEach(item=>{
                            this.auditPointIdlist.push(item);
                        });*/
                    })
            },

            //获取问题进度
            getissuerprogess() {
                this.isLoading = true;
                this.IssueProgressServiceProxy.getIssueStatusList(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        res.forEach(item => {
                            if (item.progress != 100 && this.isKanban == false) {
                                this.issueProgresslist.push(item);
                            }
                            if (this.isKanban) {
                                this.issueProgresslist.push(item);
                            }
                        });
                    })
            },

            //获取编辑数据
            getEditData() {
                this.isLoading = true;
                this.IssueServiceProxy.getForEdit(this.id)
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.discovererId = res.discovererId;
                        this.respId = res.respId;
                        this.respName = res.respName;
                        this.issueTypeIdList = res.issueTypeIdList;
                        this.pId = res.auditPointId;
                        this.issueProgress = res.issueProgress;
                        this.discoveryTime = res.discoveryTime ? moment(res.discoveryTime) : undefined;
                        this.closeTime = res.closeTime ? moment(res.closeTime) : undefined;
                        this.planStart = res.planStart ? moment(res.planStart) : undefined;
                        this.planEnd = res.planEnd ? moment(res.planEnd) : undefined;
                        this.description = res.description;
                        this.discoveryPicture = res.discoveryPicture;
                        this.improvePicture = res.improvePicture;
                        this.improveNote = res.improveNote;
                        this.correctiveNote = res.correctiveNote;
                        this.remark = res.remark;
                        var str1 = res.discoveryPicture.split(',');
                        str1.forEach(item => {
                            if (item != null && item != "") {
                                this.discoveryPicturelist.push(
                                    {
                                        uid: item,
                                        name: item,
                                        status: 'done',
                                        url: AppConsts.remoteServiceBaseUrl + res.realPath + '/' + item,
                                    }
                                )
                            }
                        });

                        var str2 = res.improvePicture.split(',');
                        str2.forEach(item => {
                            if (item != null && item != "") {
                                this.improvePicturelist.push(
                                    {
                                        uid: item,
                                        name: item,
                                        status: 'done',
                                        url: AppConsts.remoteServiceBaseUrl + res.realPath + '/' + item,
                                    }
                                )
                            }
                        });

                    });


            },


            change(value, name, model) {
                let responsibleID = model.triggerNode.$options.propsData.dataRef.respId;
                if (responsibleID != 0) {
                    if (!this.isResId) {
                        this.respId = responsibleID;
                    }
                }
            },

            Responsible() {
                this.isResId = true;
            },

            //图片上传
            beforeUpload(file) {
                // const isJPG =
                //     file.type === "image/jpeg" ||
                //     file.type === "image/png" ||
                //     file.type === "image/gif" ||
                //     file.type === "image/bmp";
                // if (!isJPG) {
                //     abp.message.error(this.l("请上传正确格式的图片文件"));
                // }
                // const isLtXM = file.size / 1024 / 1024 < 5;
                // if (!isLtXM) {
                //     abp.message.error(
                //         this.l(
                //             "ProfilePicture_Warn_SizeLimit",
                //             2
                //         )
                //     );
                // }

                // const newFile = this.compressImg(file)

                // return isJPG && isLtXM;
                return new Promise(async (resolve, reject) => {
                    const isJPG =
                        file.type === "image/jpeg" ||
                        file.type === "image/png" ||
                        file.type === "image/gif" ||
                        file.type === "image/bmp";
                    if (!isJPG) {
                        abp.message.error(this.l("请上传正确格式的图片文件"));
                    }
                    const isLtXM = file.size / 1024 / 1024 < 5;
                    // if (!isLtXM) {
                    //     abp.message.error(
                    //         this.l(
                    //             "ProfilePicture_Warn_SizeLimit",
                    //             2
                    //         )
                    //     );
                    // }
                    var newPictureQuality = this.PictureQuality.split(",")[1];
					var size = this.PictureQuality.split(",")[0];
                    const newFile = await this.compressImg(file,newPictureQuality,size)
                    resolve(newFile)
                })

            },

            //删除
            removePic(file) {
                return new Promise((resolve, reject) => {
                    this.$confirm({
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk() {
                            resolve(true)

                        },
                        onCancel() {
                            reject(false)
                        },
                    })

                })
            },
            //预览
            handleCancel() {
                this.previewVisible = false;
            },
            handleCancel2() {
                this.previewVisible2 = false;
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            async handlePreview2(file) {
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage2 = file.url || file.preview;
                this.previewVisible2 = true;
            },
            handleChange({ file, fileList }) {
                if (file.status === 'done') {
                    if (this.discoveryPicture == '') {
                        this.discoveryPicture = file.response.result.fileName + ',';
                    } else {
                        this.discoveryPicture = this.discoveryPicture + file.response.result.fileName + ',';
                    }
                }

                if (file.status === 'removed') {
                    if (file.response == undefined) {
                        var str = file.name + ',';
                        this.discoveryPicture = this.discoveryPicture.split(str).join('');
                    } else {
                        var str = file.response.result.fileName + ',';
                        this.discoveryPicture = this.discoveryPicture.split(str).join('');
                    }
                }
				let index = fileList.length;
				if(fileList[index-1].status == "done") {
					fileList[index-1].uid = fileList[index-1];
				}
				this.discoveryPicturelist = fileList;
            },
            handleChange2({ file, fileList }) {
                console.log(file, "file");
                if (file.status === 'done') {
                    if (this.improvePicture == '') {
                        this.improvePicture = file.response.result.fileName + ',';
                    } else {
                        this.improvePicture = this.improvePicture + file.response.result.fileName + ',';
                    }
                }

                if (file.status === 'removed') {
                    if (file.response == undefined) {
                        var str = file.name + ',';
                        this.improvePicture = this.improvePicture.split(str).join('');
                    } else {
                        var str = file.response.result.fileName + ',';
                        this.improvePicture = this.improvePicture.split(str).join('');
                    }
                }

                this.improvePicturelist = fileList;
            },
            respNameSelect() {
                ModalHelper.create(PersonnelSelection, {
                    respId: this.respId,
                    isSelect: true,
                    userType: 1
                }, {
                    width: 1100
                }).subscribe(res => {
                    if (res) {
                        this.respName = res[0].realName;
                        this.respId = res[0].userId;
                    }
                })
            },
            issueTypeChange(value, name, model) {
                this.issueTypeName = name[0];
            },
            // // base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
            // dataURLtoFile(dataurl, filename) {
            //     var arr = dataurl.split(',')
            //     var mime = arr[0].match(/:(.*?);/)[1]
            //     var bstr = atob(arr[1])
            //     var n = bstr.length
            //     var u8arr = new Uint8Array(n)
            //     while (n--) {
            //         u8arr[n] = bstr.charCodeAt(n)
            //     }
            //     return new File([u8arr], filename, { type: mime })
            // },

            // // 图片压缩函数
            // compressImg(file) {
            //     const that = this
            //     var files
            //     var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2)
            //     console.log(fileSize);
            //     var read = new FileReader()
            //     read.readAsDataURL(file)
            //     return new Promise(function (resolve, reject) {
            //         read.onload = function (e) {
            //             var img = new Image()
            //             img.src = e.target.result
            //             img.onload = function () {
            //                 // 默认按比例压缩
            //                 var w = this.width
            //                 var h = this.height
            //                 // 生成canvas
            //                 var canvas = document.createElement('canvas')
            //                 var ctx = canvas.getContext('2d')
            //                 var base64
            //                 // 创建属性节点
            //                 canvas.setAttribute('width', w)
            //                 canvas.setAttribute('height', h)
            //                 ctx.drawImage(this, 0, 0, w, h)
            //                 console.log(fileSize);
            //                 if (fileSize < 1) {
            //                     // 如果图片小于一兆 那么压缩0.5
            //                     base64 = canvas.toDataURL(file['type'], 0.3)
            //                 } else if (fileSize > 1 && fileSize < 2) {
            //                     // 如果图片大于1M并且小于2M 那么压缩0.5
            //                     base64 = canvas.toDataURL(file['type'], 0.6)
            //                 } else {
            //                     // 如果图片超过2m 那么压缩0.2
            //                     base64 = canvas.toDataURL(file['type'], 0.9)
            //                 }
            //                 // 回调函数返回file的值（将base64编码转成file）
            //                 files = that.dataURLtoFile(base64, file.name) // 如果后台接收类型为base64的话这一步可以省略
            //                 resolve(files)
            //             }
            //         }
            //     })
            // }

        }
    }
</script>

<style scoped>

</style>