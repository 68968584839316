<template>
	<div>
	    <a-config-provider :locale="zh_CN">
			<a-card>
				<a-row>
					<a-col :span="6">
						<a-row style="margin-bottom: 20px;">
							<a-col :span="24">
								<div style="width:100%;overflow:auto;" :style="styObj">
									<div class="con" style="width: 100%;min-width: 180px;">
										<a-spin :spinning="isTreeLoading">
											<a-tree
													style="width:100%"
													:expanded-keys="expandedTreeKeys"
													:selected-keys="selectedTreeKeys"
													v-model="checkedTreeKeys"
													:tree-data="treeData"
													:replace-fields="treeReplaceFields"
													@select="onTreeSelect"
													@expand="onTreeExpand"
											/>
										</a-spin>
								</div>
								</div>
							</a-col>
						</a-row>
					</a-col>
					<!-- <a-col :span="1">
						
					</a-col> -->
					<a-col :span="18" style="padding-left: 10px;">
						<a-row style="margin-bottom: 20px;">
						    <a-col :span="24" class="d-flex justify-content-end">
								<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('StartTime'), l('WEEndDate')]" v-model="timePeriod"
								 style=" width: 250px;text-align: center;margin-right:10px;" />
								<a-tree-select
								        style="width: 200px"
								        :tree-data="workProcessData"
								        :replace-fields="treeReplace"
								        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								        :placeholder="l('please_choose_the_type')"
								        allow-clear
								        tree-default-expand-all
										@change="workProcessClassChange"
								>
								</a-tree-select>
									<!-- <a-select showSearch :filterOption="filterOption" style="width: 200px;text-align: left;" dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"  v-model="workProcessClass"  placeholder="请输入类型搜索..." allow-clear @change="filteronchange">
										<a-select-option v-for="item in workProcessData" :key="item.id" @click="workProcessClassChange" :title="item.name">
										  {{ item.name }}
										</a-select-option>
									</a-select> -->
									<a-input-search :placeholder="l('please_enter_the_audit_item_to_search')" v-model.trim="filterText" enterButton
													style="width: 200px;margin-left: 10px;" @search="filteronchange"
							
									></a-input-search>
						    </a-col>
						</a-row>
						<a-spin :spinning="isLoading">
						    <a-table :columns="columns"
						             :data-source="tableData"
						             :customRow="customRow"
									 :rowKey="(record)=>record.id"
						             :scroll="{x:1000,y:610}"
									 :pagination="false"
									 size="small"
									 @change="handleTableChange"
						    >
						    </a-table>
						</a-spin>
						<a-pagination
						        class="pagination"
						        :total="totalItems"
						        showSizeChanger
						        showQuickJumper
								:show-total="total => `共有 ${totalItems} 条`"
								:defaultPageSize="request.maxResultCount"
								:pageSizeOptions="pageSizeOptions"
						        @change="onChangePage"
						        @showSizeChange="showSizeChange"/>
						<!-- <a-pagination
						        class="pagination"
						        :total="totalItems"
						        showSizeChanger
						        showQuickJumper
						        :showTotal="showTotalFun"
						        @change="onChangePage"
						        @showSizeChange="showSizeChange"/> -->
					</a-col>
				</a-row>
				
			</a-card>
		</a-config-provider>
	</div>
</template>

<script>
	import { AppComponentBase } from "@/shared/component-base";
	import ModalComponentBase from '../../../shared/component-base/modal-component-base';
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import { AuditItemGroupServiceProxy,ReportServiceProxy,AuditItemtypeServiceProxy } from '../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
	import {fileDownloadService} from "../../../shared/utils";
	import moment from "moment";
	
	let _this;
	export default {
	    name: "AuditItemIssueStatistics",
	    mixins: [ModalComponentBase],
	    components: {
	    },
		created() {
		    _this = this;
		    this.AuditItemGroupServiceProxy = new AuditItemGroupServiceProxy(this.$apiUrl, this.$api);
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
			this._fileDownloadService = fileDownloadService;
		},
		mounted() {
			this.fullData(); // 模态框必须,填充数据到data字段
			if(this.startTime) {
				this.timePeriod = [moment(this.startTime), moment(this.endTime)];
				this.startDate = moment(this.startTime);
				this.endDate = moment(this.endTime);
			}
		    this.getTree();
			this.getData();
			this.getWorkProcessData();

			window.addEventListener('resize', this.changeHeight);
			this.changeHeight();
		},
		data() {
		    return {
		        zh_CN,
		        isEdit: false,
		        isLook: false,
			
		        filterText: undefined,
				totalItems: 0,//总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "50", "100", "500"],
				request: {maxResultCount: 20, skipCount: 0},
			
		        tableData: [],
		        columns: [
					{title: this.l('Count'),width: 50,dataIndex: 'count',align: 'center',},
		            {title: this.l('audititem.name'), width: 500, dataIndex: 'auditItemName', align: 'left',ellipsis: true},
		        ],
		        selectedRowKey: [], // 选择的数据Id
				selectedRowList: [],
		        selectedRow: [],//选择的整行数据
		        isLoading: false,//加载中
				children: [],
				treeData: [],
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    code: 'code'
				},
				treeReplace: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				styObj:{
					height:0
				},

				checkedTreeKeys: [],
				isTreeEdit: false,
				isTreeCreate: false,
				isTreeLoading: false,//加载中
				treeNodeCode: undefined,
				expandedTreeKeys: [],
				selectedTreeKeys: [],
				selected: [],
				workProcessClass: undefined,
				workProcessData: [],
				teby: [],
				enable: [],
				_fileDownloadService: undefined,
				
				startDate: undefined,
				endDate: undefined,
				
				//LPA看板传值
				timePeriod: [],
				startTime: undefined,
				endTime: undefined
		    }
		},
		methods: {
			moment,
			//div高度
			changeHeight(){
					this.styObj.height=window.innerHeight-155+'px';
			},
			//获取数据
			getTree() {
				this.treeData = [];
				this.isTreeLoading = true;
				this.AuditItemGroupServiceProxy.getPaged(
				).finally(() => {
				    this.isTreeLoading = false;
				}).then(rel => {
					console.log(rel);
					if(rel.length <= 0){
					    this.treeData = [{name: '审核项', code: '', id: "null", children: rel,}];
					}
					else{
						this.treeData = [];
					    rel.forEach(item=>{
					        this.treeData.push(item)
					    });
					
					    console.log(rel)
					}
					
					this.expandedTreeKeys.push('null');
					this.recursion(rel);
				})
			},
			//排序
			handleTableChange(pagination, filters, sorter) {
			    console.log(sorter);
			    if (sorter.order != undefined){
			        this.Sorting = sorter.field + " " + sorter.order.replace("end","");
			    }
			    else{
			        this.Sorting = undefined;
			    }
			    this.getData();
			},
			recursion(rel) {
			    if (rel.length > 0) {
			        for (let i = 0; i < rel.length; i++) {
			            // console.log(rel[i].id)
			            this.expandedTreeKeys.push(rel[i].id)
			            this.recursion(rel[i].children);
			        }
			    } else {
			        return
			    }
			},
			onTreeExpand(expandedKeys, record) {
			    this.expandedTreeKeys = expandedKeys;
			},
			//审核项分组选择
			onTreeSelect(selectedKeys, info) {
			    if (selectedKeys.length > 0) {
			        this.workProcessClass = undefined;
			        this.selectedTreeKeys = [];
			        this.treeNodeCode = [];
			        if(isNaN(selectedKeys[0])){
			            console.log(selectedKeys, info);
			            this.selectedTreeKeys = selectedKeys;
			            this.workProcessClass = info.selectedNodes[0].data.props.code;
			            console.log(this.workProcessClass);
			            //获取物料信息
			            this.getData();
			            // this.selectedRowKeys = [];
			            // this.selectedRows = [];
			        }
			        else{
			            console.log(selectedKeys[0], info);
						this.selected = selectedKeys[0];
			            this.selectedTreeKeys = selectedKeys;
			            this.treeNodeCode = selectedKeys;
			            if (this.selectedTreeKeys[0] == 'null' || this.treeNodeCode == 'W01' || this.treeNodeCode == 'W02' || this.treeNodeCode == 'W03' || this.treeNodeCode == 'W04') {
			                this.groupAct = true;
			            } else {
			                this.groupAct = false;
			            }
			            //获取物料信息
			            this.getData();
			            // this.selectedRowKeys = [];
			            // this.selectedRows = [];
			        }
			    
			    }
			},
			//获取数据
			getData() {
				this.selectedRowKey = [];
				this.selectedRowList = [];
				this.isLoading = true;
				this.ReportServiceProxy.getAuditItemIssueStatistics(
				this.startDate,
				this.endDate,
				this.selected,
				this.workProcessClass,
				this.filterText ? this.filterText : undefined,
				this.Sorting,
				this.request.maxResultCount,
				this.request.skipCount
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.tableData = res.items;
					
					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
					    res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
					    (this.pageNumber - 1) * this.request.maxResultCount + 1,
					    this.pageNumber * this.request.maxResultCount,
					];
				})
			},
			//获取审核项类型
			getWorkProcessData() {
				this.isLoading = true;
				this.AuditItemtypeServiceProxy.getAllDate(
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					console.log(res);
				    this.workProcessData = JSON.parse(JSON.stringify(res));
				})
			},
			//搜索框点击事件
			filteronchange() {
			    this.pageNumber = 1;
			    this.request.skipCount = 0;
			    this.getData();
			},
			workProcessClassChange(key){
				console.log(key);
				this.workProcessClass = key;
				this.getData();
			},
			filterOption(input, option){
			    return (
			        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			    );
			},
			//分页
			onChangePage(page, pageSize) {
			    this.selectedRowKey = [];
			    this.pageNumber = page;
			    this.request.skipCount = (page - 1) * this.request.maxResultCount;
			    this.getData();
			},
			//正常分页展示形式，显示（共有 {0} 条）
			// showTotalFun() {
			// 	console.log(this.totalItems);
			//     return this.l(
			//         "GridFooterDisplayText",
			//         this.totalItems
			//     );
			// },
			showSizeChange(current, size) {
			    this.selectedRowKey = [];
			    this.pageNumber = 1;
			    this.request.maxResultCount = size;
			    this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			    this.getData();
			},
			
			DiscoverytimeChange(date, dateString) {
				this.startDate = date[0];
				this.endDate = date[1];
				this.getData();
			},
		}
	}
</script>

<style>
	.pagination {
	    margin: 10px auto;
	    text-align: right;
	}

	.con {
	    padding-bottom: 10px;
	}
</style>
