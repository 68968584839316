<template>
  <div style="width: 1920px;height: 1080px;">
    <div style="width: 1920px;height: 1080px;position: absolute;top: 0;z-index: 1;" id="LPA">
      <iframe style="width: 100%;height: 100%;display:block" frameborder="0" src="/#/FS-KanBan"></iframe>
    </div>
    <div style="width: 1920px;height: 1080px;z-index: 2;" id="ANDON">
      <!-- <iframe style="width: 100%;height: 100%;" frameborder="0" src="http://10.163.0.55:8001/ST"></iframe> -->
    </div>
  </div>
</template>

<script>
  import { AppComponentBase } from "@/shared/component-base";
  let _this;

  export default {
      name: 'Rotate',
	  mixins: [AppComponentBase],
      data() {
        return {
          ishandoff: true
        }
      },
      created(){
        _this = this;
        // setTimeout(function() {
        //   _this.getDate();
        // }, 30000);
      },
      mounted() {
        _this = this;
      },
      methods: {
        getDate() {
          if(this.ishandoff) {
            this.ishandoff = false;
            document.getElementById("LPA").style.display = "none";
            document.getElementById("ANDON").style.display = "";
          }else {
            this.ishandoff = true;
            document.getElementById("LPA").style.display = "";
            document.getElementById("ANDON").style.display = "none";
          }
		  setTimeout(function() {
		    _this.getDate();
		  }, 30000);
        }
      }
  }
</script>

<style>
</style>
