<template>
    <div>

        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span>{{l('audit.audittemplatename')}}</span>
            </div>
        </div>

        <a-config-provider :locale="zh_CN">
            <a-card>
				<a-row style="margin-bottom: 10px;">
					<a-col :span="24" style="text-align: right;">
						<a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
							style="width: 200px;" @search="filteronchange"></a-input-search>
					</a-col>
					<a-col :span="8">
						<a-col :span="24">
							<div style="width:100%;height:400px;overflow:auto;">
								<div class="con" style="width: 100%;min-width: 180px;">
									<a-spin :spinning="isTreeLoading">
										<a-tree
												style="width:100%"
												:expanded-keys="expandedTreeKeys"
												:selected-keys="selectedTreeKeys"
												v-model="checkedTreeKeys"
												:tree-data="treeData"
												:replace-fields="treeReplaceFields"
												@select="onTreeSelect"
												@expand="onTreeExpand"
										/>
									</a-spin>
							</div>
							</div>
						</a-col>
					</a-col>
					<a-col :span="16">
						<a-spin :spinning="isLoading">
						    <a-table :pagination="false"
						             :columns="columns"
						             :dataSource="tableData"
						             :rowSelection="{selectedRowKeys:selectedRowKeys,onSelect:onSelect}"
						             :customRow="customRow"
									 :scroll="{x:scroll_x,y:400}"
									 size="small"
						             :rowKey="(record)=>record.id">
						
						        <span slot="isWalkAudit" slot-scope="text">
										<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
										<span v-else><a-icon type='close'/></span>
									 </span>
						
						    </a-table>
						</a-spin>
						<a-pagination
						        class="pagination"
						        :total="totalItems"
						        v-model="pageNumber"
						        showSizeChanger
						        showQuickJumper
								:show-total="total => `共有 ${totalItems} 条`"
								:defaultPageSize="request.maxResultCount"
								:pageSizeOptions="pageSizeOptions"
						        @change="onChangePage"
						        @showSizeChange="showSizeChange"/>
					</a-col>
				</a-row>
            </a-card>
        </a-config-provider>
		<div class="modal-footer" style="margin-bottom: -15px;margin-top: 25px;">
		    <a-button :disabled="saving" @click="close()" type="button">
		        <a-icon type="close-circle"/>
		        {{ l('Cancel') }}
		    </a-button>
		    <a-button :loading="saving" :type="'primary'" @click="save">
		        <a-icon type="save"/>
		        {{ l('Save') }}
		    </a-button>
		</div>
    </div>
</template>

<script>

    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {
        AuditTaskServiceProxy,AuditItemTemplateGroupServiceProxy
    } from "../../../../shared/service-proxies";

    let _this;

    export default {
        name: "audit-item-template-model",
        mixins: [ModalComponentBase],
        components: {},
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            _this = this;
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
			this.AuditItemTemplateGroupServiceProxy = new AuditItemTemplateGroupServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
			this.getTree();
            this.getData();
        },
        data() {
            return {
                zh_CN,
                tableData: [],
                columns: [
                    {title: this.l('Name'), width: 100, dataIndex: 'name', align: 'center'},
                    {title: this.l('auditTemplate.isWalkAudit'), width: 100, dataIndex: 'isWalkAudit', align: 'center',scopedSlots: {customRender: "isWalkAudit"}},
                ],
                selectedRowKeys: [], //选择的Table数据
                selectedRows: [],
                isLoading: false,//加载中
                getTableDataApi: undefined,//接口
                //搜索筛选
                filterText: undefined,
                itemType: undefined,
                totalItems: 0,//总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "50", "100", "500"],
                request: {maxResultCount: 20, skipCount: 0},

                WarehouseTypeList: [],
                WarehouseTypeCode: undefined,

                warehouseId: undefined,
				
				isTreeLoading: false,//加载中
				checkedTreeKeys: [],
				expandedTreeKeys: [],
				selectedTreeKeys: [],
				
				auditItemTemplateGroupId: undefined,
				
				treeData: [],
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    code: 'code'
				},
            }
        },
        methods: {
			//获取审核项分组数据
			getTree() {
				this.treeData = [];
				this.isTreeLoading = true;
				this.AuditItemTemplateGroupServiceProxy.getPaged(
				).finally(() => {
				    this.isTreeLoading = false;
				}).then(rel => {
					console.log(rel);
					if(rel.length <= 0){
					    this.treeData = [{name: '审核项', code: '', id: "null", children: rel,}];
					}
					else{
						this.treeData = [];
					    rel.forEach(item=>{
					        this.treeData.push(item)
					    });
					
					    console.log(rel)
					}
					
					this.expandedTreeKeys.push('null');
					this.recursion(rel);
				})
			},
			
			//审核项分组选择
			onTreeSelect(selectedKeys, info) {
				this.auditItemTemplateGroupId = undefined;
				this.selectedTreeKeys = [];
				this.auditItemTemplateGroupId = selectedKeys[0];
				this.selectedTreeKeys = selectedKeys;
			    this.getData();
			},
			
			recursion(rel) {
			    if (rel.length > 0) {
			        for (let i = 0; i < rel.length; i++) {
			            // console.log(rel[i].id)
			            this.expandedTreeKeys.push(rel[i].id)
			            this.recursion(rel[i].children);
			        }
			    } else {
			        return
			    }
			},
			
			onTreeExpand(expandedKeys, record) {
			    this.expandedTreeKeys = expandedKeys;
			},
			
            getData() {
                this.isLoading = true;
                this.AuditTaskServiceProxy.getAllAuditItemTemplate(
					this.auditItemTemplateGroupId,
                    this.filterText ? this.filterText : undefined,
                    undefined,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(
                    () => {
                        this.isLoading = false;
                    }
                ).then(res => {
                        console.log(res.items);
                        this.tableData = res.items;
                        this.totalItems = res.totalCount;
                        this.totalPages = Math.ceil(
                            res.totalCount / this.request.maxResultCount
                        );
                        this.pagerange = [
                            (this.pageNumber - 1) * this.request.maxResultCount + 1,
                            this.pageNumber * this.request.maxResultCount,
                        ];

                    }
                );
            },

            save() {
                let counts = this.selectedRowKeys.length;
                if (counts == 0) {
                    return abp.message.info('请选择一个模板');
                }
                let ss =[];
                this.selectedRows.forEach(item=>{
                    ss.push(item.name)
                })
                this.success(this.selectedRows);
            },

            //正常分页展示形式，显示（共有 {0} 条）
            // showTotalFun() {
            //     return this.l(
            //         "GridFooterDisplayText",
            //         this.totalItems
            //     );
            // },

            //特殊分页展示形式，显示（当前 {0} 页/共 {1} 页，共 {2} 条/显示 {3}-{4} 条）
            // showTotalFun() {
            //     return this.l(
            //         "DefaultGridFooterDisplayText",
            //         this.pageNumber,
            //         this.totalPages,
            //         this.totalItems,
            //         this.pagerange[0],
            //         this.pagerange[1]
            //     );
            // },
            onChangePage(page, pageSize) {
                this.selectedRowKeys = [];
                this.selectedRows = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.selectedRowKeys = [];
                this.selectedRows = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.onChangePage(1, size);
            },

            resetRequest() {
                this.selectedRows = [];
                this.selectedRowKeys = [];
                this.pageNumber = 1;
                this.request = {maxResultCount: 10, skipCount: 0};
            },
            /**
             * 重写复选框点击事件(不可删除)
             */
            onSelect(record) {
                record.checked = true;
                if (record.checked) {
                    this.selectedRowKeys = [];
                    this.selectedRows = [];
                    this.selectedRows[0] = record;
                    this.selectedRowKeys[0] = record.id;

                    this.selectedWorkProcesRowKeys = [];
                    this.selectedWorkProcesRows = [];
                } else {
                    this.selectedRowKeys = [];
                    this.selectedRows = [];
                }
                console.log(this.selectedRowKeys)
            },
            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }
            },

            changeType(value) {
                console.log(value)
                this.WarehouseTypeCode = value;
                this.getData()
            },

			//搜索框点击事件
			filteronchange() {
				this.selectedRowKeys = [];
				this.selectedRows = [];
				this.pageNumber = 1;
				this.request.skipCount = 0;
				this.getData();
			},
        },
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>

