<template>
	<div class="label" ref="label" :style="labelStyle">
		<span class="required" v-if="required">*</span>
		{{ label }}
		<span v-if="colon">：</span>
	</div>
</template>

<script>
export default {
	name: 'q-label',
	props: {
		required: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: '',
		},
		//是否显示尾部冒号：
		colon: {
			type: Boolean,
			default: true,
		},
		//组件下方间隔
		marginBottom: {
			type: Number,
			default: 20,
		},
		position: {
			type: String,
			default: 'right',
		},
	},
	computed: {
		labelStyle() {
			return {
				'margin-bottom': this.marginBottom + 'px',
				'justify-content': this.position == 'right' ? 'flex-end' : 'flex-start',
			};
		},
	},
	mounted() {
		const label = this.$refs.label;
		try {
			const height = label.parentNode.nextSibling.offsetHeight;
			label.style.height = height + 'px';
		} catch (e) {
			label.style.height = 32 + 'px';
			console.error(e);
		}
	},
};
</script>

<style lang="less" scoped>
div {
	box-sizing: border-box;
}
.label {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 2px;
	.required {
		color: red;
		margin-right: 4px;
	}
}
</style>
