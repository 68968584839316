<template>
    <div class="editable-cell">
        <a-dropdown>
          <a style="color: #666666;" class="ant-dropdown-link" @click="e => e.preventDefault()">
			  <a-icon type="global" />
              {{ title }} <a-icon type="down" />
          </a>
          <a-menu slot="overlay" @click="onClick">
            <a-menu-item v-for="item in multilingualType" :key="item.name" :value="item.displayName">
              {{ item.displayName }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
</template>
<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { QYBLanguageServiceProxy } from '@/shared/service-proxies';
export default{
    mixins: [ModalComponentBase],
    props: {
    text: String,
    recordtext: Object,
  },
  data() {
    return {
      value: this.text,
      title: undefined,
      multilingualTypes: [],
      multilingualType: [],
    };
  },
  async mounted(){
    this._qybLanguageServiceProxy = new QYBLanguageServiceProxy(this.$apiUrl, this.$api);
    this.multilingualTypes = await this._qybLanguageServiceProxy.getLanguages();
    this.multilingualType = this.multilingualTypes.filter((txt) => {
        if (!txt.isDisabled) {
            return txt;
        }
    });
    this.getdata();
    
  },
  methods: {
    getdata(){
      const language = localStorage.getItem('qyb-language');
      if(language === null){
        //如果没有直接获取默认语言，并添加到缓存中
        let datas = this.multilingualTypes.filter((txt) => {
            if (txt.isDefault) {
                return txt;
            }
        });
        this.title = datas[0].displayName
        localStorage.setItem("qyb-language",datas[0].name)
      }else {
        let data = this.multilingualType.filter((txt) => {
            if (language === txt.name) {
                return txt;
            }
        });
        this.title = data[0].displayName
      }
    },
    onClick(e){ 
      this.title = e.item.value;
      this.cacheLanguageName(e.key);
    },
    cacheLanguageName(languageName){
      if (languageName){
        localStorage.setItem("qyb-language",languageName)
      }
      location.reload()
    }
  },
}
</script>
<!-- <style scoped>
>>>.ant-select-selection{
  border: none;
}
</style> -->
