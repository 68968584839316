<template>
	<div class="ant-layout-sider-children">
		<!-- logo -->
		<div class="alain-pro__sider-logo">
			<router-link class="alain-pro__header-logo-link" to="/" v-if="loading">
				<img :src="path + logoPic" class="tenant-logo" />
			</router-link>
		</div>

		<!-- sidebar -->
		<yoyo-sidebar-nav></yoyo-sidebar-nav>
	</div>
</template>

<script>
import { YoyoSidebarNav } from './components';
import bus from '@/shared/bus/bus.js';
import { CompanyImagesServiceProxy, layoutService } from '@/shared';
import { AppConsts } from '@/abpPro/AppConsts';
import apiHttpClient from '@/shared/utils/api-http-client';

export default {
	name: 'sidebar',
	components: {
		YoyoSidebarNav,
	},
	data() {
		return {
			//后台拼接的图片路径
			path: `${AppConsts.OSSRequestUrl}/${AppConsts.localization.defaultLocalizationSourceName}/`,
			logoPic: '',
			imgData: {},
			loading: false,
		};
	},
	created() {
		this.companyImgApi = new CompanyImagesServiceProxy(AppConsts.remoteServiceBaseUrl, apiHttpClient);
		this.loading = false;
		this.companyImgApi
			.getCompanyImagesListByTenantId()
			.finally(() => {
				this.loading = true;
			})
			.then((res) => {
				this.imgData = res;
				this.loading = true;
				this.$nextTick(() => {
					if (!layoutService.data.collapsed) {
						this.logoPic = `OblongLogo/${abp.session.tenantId}/${res.oblongLogo}`;
					} else {
						this.logoPic = `SquareLogo/${abp.session.tenantId}/${res.squareLogo}`;
					}
				});
			});
		bus.$on('changePics', (isClose) => {
			if (!isClose) {
				this.logoPic = `OblongLogo/${abp.session.tenantId}/${this.imgData.oblongLogo}`;
			} else {
				this.logoPic = `SquareLogo/${abp.session.tenantId}/${this.imgData.squareLogo}`;
			}
		});
	},
};
</script>

<style lang="less" scoped>
@import './sidebar.less';
.alain-pro__sider-logo {
	padding-left: 0px;
	height: 64px;
	background: white;
}
.tenant-logo {
	height: 50px;
}
</style>
