<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>

                    <a-col :span="24" style="margin-top: -10px;">
                        <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }" :label="l('LeaveReason')">
                            <a-select showSearch :filterOption="filterOption"
                                      style="width: 200px;text-align: left;margin-right: 10px;"
                                      dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                      :placeholder="l('please_select_LeaveReason')"
                                      @change="LeaveReasonChange"
                                      allow-clear>
                                <a-select-option v-for="item in LeaveReasonList"
                                                 :key="item.itemDetailCode"
                                                 :title="item.itemDetailName">
                                    {{ item.itemDetailName }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }" :label="l('LeaveRemark')">
                            <a-textarea v-model="LeaveNote" class="input" :rows="6"></a-textarea>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>

    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {AuditTaskServiceProxy, IssueTypeEditDto, LeaveRecordEditDto} from '../../../../shared/service-proxies';
    import {Dic} from "../../../../shared/utils";

    let _this;

    export default {
        name: "leave",
        mixins: [ModalComponentBase],
        components: {

        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            try {
                this.loading = true;
                this.LeaveReasonList = await Dic.getInstance().getDicAsync('LeaveReason');
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },
        data() {
            return {
                zh_CN,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: [],
                a:new IssueTypeEditDto(),
                pId: undefined,
                treeDataList: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                id:undefined,
                LeaveReasonList:[],
                LeaveReason:undefined,
                LeaveNote:undefined,
            }
        },
        methods: {

            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

            LeaveReasonChange(value){
                console.log(value);
                this.LeaveReason = value;
                this.getData();
            },

            save() {
                if (!this.LeaveReason) {
                    abp.message.info(this.l('please_select_LeaveReason'));
                    return;
                }

                let Leave = {
                    id:this.id,
                    leaveReason:this.LeaveReason,
                    leaveRemark:this.LeaveNote
                };
                console.log(Leave);
                this.AuditTaskServiceProxy.leave(
                    Leave
                ).finally(() => {
                    this.close()
                }).then(res => {
                    this.success(true)
                })

            },

        }
    }
</script>

<style>
    .input {
        width: 200px;
    }
</style>
