<template>
    <keep-alive :include="includes">
        <router-view />
    </keep-alive>
</template>

<script>
import { abpService } from '@/shared';
export default {
    name: 'layout-block',
    computed: {
        includes() {
            return abpService.tabincludes;
        },
    },
};
</script>

<style scoped></style>
