<template>
    <div class="background">
        <div class="header">
			<div class="title1">
			</div>
			<div class="title2">
				<div style="width: 100%;display: flex;justify-content: center;align-items: center;">
					<span>
						LPA看板
					</span>
				</div>
				<div style="font-size: 25px;margin-top: -15px;color: #01def7;width: 100%;display: flex;justify-content: center;align-items: center;">
					LPA KanBan
				</div>
				
			</div>
			<div class="title3">
				<div style="margin-right: 4%;margin-top: 15px;">
					{{time}}
				</div>
			</div>
		</div>
		<div class="mainstay">
			<div class="row1">
				<div class="columns1">
					<div class="frame layout">
						<div class="frame" id="PlanCompletionRate">
							
						</div>
					</div>
				</div>
				<div class="columns1">
					<div class="frame layout">
						<div class="frame" id="CloseRate">
							
						</div>
					</div>
				</div>
				<div class="columns1">
					<div class="frame layout">
						<div class="frame" id="ConformRate">
							
						</div>
					</div>
				</div>
			</div>
			<div class="row2">
				<div class="columns2">
					<div class="frame" id="DepartmentalIssuesNumber">
						
					</div>
				</div>
				<div class="columns2">
					<div class="frame" id="IssueType">
						
					</div>
				</div>
				<div class="columns2" style="position: relative;">
					<div class="frame" style="position: absolute;" id="AuditPointComplianceRate1">
						
					</div>
					<div class="frame" style="position: absolute;" id="AuditPointComplianceRate2">
						
					</div>
				</div>
			</div>
			<div class="row3">
				<div class="columns1">
					<div class="frame layout">
						<div class="frame" id="onTimeCloseRate">
							
						</div>
					</div>
				</div>
				<div class="columns1">
					<div class="frame layout">
						<div class="frame" id="IssueTimeoutStatistics">
							
						</div>
					</div>
				</div>
				<div class="columns1">
					<div class="frame layout">
						<div class="frame" id="DuplicateIssue">
							
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
	import { AppComponentBase } from "@/shared/component-base";
	import { KanBanServiceProxy,ReportServiceProxy } from '../../../shared/service-proxies';
	import { ModalHelper } from '@/shared/helpers';
	import * as echarts from 'echarts';
	import moment from "moment";
	let _this;
		
	export default {
	    name: "FS-KanBan",
	    mixins: [AppComponentBase],
	    components: {
			
	    },
	    created() {
	        _this = this;
	        this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
			this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
	    },
	    mounted() {
			setInterval(this.NowData, 0)
			this.getDataMaintenance();
	        this.getDepartmentalCompletionRate();
			this.getDepartmentalCloseRate();
			this.getDepartmentalConformRate();
			this.getCompletionRate();
			this.getCloseRate();
			this.getConformRate();
			this.getTimeCloseRate();
			this.getIssueTimeoutStatistics();
			this.getDuplicateIssue();
	    },
	    data() {
	        return {
				time: undefined,
	            // deptPlanCompletionRateList: [],
				departmentalCompletionData: [],
				deptCloseRateList: [],
				departmentalCloseData: [],
				deptConformList: [],
				deptConformData: [],
				planCompletionRateList: [],
				completionData: [],
				closeRateList: [],
				closeData: [],
				conformList: [],
				conformData: [],
				LevelList: [],
				Level: [],
				LevelNumber: undefined,
				LevelData: [],
				IssueTypeName: [],
				IssueTypeData: [],
				IssuePointName: [],
				IssuePointData: [],
				LevelAnimate: false,
				FakeData: [],
				onTimeCloseList: [],
				onTimeCloseData: [],
				issueTimeOutList: [1,2,3,4,5,6,7,8,9,10,11,12],
				issueTimeOutData: [],
				AuditPointName: [],
				AuditPointData: [],
				AuditPointComplianceRateName: [],
				AuditPointComplianceRateData: []
	        }
	    },
		computed: {
			option1() {
				return {
					title: {
					    text: '部门问题数量',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#ea9b94', '#cdea3c', '#0a91ea'],
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '60'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					// calculable: true,
					xAxis: [{
						type: 'category',
						data: this.deptPlanCompletionRateList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							lineStyle: {
							  color: '#fff'
							},
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if(_this.deptPlanCompletionRateList.length >8) {
									provideNumber = 2;
								}else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if(number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								}else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						}
					],
					series: this.departmentalCompletionData,
				}
			},
			option2() {
				return {
					title: {
					    text: '问题分类',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#ea9b94', '#cdea3c', '#0a91ea'],
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '60'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.IssueTypeName,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if(_this.deptCloseRateList.length >8) {
									provideNumber = 2;
								}else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if(number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								}else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						}
					],
					series: this.IssueTypeData,
				}
			},
			option3() {
				return {
					title: {
					    text: '部门符合率',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#ea9b94', '#cdea3c', '#0a91ea'],
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '60'
					},
					legend: {
						left: '5%',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.AuditPointName,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff',
							fontSize: 10,
							formatter: function(params) {
								let name = '';
								let number = params.length;
								let provideNumber = 4;
								if(_this.deptConformList.length >8) {
									provideNumber = 2;
								}else {
									provideNumber = 3;
								}
								let rowNumber = Math.ceil(number / provideNumber);
								if(number > provideNumber) {
									for (let p = 0; p < 2; p++) {
										let tempStr;
										let start = p * provideNumber;
										let end = start + provideNumber;
										if (p == rowNumber - 1) {
											tempStr = params.substring(start, number);
										} else {
											tempStr = params.substring(start, end) + "\n";
										}
										name += tempStr;
									}
								}else {
									name = params
								}
								return name
							}
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('符合率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.AuditPointData,
				}
			},
			option4() {
				return {
					title: {
					    text: '计划完成率',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '50'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.planCompletionRateList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('完成率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.completionData,
				}
			},
			option5() {
				return {
					title: {
					    text: '问题关闭率',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '50'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.closeRateList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('关闭率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.closeData,
				}
			},
			option6() {
				return {
					title: {
					    text: '符合率',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '50'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.conformList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: this.l('符合率(%)'),
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.conformData,
				}
			},
			option7() {
				return {
					title: {
					    text: '问题按时关闭率',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '50'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.onTimeCloseList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}],
					yAxis: {
							type: 'value',
							name: '%',
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
					},
					series: this.onTimeCloseData,
				}
			},
			option8() {
				return {
					title: {
					    text: '问题超时统计',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '50'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					// calculable: true,
					xAxis: [{
						type: 'category',
						data: this.issueTimeOutList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						}
					],
					series: this.issueTimeOutData,
				}
			},
			option9() {
				return {
					title: {
					    text: '重复发生问题数量',
						y: '5%',
						x: 'center',
						textStyle: {
						  fontSize: '20px',
						  fontWeight: 'normal',
						  color: '#fff'
						}
					},
					grid: {
						top: '85',
						left: '50',
						right: '50',
						bottom: '50'
					},
					legend: {
						left: 'center',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					// calculable: true,
					xAxis: [{
						type: 'category',
						data: this.issueTimeOutList,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisLabel: {
							color: '#fff'
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						}
					],
					series: this.DuplicateIssueData,
				}
			},
			option10() {
				return {
					color: ['#00aa7f', '#ff5500'],
					grid: {
						top: '85',
						left: '50',
						right: '58',
						bottom: '60'
					},
					legend: {
						left: '70%',
						bottom: '1%',
						textStyle: {
							color: '#fff'
						}
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.AuditPointComplianceRateName,
						splitLine: {
							show:false,
							lineStyle: {
							  color: ['#fff']
							}
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisLabel: {
							show: false,
							color: '#fff',
							fontSize: 10
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('数量'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
								color: '#fff'
							},
							axisTick: {
								show: false
							},
							axisLine: {
								show: false
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
						{
							type: 'value',
							name: '',
							min: 0,
							max: 100,
							axisLabel: {
								show: false,
								formatter: '{value}',
								color: '#fff'
							},
							axisTick: {
								show: false
							},
							axisLine: {
								show: false
							},
							splitLine: {
								show:false,
								lineStyle: {
								  color: ['#fff']
								}
							},
							nameTextStyle: {
								color: '#fff'
							}
						},
					],
					series: this.AuditPointComplianceRateData,
				}
			}
		},
	    methods: {
			//时间
			NowData() {
			  let aData = new Date();
			  let year = aData.getFullYear();
			  let month = (aData.getMonth() + 1);
			  let day = aData.getDate();
			  let Hour = aData.getHours();
			  let Minute = aData.getMinutes();
			  let Second = aData.getSeconds();
			  month = month < 10 ? '0' + month : month;
			  day = day < 10 ? '0' + day : day;
			  Hour = Hour < 10 ? '0' + Hour : Hour;
			  Minute = Minute < 10 ? '0' + Minute : Minute;
			  Second = Second < 10 ? '0' + Second : Second;
			  this.time = year + "-" + month + "-" + day + " " + Hour + ":" + Minute + ":" + Second;
			},
			
			//目标值
			getDataMaintenance() {
				this.KanBanServiceProxy.getAll(
					
				).finally(() => {
				    
				}).then(res => {
					this.FakeData = res;
				})
			},
			
			//部门问题数量
	        getDepartmentalCompletionRate() {
				this.KanBanServiceProxy.getDeptIssueQty(
					
				).finally(() => {
				    
				}).then(res => {
					this.deptPlanCompletionRateList = [];
					let monthList = [];
					for(let i in res.month) {
						let issueNumber = {
							name: res.month[i],
							type: 'bar',
							data: [],
							barMaxWidth: 20
						};
						monthList.push(issueNumber);
					}
					
					for(let i in res.data) {
						this.deptPlanCompletionRateList.push(res.data[i].name);
						for(let l in res.data[i].data) {
							monthList[l].data.push(res.data[i].data[l])
						}
					}
					this.departmentalCompletionData = monthList;
					
					this.DepartmentalCompletionRateCharts();
				})
	        },
			
			DepartmentalCompletionRateCharts() {
				this.myeChart = echarts.init(document.getElementById("DepartmentalIssuesNumber"));
			
				this.myeChart.clear();
				this.myeChart.setOption(this.option1);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//问题分类
			getDepartmentalCloseRate() {
				this.KanBanServiceProxy.getIssueTypeTopTen(
					
				).finally(() => {
				    
				}).then(res => {
					this.IssueTypeName = [];
					for(let i in res) {
						this.IssueTypeName.push(res[i].name);
						let issueType = {
							name: res[i].name,
							type: 'bar',
							data: res[i].data,
							barMaxWidth: 20
						};
						this.IssueTypeData.push(issueType);
					}
					
					this.DepartmentalCloseRateCharts();
				})
			},
			
			DepartmentalCloseRateCharts() {
				this.myeChart = echarts.init(document.getElementById("IssueType"));
			
				this.myeChart.clear();
				this.myeChart.setOption(this.option2);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//审核点符合率
			getDepartmentalConformRate() {
				this.KanBanServiceProxy.getAuditPointComplianceRate(
					
				).finally(() => {
				    
				}).then(res => {
					this.AuditPointName = [];
					let monthList = [];
					let complianceRate = {
						name: "符合率",
						type: 'line',
						yAxisIndex : 1,
						data: [],
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					let targetValue = {
						name: "目标值",
						type: 'line',
						yAxisIndex : 1,
						data: []
					};
					for(let i in res.month) {
						let auditPoint = {
							name: res.month[i],
							type: 'bar',
							data: [],
							barMaxWidth: 20
						};
						monthList.push(auditPoint);
					}
					
					this.AuditPointComplianceRateName = [];
					this.AuditPointComplianceRateData = [];
					let xAxis = 0;
					for(let i in res.data) {
						this.AuditPointName.push(res.data[i].name);
						for(let l in res.data[i].conform) {
							monthList[l].data.push(res.data[i].conform[l])
						}
						for(let c in res.data[i].complianceRate) {
							complianceRate.data.push(res.data[i].complianceRate[c])
							xAxis = xAxis + 1;
							this.AuditPointComplianceRateName.push(xAxis);
						}
					}
					this.AuditPointComplianceRateData.push(complianceRate);
					
					for(let i in this.FakeData) {
						if(this.FakeData[i].code == "Audit-Point-Compliance-Rate") {
							for(let l in this.AuditPointComplianceRateName) {
								targetValue.data.push(Number(this.FakeData[i].value))
							}
						}
					}
					this.AuditPointComplianceRateData.push(targetValue);
					
					this.AuditPointData = monthList;
					console.log(this.AuditPointData)
					
					this.DepartmentalConformRateCharts1();
					this.DepartmentalConformRateCharts2();
				})
			},
			
			DepartmentalConformRateCharts1() {
				this.myeChart = echarts.init(document.getElementById("AuditPointComplianceRate1"));
			
				this.myeChart.clear();
				this.myeChart.setOption(this.option3);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			DepartmentalConformRateCharts2() {
				this.myeChart = echarts.init(document.getElementById("AuditPointComplianceRate2"));
			
				this.myeChart.clear();
				this.myeChart.setOption(this.option10);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//计划完成率
			getCompletionRate() {
				this.KanBanServiceProxy.getYearCompletionRate(
					
				).finally(() => {
				    
				}).then(res => {
					this.planCompletionRateList = res.monthList;
					let completed = {
						name: "完成",
						type: 'bar',
						stack: 'bar',
						data: res.finshList,
						barMaxWidth: 20
					};
					
					let NoCompletion = {
						name: "未完成",
						type: 'bar',
						stack: 'bar',
						data: res.noFinshList,
						barMaxWidth: 20
					};
					
					let completedRate = {
						name: "完成率",
						type: 'line',
						yAxisIndex : 1,
						data: res.finshRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					let targetValue = {
						name: "目标值",
						type: 'line',
						yAxisIndex : 1,
						data: []
					};
					for(let i in this.FakeData) {
						if(this.FakeData[i].code == "Plan-completion-rate") {
							for(let l = 0; l < 12; l++) {
								targetValue.data.push(Number(this.FakeData[i].value))
							}
						}
					}
					
					this.completionData = [];
					this.completionData.push(completed);
					this.completionData.push(NoCompletion);
					this.completionData.push(completedRate);
					this.completionData.push(targetValue);
					
					this.CompletionRateCharts();
				})
			},
			
			CompletionRateCharts() {
				this.myeChart = echarts.init(document.getElementById("PlanCompletionRate"));
				
				this.myeChart.clear();
				this.myeChart.setOption(this.option4);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//问题关闭率
			getCloseRate() {
				this.KanBanServiceProxy.getYearIssueCloseRate(
					
				).finally(() => {
				    
				}).then(res => {
					this.closeRateList = res.monthList;
					let closeList = {
						name: "关闭",
						type: 'bar',
						stack: 'bar',
						data: res.closeList,
						barMaxWidth: 20
					};
					
					let noCloseList = {
						name: "未关闭",
						type: 'bar',
						stack: 'bar',
						data: res.noCloseList,
						barMaxWidth: 20
					};
					
					let closeRateList = {
						name: "关闭率",
						type: 'line',
						yAxisIndex : 1,
						data: res.closeRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					let targetValue = {
						name: "目标值",
						type: 'line',
						yAxisIndex : 1,
						data: []
					};
					for(let i in this.FakeData) {
						if(this.FakeData[i].code == "Issue-closure-rate") {
							for(let l = 0; l < 12; l++) {
								targetValue.data.push(Number(this.FakeData[i].value))
							}
						}
					}
					
					this.closeData = [];
					this.closeData.push(closeList);
					this.closeData.push(noCloseList);
					this.closeData.push(closeRateList);
					this.closeData.push(targetValue);
					
					this.CloseRateCharts();
				})
			},
			
			CloseRateCharts() {
				this.myeChart = echarts.init(document.getElementById("CloseRate"));
				
				this.myeChart.clear();
				this.myeChart.setOption(this.option5);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//符合率
			getConformRate() {
				this.KanBanServiceProxy.getYearComplianceRate(
					
				).finally(() => {
				    
				}).then(res => {
					this.conformList = res.monthList;
					let issueList = {
						name: "审核项",
						type: 'bar',
						stack: 'bar',
						data: res.issueList,
						barMaxWidth: 20
					};
					
					let complianceRateList = {
						name: "符合率",
						type: 'line',
						yAxisIndex : 1,
						data: res.complianceRateList,
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					
					let targetValue = {
						name: "目标值",
						type: 'line',
						yAxisIndex : 1,
						data: []
					};
					for(let i in this.FakeData) {
						if(this.FakeData[i].code == "Compliance-rate") {
							for(let l = 0; l < 12; l++) {
								targetValue.data.push(Number(this.FakeData[i].value))
							}
						}
					}
					
					this.conformData = [];
					this.conformData.push(issueList);
					this.conformData.push(complianceRateList);
					this.conformData.push(targetValue);
					
					this.ConformRateCharts();
				})
			},
			
			ConformRateCharts() {
				this.myeChart = echarts.init(document.getElementById("ConformRate"));
			
				this.myeChart.clear();
				this.myeChart.setOption(this.option6);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//问题按时关闭率
			getTimeCloseRate() {
				this.KanBanServiceProxy.getIssueOnTimeCloseRate(
					
				).finally(() => {
				    
				}).then(res => {
					this.onTimeCloseList = [];
					this.onTimeCloseData = [];
					let onTimeCloseRateList = {
						name: "按时关闭率",
						type: 'line',
						data: [],
						label: {
							show: true,
							color: '#fff',
							textBorderWidth: '0',
							formatter: function(data){
								if(data.value>0) {
									return data.value + '%'
								}else {
									return ""
								}
							}
						}
					};
					for(let i in res) {
						this.onTimeCloseList.push(res[i].name);
						onTimeCloseRateList.data.push(res[i].onTimeCloseRate);
					}
					
					let targetValue = {
						name: "目标值",
						type: 'line',
						data: []
					};
					for(let i in this.FakeData) {
						if(this.FakeData[i].code == "onTime-Issue-Close-Rate") {
							for(let l = 0; l < 12; l++) {
								targetValue.data.push(Number(this.FakeData[i].value))
							}
						}
					}
					
					this.onTimeCloseData.push(onTimeCloseRateList);
					this.onTimeCloseData.push(targetValue);
					
					this.onTimeCloseRateCharts();
				})
			},
			
			onTimeCloseRateCharts() {
				this.myeChart = echarts.init(document.getElementById("onTimeCloseRate"));
							
				this.myeChart.clear();
				this.myeChart.setOption(this.option7);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//问题超时统计
			getIssueTimeoutStatistics() {
				this.issueTimeOutData = [];
				let ultraHourList = {
					name: "超24H未关闭",
					type: 'bar',
					data: [],
					barMaxWidth: 20
				};
				
				let ultraDayList = {
					name: "超30天未关闭",
					type: 'bar',
					data: [],
					barMaxWidth: 20
				};
				this.ReportServiceProxy.getTwentyFourInnerIssueCloseRate(
					false,
					2
				).finally(() => {
				    
				}).then(res => {
					for(let i in res) {
						ultraHourList.data.push(res[i].notCloseCount)
					}
					this.issueTimeOutData.push(ultraHourList);
				});
				
				this.ReportServiceProxy.getTwentyFourInnerIssueCloseRate(
					false,
					3
				).finally(() => {
				    
				}).then(res => {
					for(let i in res) {
						ultraDayList.data.push(res[i].notCloseCount)
					}
					this.issueTimeOutData.push(ultraDayList);
					this.IssueTimeoutStatisticsCharts();
				})
			},
			
			IssueTimeoutStatisticsCharts() {
				this.myeChart = echarts.init(document.getElementById("IssueTimeoutStatistics"));
				
				this.myeChart.clear();
				this.myeChart.setOption(this.option8);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			//重复发生问题数量
			getDuplicateIssue() {
				this.KanBanServiceProxy.getSixMonthRepeatIssueForEcharts(
					
				).finally(() => {
				    
				}).then(res => {
					let DuplicateIssueList = [
						{
							name: "重复问题数量",
							type: 'bar',
							data: [0,0,0,0,0,0,0,0,0,0,0,0],
							barMaxWidth: 20
						}
					];
					for(let i in DuplicateIssueList) {
						for(let l in res) {
							if(res[l].month == i) {
								DuplicateIssueList[i] = res[l].issueCount;
							}
						}
					}
					
					this.DuplicateIssueData = DuplicateIssueList;
					this.DuplicateIssueCharts();
				})
			},
			
			DuplicateIssueCharts() {
				this.myeChart = echarts.init(document.getElementById("DuplicateIssue"));
				
				this.myeChart.clear();
				this.myeChart.setOption(this.option9);
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			}
	    }
	}
</script>

<style lang="less" scoped>
	.background{
		width: 1920px;
		height: 1080px;
		background-color: #000;
	}
	
	.header{
		width: 100%;
		height: 12%;
		display: flex;
		background-image: url('../../../../public/img/image/top.jpg');
	}
	
	.title1{
		width: 30%;
		height: 100%;
	}
	
	.title2{
		width: 40%;
		height: 100%;
		color: #fff;
		font-size: 45px;
		// margin-top: -10px;
	}
	
	.title3{
		width: 30%;
		height: 100%;
		color: #fff;
		font-size: 25px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: right;
	}
	
	.mainstay{
		width: 100%;
		height: 88%;
		display: flex;
	}
	
	.row1{
		width: 23.8%;
		height: 100%;
		margin-left: 2%;
	}
	
	.row2{
		width: 46.5%;
		height: 100%;
		margin-left: 1%;
		margin-right: 1%;
	}
	
	.row3{
		width: 23.8%;
		height: 100%;
		margin-right: 2%;
	}
	
	.subtitle{
		width: 100%;
		height: 5%;
		color: #fff;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	.columns1{
		width: 100%;
		height: 31%;
		margin-bottom: 4%;
		background-image: url('../../../../public/img/image/block.jpg');
	}
	
	.columns2{
		width: 100%;
		height: 31%;
		margin-bottom: 2%;
		background-image: url('../../../../public/img/image/blockLong.jpg');
	}
	
	.column3{
		width: 100%;
		height: 31%;
		margin-bottom: 2%;
		display: flex;
	}
	
	.column4{
		width: 49%;
		height: 100%;
		background-image: url(../../../../public/img/image/frame1.png);
		background-size: 100% 100%;
	}
	
	.frame{
		width: 100%;
		height: 100%;
	}
	
	.layout{
		width: 100%;
		height: 100%;
		position: relative;
	}
	
	.icon-outer {
		width: 18px;
		height: 18px;
		border-radius: 9px;
		border: 1px solid #42dfb7;
		position: absolute;
		left: 25px;top: 20px;
	}
	
	.icon-inner {
		width: 12px;
		height: 12px;
		border-radius: 6px;
		background-color: #42dfb7;
		top: 1.5px;left: 1.5px;
		position: absolute;
	}
	
	.anim {
	   transition: all 0.5s;
	   margin-top: -32px;
	}
	
	.auditTask {
		width: 94%;
		height: 75.5%;
		margin-left: 3%;
		overflow-y:auto;
		scrollbar-width: none; /* Firefox 64 */
	}
	
	.auditPlan {
		width: 94%;
		height: 80%;
		margin-left: 3%;
		overflow-y:auto;
		scrollbar-width: none; /* Firefox 64 */
	}
	
	.Level {
		width: 100%;
		height: 100%;
		overflow: hidden;
		white-space: nowrap;
	}
	
	.LevelAnim {
	   transition: all 0.5s;
	   margin-left: -32px;
	}
	
	.auditTask::-webkit-scrollbar {
	      display: none;
	}
	
	.auditPlan::-webkit-scrollbar {
	      display: none;
	}
	
	/* .Level::-webkit-scrollbar {
	      display: none;
	} */
	
	.backgroundcolor {
		color: #ffff00;
		background-color: #e957ee;
	}
</style>
