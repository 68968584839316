<template>
	<div>
		<div>
			<a-config-provider :locale="zh_CN">
				<a-row>
					<a-col :span="24">
						<a-col :span="3" class="audit">
							<p>{{l('Auditdescription')}}:</p>
						</a-col>
						<a-col :span="21">
							<p class="view">{{noteDate}}</p>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="3" class="audit">
							<p>{{l('Enterthevalue')}}:</p>
						</a-col>
						<a-col :span="8">
							<p class="view">{{dataDate}}</p>
						</a-col>
					</a-col>
					<a-col :span="24">
						<a-col :span="3" class="audit">
							<p>{{l('Livephotos')}}:</p>
						</a-col>
						<a-col :span="20" class="view">
							
						</a-col>
					</a-col>
				</a-row>
				<div><p>{{l('Livephotos')}}:</p></div>
				<div class="images" v-vuer="{movable: false}" style="min-height: 100px;">
					
					<img :src="item" :key="item" style="width: 100px;height: 100px;margin-right: 10px;" v-if="img" v-for="item in Photo" @click="show()" />
				</div>
			</a-config-provider>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { ModalHelper } from '@/shared/helpers';
	import EditThePart from "../photos/photos.vue";
	import {AppConsts} from "../../../../abpPro/AppConsts";
	import 'viewerjs/dist/viewer.css'
	import Vuer from 'v-viewer'
	import Vue from 'vue'
	Vue.use(Vuer, {name: 'vuer'})
	
	let _this;
	export default {
		name: 'Order',
		mixins: [ModalComponentBase],
		props:{
			note:[],
			data:[],
			picture:[],
			realPath: [],
			tempPath: [],
		},
		components: {
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
		},
		mounted() {
		    this.getData();
			this.getDate();
		},
		watch:{
		    note:function () {
		        this.getData();
		    },
			data:function () {
			    this.getData();
			},
			picture:function () {
			    this.getData();
			},
			realPath:function () {
			    this.getData();
			},
			tempPath:function () {
			    this.getData();
			},
		},
		data() {
		    return {
				zh_CN,
		        isEdit: false,//是否是编辑
		        isLook: false,//是否是查看
				noteDate: undefined,
				dataDate: undefined,
				pictureDate: undefined,
				real: undefined,
				temp: undefined,
				previewVisible: false,
				// 上传的接口地址
				uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
				//图片的请求头信息
				picUploadHeaders: {
				    Authorization: "Bearer " + abp.auth.getToken(),
				    type: "photo"
				},
				previewImage: '',
				discoveryPicture:'',
				discoveryPicturelist:[],
				picturedate: undefined,
				photos: undefined,
				Photo: [],
		    }
		},
		methods: {
			getData() {
				console.log(this.picture)
				this.noteDate = this.note;
				this.dataDate = this.data;
				this.pictureDate = this.picture;
				this.real = this.realPath;
				this.temp = this.tempPath;
				this.getDate();
			},
			getDate() {
				if(this.picture==undefined) {
					
				}else {
					this.picturedate=this.pictureDate.split(',');
					console.log(this.picturedate);
					this.picturedate.forEach(item=>{
						this.photos = this.realPath +'/'+ item;
						this.Photo.push(this.photos);
					});
				}
			},
			show () {
			        const vuer = this.$el.querySelector('.images').$vuer
			        vuer.show()
			},
		}
	}
</script>

<style>
	.view {
		margin-left: 10px;
	}
	
	.audit {
		text-align: right;
	}
</style>
