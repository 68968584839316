import { ILayout, layoutStore_NS, rootStore } from '@/shared/store';

class LayoutService {
	constructor() {
		window.addEventListener(
			'resize',
			() => {
				let isPad = window.innerWidth < 1000;
				let obj = { ...this.data };
				obj.isPad = isPad;
				obj.collapsed = isPad;
				this.set(obj);
			},
			false
		);
		let isPad = window.innerWidth < 1000;
		let obj = { ...this.data };
		obj.isPad = isPad;
		obj.collapsed = isPad;
		this.set(obj);
	}

	get data(): ILayout {
		return rootStore.getters[`${layoutStore_NS}/get`];
	}

	set(val: ILayout) {
		rootStore.commit(`${layoutStore_NS}/set`, val);
	}

	changeTheme() {
		let data = JSON.parse(JSON.stringify(this.data));
		data.theme = data.theme === 'dark' ? 'light' : 'dark';
		this.set(data);
	}
}

const layoutService = new LayoutService();
export default layoutService;
