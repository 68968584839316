import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const TaskSystemRouting: RouteConfig[] = [
    {
        path: 'task-system',
        meta: { title: '任务系统' },
        component: LayoutBlock,
        redirect: '/app/task-system/baseinfo',
        children: [
            {
                name: 'baseinfo',
                path: 'baseinfo',
                component: () => import(/* webpackChunkName: "admin" */ './baseinfo/baseinfo.vue'),
                meta: { title: '任务列表' },
            },
            {
                name: 'task-complete-calc',
                path: 'task-complete-calc',
                component: () => import(/* webpackChunkName: "admin" */ './task-complete-calc/task-complete-calc.vue'),
                meta: { title: '任务完成率' },
            },
            {
                name: 'task-refuse-calc',
                path: 'task-refuse-calc',
                component: () => import(/* webpackChunkName: "admin" */ './task-refuse-calc/task-refuse-calc.vue'),
                meta: { title: '任务拒绝率' },
            },
        ],
    },
];
export default TaskSystemRouting;
