import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const UnderlyingRouting: RouteConfig[] = [
    {
        path: 'underlying-data',
        meta: { title: '基础数据' },
        component: LayoutBlock,
        redirect: '/app/underlying-data/the-progress-of-the-problem',
        children: [
            {
                name: 'the-progress-of-the-problem',
                path: 'the-progress-of-the-problem',
                component:()=>import('./the-progress-of-the-problem/the-progress-of-the-problem.vue'),
                meta: { title: '218' },
            },
			{
			    name: 'problem-classification',
			    path: 'problem-classification',
			    component:()=>import('./problem-classification/problem-classification.vue'),
			    meta: { title: '219' },
			},
			{
			    name: 'audit-point',
			    path: 'audit-point',
			    component:()=>import('./audit-point/audit-point.vue'),
			    meta: { title: '220' },
			},
			{
			    name: 'audit-items',
			    path: 'audit-items',
			    component:()=>import('./audit-items/audit-items.vue'),
			    meta: { title: '221' },
			},
			{
			    name: 'the-type-of-audit-item',
			    path: 'the-type-of-audit-item',
			    component:()=>import('./the-type-of-audit-item/the-type-of-audit-item.vue'),
			    meta: { title: '222' },
			},
			{
			    name: 'audit-level',
			    path: 'audit-level',
			    component:()=>import('./audit-level/audit-level.vue'),
			    meta: { title: '223' },
			},
			// {
			//     name: 'organization-structure',
			//     path: 'organization-structure',
			//     component:()=>import('./organization-structure/organization-structure.vue'),
			//     meta: { title: '审核模板' },
			// },
			{
			    name: 'new-structure',
			    path: 'new-structure',
			    component:()=>import('./new-structure/new-structure.vue'),
			    meta: { title: '225' },
			},
			{
				name: 'layoutUser',
				path: 'layoutUser',
				component:()=>import('./layoutUser/layoutUser.vue'),
				meta: { title: '224' },
			},
			{
				name: 'organization-structure',
				path: 'organization-structure',
				component:()=>import('./organization-structure/organization-structure.vue'),
				meta: { title: '226' },
			},
			{
				name: 'audit-task',
				path: 'audit-task',
				component:()=>import('./audit-task/audit-task.vue'),
				meta: { title: '228' },
			},
			{
				name: 'audittaskdetail',
				path: 'audittaskdetail',
				component:()=>import('./audittaskdetail/audittaskdetail.vue'),
				meta: { title: '231' },
			},
			{
				name: 'problem-management',
				path: 'problem-management',
				component:()=>import('./problem-management/problem-management.vue'),
				meta: { title: '232' },
			},
			{
				name: 'audit-item-templates',
				path: 'audit-item-templates',
				component:()=>import('./audit-item-templates/audit-item-templates.vue'),
				meta: { title: '233' },
			},
			{
				name: 'issueKanban',
				path: 'issueKanban',
				component:()=>import('./issueKanban/issueKanban.vue'),
				meta: { title: '234' },
			},
			{
				name: 'punch-card-records',
				path: 'punch-card-records',
				component:()=>import('./punch-card-records/punch-card-records.vue'),
				meta: { title: '240' },
			},
			{
				name: 'month-view',
				path: 'month-view',
				component:()=>import('./month-view/month-view.vue'),
				meta: { title: '239' },
			},
			{
				name: 'Mail-configuration',
				path: 'Mail-configuration',
				component:()=>import('./Mail-configuration/Mail-configuration.vue'),
				meta: { title: '241' },
			},
			{
				name: 'Line-chart',
				path: 'Line-chart',
				component:()=>import('./Line-chart/Line-chart.vue'),
				meta: { title: '242' },
			},
			{
				name: 'Message-push',
				path: 'Message-push',
				component:()=>import('./Message-push/Message-push.vue'),
				meta: { title: '246' },
			},
			{
				name: 'issue-reports',
				path: 'issue-reports',
				component:()=>import('./issue-reports/issue-reports.vue'),
				meta: { title: '248' },
			},
			{
				name: 'month-view-emp',
				path: 'report/month-view-emp',
				component:()=>import('./report/month-view-emp/month-view-emp.vue'),
				meta: { title: '249' },
			},
			{
				name: 'issue-config',
				path: 'issue-config',
				component:()=>import('./issue-config/issue-config.vue'),
				meta: { title: '250' },
			},
            {
				name: 'AuditResultSummary_Report',
				path: 'AuditResultSummary_Report',
				component:()=>import('./AuditResultSummary_Report/AuditResultSummary_Report.vue'),
				meta: { title: '252' },
			},
			{
				name: 'report-audit-point-record',
				path: 'report/audit-point-record',
				component:()=>import('./report/audit-point-record/audit-point-record.vue'),
				meta: { title: '253' },
			},
			{
				name: 'report-user-audit-record',
				path: 'report/user-audit-record',
				component:()=>import('./report/user-audit-record/user-audit-record.vue'),
				meta: { title: '254' },
			},
            {
				name: 'option-list',
				path: 'option-list',
				component:()=>import('./option-list/option-list.vue'),
				meta: { title: '257' },
			},
			{
				name: 'spot-check',
				path: 'spot-check',
				component:()=>import('./audit-task/spot-check/spot-check.vue'),
				meta: { title: '270' },
			},
			{
				name: 'AuditItemIssueStatistics',
				path: 'AuditItemIssueStatistics',
				component:()=>import('./AuditItemIssueStatistics/AuditItemIssueStatistics.vue'),
				meta: { title: '259' },
			},
			{
				name: 'audit-task-report',
				path: 'audit-task-report',
				component:()=>import('./audit-task-report/audit-task-report.vue'),
				meta: { title: 'audit-task-report' },
			},
			{
				name: 'audit-item-issues-report',
				path: 'audit-item-issues-report',
				component:()=>import('./audit-item-issues-report/audit-item-issues-report.vue'),
				meta: { title: 'audit-item-issues-report' },
			},
			{
				name: 'barcode',
				path: 'barcode',
				component:()=>import('./barcode/barcode.vue'),
				meta: { title: 'barcode' },
			},
            {
				name: 'Text-Message',
				path: 'Text-Message',
				component:()=>import('./Text-Message/Text-Message.vue'),
				meta: { title: '0' },
			},
			{
				name: 'issue-assistant-confirm',
				path: 'issue-assistant-confirm',
				component:()=>import('./issue-assistant-confirm/issue-assistant-confirm.vue'),
				meta: { title: '0' },
			},
        ],
    },
];
export default UnderlyingRouting;
