import axios from 'axios';


const httpClient = axios.create({
  baseURL: '',
  timeout: 300000,
  headers:{
    'current-language':localStorage.getItem('qyb-language')??""
  }
});

export default httpClient;