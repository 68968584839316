import { RouteConfig } from 'vue-router';
import AccountLayout from '@/account/account-layout.vue';

const accountRouting: RouteConfig[] = [
	{
		path: '/account',
		component: AccountLayout,
		redirect: '/account/login',
		children: [
			{
				path: 'login',
				name: 'login',
				component: () => import(/* webpackChunkName: "account" */ './login/login.vue'),
			}
		],
	},
	{
		path: '/FS-KanBan',
		name: 'FS-KanBan',
		component: () => import(/* webpackChunkName: "account" */ '../app/kanban/FS-KanBan/FS-KanBan.vue'),
		meta: {
			title: 'FS-KanBan'
		}
	},
	{
		path: '/Rotate',
		name: 'Rotate',
		component: () => import(/* webpackChunkName: "account" */ '../app/kanban/Rotate/Rotate.vue'),
		meta: {
			title: 'Rotate'
		}
	}
];

export default accountRouting;
