<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="Wishloading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<span v-if="type == 0">{{ l('设置生日祝福') }}</span>
					<span v-else>{{ l('设置入职周年祝福') }}</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="12">
						<!--                        生日祝福-->
						<a-row v-if="type == 0">
							<a-col :span="7">
								启用生日祝福:
							</a-col>
							<a-col :span="3">
								<a-switch v-model="BirthdayWishSetting.isActivate" />
							</a-col>
							<a-col :span="8">
								当天推送时间:
							</a-col>
							<a-col :span="6">
								<a-time-picker
									placeholder="请选择时间"
									:allowClear="false"
									style="width: 100%"
									format="HH:mm"
									v-model="BriDateTime"
									@change="BirDateChange"
								/>
							</a-col>
						</a-row>
						<!--                        周年祝福-->
						<a-row v-if="type == 1">
							<a-col :span="7">
								启用周年祝福:
							</a-col>
							<a-col :span="3">
								<a-switch v-model="AnniversarySettings.isActivate" />
							</a-col>
							<a-col :span="8">
								当天推送时间:
							</a-col>
							<a-col :span="6">
								<a-time-picker
									placeholder="请选择时间"
									:allowClear="false"
									style="width: 100%"
									format="HH:mm"
									v-model="AnnDateTime"
									@change="AnnDateChange"
								/>
							</a-col>
						</a-row>
						<a-row>
							<a-col :span="5">员工姓名:</a-col>
							<a-col v-if="type == 0" :span="18" style="color: #A9A8AC;font-size: 14px;text-align: left"
								>(将显示过生日的员工姓名)
							</a-col>
							<a-col v-else :span="18" style="color: #A9A8AC;font-size: 14px"
								>(将显示当天入职周年的员工姓名)</a-col
							>
						</a-row>
						<a-row>
							<a-textarea placeholder="请输入祝福语" :rows="19" v-model="entity.wishConten" />
						</a-row>
						<a-row v-if="type != 0" style="margin-top: 10px;line-height: 0px">
							<a-col style="color: #A9A8AC;font-size: 10px;line-height: 20px;text-align: left">
								注：@Company=员工所在公司(系统默认员工所在公司)，@Year=入职年数，公司关键字可无.
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="1" style="text-align: center;">
						<div style="border-left: 1px solid #bfbfbf;width: 1px;height: 490px;margin: auto"></div>
					</a-col>
					<!--                    生日祝福-->
					<a-col :span="10" v-if="type == 0">
						<a-col :span="24">
							<a-row> <img src="/assets/images/hr-care/birthday.png" width="290" height="490"/></a-row>
						</a-col>
					</a-col>
					<!--                    入职周年-->
					<a-col :span="10" v-else>
						<a-col :span="24">
							<a-row>
								<img src="/assets/images/hr-care/Anniversary.png" width="290" height="490" />
							</a-row>
						</a-col>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :loading="saving" :type="'primary'" @click="save()">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import {
	AnniversaryManagementSettingsEditDto,
	BirthdayWishEditDto,
	BirthdayWishEditInput,
	BirthdayWishManagementSettingEditDto,
	BirthdayWishServiceServiceProxy,
} from '../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'create-or-edit-wish',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			Wishloading: false,
			entity: new BirthdayWishEditDto(),
			type: undefined,
			BirthdayWishSetting: new BirthdayWishManagementSettingEditDto(),
			AnniversarySettings: new AnniversaryManagementSettingsEditDto(),
			AnnDateTime: undefined,
			BriDateTime: undefined,
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._birthdayWishServiceServiceProxy = new BirthdayWishServiceServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.Wishloading = true;
			this._birthdayWishServiceServiceProxy
				.getForEdit(this.type)
				.finally(() => {
					this.Wishloading = false;
				})
				.then((res) => {
					this.entity = res.birthdayWishEdit;
					if (!this.entity.wishConten) {
						this.entity.wishConten =
							'今天是你的生日，祝你生日快乐，我们一起给你大大的拥抱，送上最温暖的心意。愿过去一年里你所有的努力与付出，都能在今天化作成长的幸福。崭新的下一年，期待我们继续一路同行，分享这段旅途所有的风景和心情！';
					}
					if (!res.anniversarySettings.hour) {
						this.AnnDateTime = moment('08:00', 'HH:mm');
						this.AnniversarySettings.hour = 8;
						this.AnniversarySettings.minute = 0;
					} else {
						let s = res.anniversarySettings.hour + ':' + res.anniversarySettings.minute;
						this.AnnDateTime = moment(s, 'HH:mm');
						this.AnniversarySettings = res.anniversarySettings;
					}
					if (!res.birthdayWishSettings.hour) {
						this.BriDateTime = moment('08:00', 'HH:mm');
						this.BirthdayWishSetting.hour = 8;
						this.BirthdayWishSetting.minute = 0;
					} else {
						let s = res.birthdayWishSettings.hour + ':' + res.birthdayWishSettings.minute;
						this.BriDateTime = moment(s, 'HH:mm');
						this.BirthdayWishSetting = res.birthdayWishSettings;
					}
				});
		},
		save() {
			if (!this.entity.wishConten) {
				return abp.message.warn('祝福语不能为空');
			}
			let input = new BirthdayWishEditInput();
			this.Wishloading = true;
			this.entity.wishType = this.type;
			input.birthdayWishEdit = this.entity;
			input.anniversarySettings = this.AnniversarySettings;
			input.birthdayWishSettings = this.BirthdayWishSetting;
			this._birthdayWishServiceServiceProxy
				.createOrUpdateBirthdayWish(input)
				.finally(() => {
					this.Wishloading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
		/**
		 * 入职周年时间
		 * @constructor
		 */
		AnnDateChange(record, str) {
			if (str) {
				let r = moment(record)
					.subtract(8, 'hours')
					.format('HH:mm');
				let s = str.split(':');
				this.AnniversarySettings.hour = parseInt(s[0]);
				this.AnniversarySettings.minute = parseInt(s[1]);
			}
		},
		/**
		 * 生日祝福时间
		 * @constructor
		 */
		BirDateChange(record, str) {
			if (str) {
				let r = moment(record)
					.subtract(8, 'hours')
					.format('HH:mm');
				let s = str.split(':');
				this.BirthdayWishSetting.hour = parseInt(s[0]);
				this.BirthdayWishSetting.minute = parseInt(s[1]);
			}
		},
	},
};
</script>

<style scoped></style>
