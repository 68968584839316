import Vue from 'vue';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";    
import moment from 'moment';
import Antd from 'ant-design-vue';                             
import root from './root.vue'; // 主容器组件
import { rootStore } from './shared/store'; // 全局store
import { AppPreBootstrap } from './AppPreBootstrap'; // 预启动器
import { rootRouting } from './router'; // 全局路由
import { authRouteGuard } from './shared/auth'; // 路由守卫
import './styles/index.less'; // 全局样式注册
import { appSessionService, permissionService } from './shared/abp';
import { localizationService } from './shared/i18n';
import { layoutService, menuService, reuseTabService } from './shared/common';
import { UserNotificationHelper } from './shared/helpers';
import { preloaderFinished } from '@/shared/utils';
import AMap from 'vue-amap';
import VueLazyload from 'vue-lazyload';
import { setupIcon } from './shared/antd/import-antd-icon';
import { setupComponents } from './components';
import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueLazyload, {
	//开启默认事件
	dispatchEvent: true,
});

Vue.use(AMap);
// 初始化vue-amap
AMap.initAMapApiLoader({
	// 高德key
	key: 'e71901894dd4d6ec5b58aab53e29ee60',
	// 插件集合 （插件按需引入）
	plugin: [
		'AMap.Autocomplete', //输入提示插件
		'AMap.PlaceSearch', //POI搜索插件
		'AMap.Scale', //右下角缩略图插件 比例尺
		'AMap.OverView', //地图鹰眼插件
		'AMap.ToolBar', //地图工具条
		'AMap.MapType', //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
		'AMap.PolyEditor', //编辑 折线多，边形
		'AMap.CircleEditor', //圆形编辑器插件
		'AMap.Geolocation', //定位控件，用来获取和展示用户主机所在的经纬度位置
	],
	uiVersion: '1.0',
});
/**
 * 过滤器
 */
Vue.filter('moment', (value, formatString) => {
	formatString = formatString || 'YYYY-MM-DDTHH:mm:ss';
	return moment(value).format(formatString);
});
/**
 * 重写moment的toISOString(),解决接口参数序列化后时间少8小时的问题
 */
moment.prototype.toISOString = function() {
	return moment(this).format('YYYY-MM-DD HH:mm:ss');
};

AppPreBootstrap.run(() => {
	// 初始化会话信息
	appSessionService.init().then((res) => {
		setupIcon();
		setupComponents();

		// 初始化路由守卫
		authRouteGuard.init(rootRouting);
		// 初始化复用标签
		reuseTabService.init(rootRouting, localizationService);
		// 初始化菜单服务
		menuService.initService(localizationService, permissionService);

		// 初始化通知服务
		UserNotificationHelper.init();

		// 启用复用标签
		layoutService.data.reuseTab = true;
		// 初始化菜单
		// menuService.add(AppMenus.Menus);

		new Vue({
			router: rootRouting,
			store: rootStore,
			render: (h) => h(root),
			mounted() {
				preloaderFinished();
			},
		}).$mount('#app');
	});
});
