<template>
    <div style="height:150px;">
        <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
            <a-button>
                <a-icon type="upload"/>
                {{l('SelectFile')}}
            </a-button>
        </a-upload>
        <br>
        <a-checkbox v-model="IsNoExist" />  {{l('Prohibitcreatingduplicatetasks')}}
        <br>
        <a-button
                type="primary"
                :disabled="fileList.length === 0"
                :loading="uploading"
                style="margin-top: 16px"
                @click="handleUpload"
        >
            {{ uploading ? l('Importing...') : l('import') }}
        </a-button>
    </div>
</template>
<script>

    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {AppConsts} from "@/abpPro/AppConsts";
    import axios from 'axios';
    import {AuditTaskServiceProxy} from "../../../../shared/service-proxies";
    import {fileDownloadService} from "../../../../shared/utils";

    let _this;
    export default {
        name: 'importExcelComponent',
        mixins: [ModalComponentBase],
        components: {
            axios
        },
        created() {
            _this = this;
            this.fullData();
            this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
            this.excelFileDownloadService = fileDownloadService;
        },
        data() {
            return {
                fileList: [],
                uploading: false,
                importExcelUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadExcelFile",
                authorization: "Bearer " + abp.auth.getToken(),
                getTableDataApi: undefined,
                excelFileDownloadService: undefined,
                IsNoExist: true,
            }
        },
        methods: {
            handleRemove(file) {
                //移除打开的Excel文件
                const index = this.fileList.indexOf(file);
                const newFileList = this.fileList.slice();
                newFileList.splice(index, 1);
                this.fileList = newFileList;
            },
            beforeUpload(file) {
                //上传之前判断是否是Excel文件
                //获取最后一个.的位置
                var index = file.name.lastIndexOf(".");
                //获取后缀
                var ext = file.name.substr(index + 1);
                if (ext != 'xls' && ext != 'xlsx') {
                    this.message.warn(this.l('The_imported_file_format_is_incorrect'));
                    this.handleRemove(file);
                } else {
                    this.fileList = [...this.fileList, file];
                }
                //手动上传设置返回false
                return false;
            },
            handleUpload() {
                //上传文件
                const formData = new FormData();
                this.fileList.forEach(file => {
                    formData.append('files[]', file);
                });
                this.uploading = true;

                axios.post(this.importExcelUrl, formData, {
                    headers: {
                        "Authorization": this.authorization
                    }
                }).then((response) => {
                    this.importExcelData(response.data.result.fileName);
                }).catch((error) => {
                    // catch 指请求出错的处理
                    console.log(error);
                    this.message.error(this.l('Template_data_import_error'));
                });
            },
            importExcelData(fileName) {
                //执行导入数据的接口
                _this.AuditTaskServiceProxy.importExcel(
                    fileName,
                    this.IsNoExist
                ).finally(
                    () => {
                        this.uploading = false;
                    }
                ).then(
                    res => {
                        console.log(res);

                        this.fileList = [];
                        if (!res.isSuccess && res.failedCount > 0) {
                            this.message.warn(this.l('Total_number_of_entries') + ":" + res.totalCount + "  " + this.l('The_number_of_failed_rows') + ":" + res.failedCount);
                            this.excelFileDownloadService.downloadTempFile(res.file);
                        } else {
                            this.message.success(this.l("The_impor_ was_successful"));
                            this.success(true);
                        }
                    }
                );
            }
        }
    }
</script>
<style scoped>

</style>