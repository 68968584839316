import AppCompoentBase from './app-component-base';
import {AppConsts} from '@/abpPro/AppConsts';


const PagedListingComponentBase = {
    mixins: [AppCompoentBase],
    data() {
        return {
            pageSize: AppConsts.grid.defaultPageSize,
            pageNumber: 1,
            totalPages: 1,
            totalItems: 0,
            // 条数显示范围
            pagerange: [1, 1],
            allChecked: false,
            sorting: undefined,
            filterText: '',
            request: {maxResultCount: 10, skipCount: 0,sorting:""},
            //表格数据
            dataList: [],
            //选中的key
            selectedRowKeys:[],
            //选中的行数据
            selectedRows:[]
        }
    },
    mounted() {
        this.refresh();
    },
    computed: {},
    methods: {
        /**
         * 刷新
         */
        refresh() {
            this.rsetCheckAllStatus(this.dataList);
            this.getData();
        },
        /**
         * 刷新表格数据并调转至第一页
         */
        refreshGoFirstPage() {
            this.pageNumber = 1;
            this.request.skipCount=0;
            this.rsetCheckAllStatus(this.dataList);
            this.getData();
        },
        getDataPage(page) { // 请求分页数据
            let req = {
                maxResultCount: 0,
                skipCount: 0,
                sorting: null,
            };
            req.maxResultCount = this.pageSize;
            req.skipCount = (page - 1) * this.pageSize;
            req.sorting = this.sorting;

            this.fetchDataList(req, page, (result) => {
                this.dataList=result.items;
                this.showPaging(result);
                this.pagerange = [
                    (this.pageNumber - 1) * req.request.maxResultCount + 1,
                    this.pageNumber * req.request.maxResultCount,
                ];
            });
        },
        /**
         * 页面改变时
         */
        pageNumberChange() {
            if (this.pageNumber > 0) {
                this.refresh();
            }
        },
        /**
         * 全选事件
         * @param 数据集合
         * @param 状态
         */
        checkAll(entityList,value) {
            entityList.map(item=>{item.checked=value;});
            this.refreshCheckStatus(entityList);
        },
        /**
         * 刷新选中状态
         * @param entityList
         */
        refreshCheckStatus(entityList) {
            entityList.forEach(item=>{
               if(item.checked){
                   //判断是否存在
                   let u = this.selectedRows.find(t => t.id == item.id);
                   if(!u){
                       this.selectedRows.push(item);
                       this.selectedRowKeys.push(item.id);
                   }
               }
               else {
                   this.selectedRows=this.selectedRows.filter(row=>row.id!=item.id);
                   this.selectedRowKeys=this.selectedRowKeys.filter(key=>key!=item.id);
               }
            });
        },
        /**
         * 重置选中状态(只会重置当前页的选中状态,选中行和key也只会去除当前页)
         * @param 需要清空的集合
         */
        restCheckStatus(entityList) {
            entityList.forEach(item=>{
                item.checked=false;
                this.selectedRows=this.selectedRows.filter(row=>row.id!=item.id);
                this.selectedRowKeys=this.selectedRowKeys.filter(key=>key!=item.id);
            });
        },
        /**
         * 重置选中状态并且清空选中的行数据
         * @param entityList
         */
        rsetCheckAllStatus(entityList){
            entityList.map(item=>{item.checked=false;});
            this.selectedRows=[];
            this.selectedRowKeys=[];
        },
        /**
         * 计算分页
         * @param result
         */
        showPaging(result) {
            this.totalItems = result.totalCount;
            this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        },
        /**
         * 复选框点击事件
         * @param record
         */
        onSelectChange(record) { // antd-vue table selectChange事件
            record.checked = !record.checked;
            if (record.checked) {
                this.selectedRowKeys.push(record.id);
                this.selectedRowKeys.push(record);
            } else {
                this.selectedRowKeys = [...this.selectRows.filter(item => item.id != record.id)];
                this.selectedRowKeys = [...this.selectedRowKeys.filter(item => item != record.id)];
            }
        },
        /**
         * 获取数据接口
         */
        getData() {

        },
        fetchDataList(request, pageNumber, finishedCallback) {
        },
        gridSort(sort) { // 数据表格排序
            let ascOrDesc = sort.value; // 'ascend' or 'descend' or null
            const filedName = sort.key; // 字段名
            if (ascOrDesc) {
                ascOrDesc = abp.utils.replaceAll(ascOrDesc, 'end', '');
                const args = ['{0} {1}', filedName, ascOrDesc];
                const sortingStr = abp.utils.formatString.apply(this, args);
                if (this.sorting === sortingStr) {
                    return;
                }
                this.sorting = sortingStr;
            } else {
                this.sorting = undefined;
            }
            this.refresh();
        },
        tableChange(pagination, filters, sorter, data) { // antd-vue table change事件
            // 调用排序
            sorter['value'] = sorter.order;
            sorter['key'] = sorter.columnKey;
            this.gridSort(sorter);
        }
    }
};

export default PagedListingComponentBase;

